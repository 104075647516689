<template>
  <div class="dukaqi">
    <div class="title">
      <p
        @click="titleIndex = index"
        :class="[titleIndex == index ? 'titleActive' : '']"
        v-for="(item, index) in chanpinneironglist"
        :key="index"
      >
        {{ item.title }}
      </p>
    </div>

    <div class="jiejue">
      <ul>
        <li
          v-for="item in chanpinneironglist[titleIndex].contentList"
          :key="item.id"
          @click="toDetails(item)"
          class="mateDom"
        >
          <div class="jiejueimg">
            <el-image :src="item.src" alt="" lazy class="chanpintu" fit="contain"></el-image>
            <!-- <el-image :src="productObj.srcDetails" lazy class="tupian" ></el-image> -->
          </div>
          <div class="neirong">
            <div class="wenzi">{{ item.name }}</div>
            <div class="ttitle">{{ item.chakan }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Fangke",
  components: {},
  data() {
    return {
      chanpinneironglist: [
        {
          id: 0,
          title: "XC-IVS8008系列智能边缘分析盒",
          contentList: [
            {
              id: 1,
              src: require("../../assets/imgAll/bianyuan/XC-IVS8008-1U/by1.png"), //产品图
              srcDetails: require("../../assets/imgAll/bianyuan/XC-IVS8008-1U/by1.png"), //产品详情页图
              introduce: `XC-IVS8008-1U边缘计算服务器是一款提供超强 AI 算力级的嵌入式高性能1U型机架式服务器，一台设备可实现视频结构化、人脸检测识别、周界防范三种算法切换，支持多算法并行分析。外接普通网络摄像头输入视频，对图像中出现的对象进行智能分析，输出异常告警信号，实现异常行为告警，单台最大可提供16路视频的处理能力，同时还配置双网口设计，支持原有局域网、专网同步接入。`, //介绍
              name: "XC-IVS8008-1U 边缘分析服务器",
              effect: {
                title: "产品功能",
                content: [
                  "可接入符合 ONVIF、RTSP 标准的高清网络摄像机。",
                  "支持 H.265/H.264 视频标准，最高可支持 800 万像素 IPC 的接入。",
                  "总库最多支持 30 万张人脸图片，支持 64 个库。",
                  "多类算法混合：人脸模式、混合模式（脸人绑定 + 视频结构化）。",
                  "人脸识别：支持 16 路视频流。",
                  "视频结构化：支持 16 路视频流。",
                  "智能警戒：支持 8 路视频流。",
                  "支持人脸抓拍、人脸识别比对报警、陌生人识别告警等。",
                  "支持人脸属性的识别：性别、年龄、戴帽子、戴眼镜、戴口罩等。",
                  "支持人脸、人体、机动车、非机动车、车牌的抓拍及脸人绑定、人-非机动车绑定。",
                  "支持人上下衣颜色、上下衣款式、背包状态，是否佩戴安全帽等人体属性解析。",
                  "支持机动车分类，颜色、品牌、驾驶方向和非机动车分类等车辆属性解析。",
                  "支持车牌识别和车牌-机动车绑定关系。",
                  "支持人车分离、拌线越界、区域入侵、车辆违停、过线计数等智能警戒功能。",
                ], //功能
              },
              parameterText: {
                title: "设备参数",
                content: [
                  {
                    title:"系统参数",
                    contentList:[
                      { name:"主处理器",parameter:"Intel Skylake-U Core I3-6100U"},
                      { name:"NPU",parameter:"内置支持 INT4 可达 28.8Tops NPU加速卡"},
                      { name:"内存",parameter:"1*SO-DIMM，DDR3 L 1600MHz ，8GB（最大可以扩展到16GB）"},
                      { name:"硬盘",parameter:"1TB"},
                      { name:"操作系统",parameter:"Ubuntu20.04"},
                    ]
                  },
                   {
                    title:"设备接入",
                    contentList:[
                      { name:"视频分辨率",parameter:"1920 x 1080（200 万）;2560 x 1440（400 万）;3840 x 2160（800 万）"},
                      { name:"视频解码类型",parameter:"H.264/H.265 "},
                    ]
                  },
                  {
                    title:"智能参数",
                    contentList:[
                      { name:"脸人绑定+人脸识别",parameter:"（最大 16 路）;人脸抓拍、人脸识别、人脸属性、人体抓拍、人体属性、脸人绑定"},
                      { name:"视频结构化",parameter:"（最大 16 路）"},
                      { name:"图片抓拍",parameter:"人脸、人体、机动车、非机动车、车牌"},
                      { name:"属性输出",parameter:"人脸、人体、机动车、非机动车、车牌"},
                      { name:"关联关系",parameter:"脸人绑定、车-车牌绑定、人-非机动车绑定 "},
                      { name:"智能警戒",parameter:"（最大 8 路）:人、车、非机动车分离，高清抓拍;拌线越界、区域入侵、车辆违停、过线计数等报警事件的输出"},
                      { name:"主动上报",parameter:"人脸抓拍、人脸识别、人脸属性结果;2）脸人机非牌的抓拍和属性、车牌识别结果;警戒告警"},
                      { name:"人脸",parameter:"抓拍率 ≥ 99%;抓拍误抓率 < 1%;白名单识别通过率 > 99.5%;识别误识率：< 0.5%;最大底库：30 万人像库"},
                      { name:"人体",parameter:"抓拍率 ≥ 95%;抓拍误抓率 < 1%"},
                      { name:"机动车",parameter:"抓拍率 ≥ 90%;抓拍误抓率 < 1%"},
                      { name:"非机动车",parameter:"抓拍率 ≥ 95%;抓拍误抓率 < 1%"},
                      { name:"车牌",parameter:"抓拍率 ≥ 95%;抓拍误抓率 < 1%;识别率≥ 95%"},
                    ]
                  },
                  {
                    title:"接口参数",
                    contentList:[
                      { name:"网络接口",parameter:"2 个，100M/1000M 自适应以太网，RJ45 接口"},
                      { name:"音频输出",parameter:"1 路"},
                      { name:"音频输入",parameter:"1 路"},
                      { name:"USB 接口",parameter:"2 个 USB2.0，2 个 USB3.0"},
                      { name:"显示输出",parameter:"1 个VGA,1 HDMI"},
                      { name:"电源指示灯(PWR)",parameter:"1 个"},
                      { name:"运行指示灯（RUN）",parameter:"1 个，双色（红+绿）"},
                    ]
                  },
                  {
                    title:"系统功能",
                    contentList:[
                      { name:"人脸识别",parameter:"出入口员工通行；重点人员名单管控和识别报警、陌生人识别等"},
                      { name:"视频结构化",parameter:"人脸、人体、机动车、非机动车、车牌等的抓拍、属性分析、车牌识别 "},
                      { name:"网络协议",parameter:"TCP/UDP/HTTP/MULTICAST/DHCP/FTP/NTP/HTTPS/RTSP 等"},
                      { name:"日志查询",parameter:"可进行脸人机非牌的抓拍信息的查询、搜索和展示"},
                    ]
                  },
                  {
                    title:"环境要求 ",
                    contentList:[
                      { name:"工作温度",parameter:"-20℃ ～ +60℃"},
                      { name:"存储温度",parameter:"-30℃ ～ +70℃"},
                      { name:"相对湿度",parameter:"10% ～ 90%RH，无冷凝 "},
                    ]
                  },
                  {
                    title:"其他 ",
                    contentList:[
                      { name:"电源",parameter:"DC12V±10%，2A"},
                      { name:"结构",parameter:"金属机壳"},
                      { name:"尺寸（长深高）",parameter:"428×350×89（mm）"},
                      { name:"重量",parameter:"＜4KG"},
                    ]
                  }
                ],
              }, //参数(文字表格)
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 2,
              src: require("../../assets/imgAll/bianyuan/XC-IVS8008-01/by1.png"), //产品图
              srcDetails: require("../../assets/imgAll/bianyuan/XC-IVS8008-01/by1.png"), //产品详情页图
              introduce: `IVS8008-01是基于深度学习算法的智能计算盒。该产品采用嵌入式设计，集成高性能NPU模块， 内嵌基于深度学习的结构化算法，产品成熟、稳定，可对实时视频中行人、机动车、非机动车目标的检出、跟踪、抓拍，并识别目标特征属性。同时，可设置基于目标的结构化数据检索（车辆类型、车身颜色、车牌号码等等），进行大数据应用，做更高级的业务管理。适用于各类城市道路监控（人行道、十字路口）、小区出入口、高速收费站、各类停车场、安全校园的人、车结构化分析等其他行人与车辆交互出现的重点监控场景。`, //介绍
              name: "XC-IVS8008-01 AI智能计算盒",
              effect: {
                title: "产品功能",
                content: [
                  "可接入符合 ONVIF、RTSP 标准的高清网络摄像机。",
                  "支持 H.265/H.264 视频标准，最高可支持 800 万像素 IPC 的接入支持人脸检测、跟踪、识别等。",
                  "总库最多支持 30 万张人脸图片，支持 64 个库。",
                  "多类算法混合：人脸模式、混合模式（脸人绑定 + 视频结构化）。",
                  "人脸识别：支持 16 路视频流。",
                  "视频结构化：支持 16 路视频流。",
                  "智能警戒：支持 8 路视频流。",
                  "支持人脸抓拍、人脸识别比对报警、陌生人识别告警等。",
                  "支持人脸属性的识别：性别、年龄、戴帽子、戴眼镜、戴口罩等。",
                  "支持人脸、人体、机动车、非机动车、车牌的抓拍及脸人绑定、人-非机动车绑定。",
                  "支持人上下衣颜色、上下衣款式、背包状态，是否佩戴安全帽等人体属性解析。",
                  "支持机动车分类，颜色、品牌、驾驶方向和非机动车分类等车辆属性解析。",
                  "支持车牌识别和车牌-机动车绑定关系。",
                  "支持人车分离、拌线越界、区域入侵、车辆违停、过线计数等智能警戒功能。",
                ], //功能
              },
              parameterText: {
                title: "设备参数",
                content: [
                  {
                    title:"系统参数",
                    contentList:[
                      { name:"主处理器",parameter:"4 x ARM CortexA53@1.3G"},
                      { name:"NPU",parameter:"支持 INT4 可达 28.8Tops"},
                      { name:"内存",parameter:"LPDDR4：2xLPDDR4X、2x2GB"},
                      { name:"闪存",parameter:"eMMC：8GB(32GB可选)"},
                      { name:"操作系统",parameter:"嵌入式 Linux "},
                    ]
                  },
                   {
                    title:"设备接入",
                    contentList:[
                      { name:"视频分辨率",parameter:"1920 x 1080（200 万）;2560 x 1440（400 万）;3840 x 2160（800 万）"},
                      { name:"视频解码类型",parameter:"H.264/H.265 "},
                    ]
                  },
                  {
                    title:"智能参数",
                    contentList:[
                      { name:"脸人绑定+人脸识别",parameter:"（最大 16 路）;人脸抓拍、人脸识别、人脸属性、人体抓拍、人体属性、脸人绑定"},
                      { name:"视频结构化",parameter:"（最大 16 路）"},
                      { name:"图片抓拍",parameter:"人脸、人体、机动车、非机动车、车牌"},
                      { name:"属性输出",parameter:"人脸、人体、机动车、非机动车、车牌"},
                      { name:"关联关系",parameter:"脸人绑定、车-车牌绑定、人-非机动车绑定 "},
                      { name:"智能警戒",parameter:"（最大 8 路）:人、车、非机动车分离，高清抓拍;拌线越界、区域入侵、车辆违停、过线计数等报警事件的输出"},
                      { name:"主动上报",parameter:"人脸抓拍、人脸识别、人脸属性结果;2）脸人机非牌的抓拍和属性、车牌识别结果;警戒告警"},
                      { name:"人脸",parameter:"抓拍率 ≥ 99%;抓拍误抓率 < 1%;白名单识别通过率 > 99.5%;识别误识率：< 0.5%;最大底库：30 万人像库"},
                      { name:"人体",parameter:"抓拍率 ≥ 95%;抓拍误抓率 < 1%"},
                      { name:"机动车",parameter:"抓拍率 ≥ 90%;抓拍误抓率 < 1%"},
                      { name:"非机动车",parameter:"抓拍率 ≥ 95%;抓拍误抓率 < 1%"},
                      { name:"车牌",parameter:"抓拍率 ≥ 95%;抓拍误抓率 < 1%;识别率≥ 95%"},
                    ]
                  },
                  {
                    title:"接口参数",
                    contentList:[
                      { name:"网络接口",parameter:"2 个，100M/1000M 自适应以太网，RJ45 接口"},
                      { name:"音频输出",parameter:"1 路"},
                      { name:"音频输入",parameter:"1 路"},
                      { name:"前置 USB 接口 ",parameter:"前置 1 个 USB2.0，1 个 USB3.0 "},
                      { name:"RS485",parameter:"1 路 （扩展）"},
                      { name:"电源指示灯(PWR)",parameter:"1 个"},
                      { name:"运行指示灯（RUN）",parameter:"1 个，双色（红+绿）"},
                    ]
                  },
                  {
                    title:"系统功能",
                    contentList:[
                      { name:"人脸识别",parameter:"出入口员工通行；重点人员名单管控和识别报警、陌生人识别等"},
                      { name:"视频结构化",parameter:"人脸、人体、机动车、非机动车、车牌等的抓拍、属性分析、车牌识别 "},
                      { name:"网络协议",parameter:"TCP/UDP/HTTP/MULTICAST/DHCP/FTP/NTP/HTTPS/RTSP 等"},
                      { name:"日志查询",parameter:"可进行脸人机非牌的抓拍信息的查询、搜索和展示"},
                    ]
                  },
                  {
                    title:"环境要求 ",
                    contentList:[
                      { name:"工作温度",parameter:"-20℃ ～ +60℃"},
                      { name:"存储温度",parameter:"-30℃ ～ +70℃"},
                      { name:"相对湿度",parameter:"10% ～ 90%RH，无冷凝 "},
                    ]
                  },
                  {
                    title:"其他 ",
                    contentList:[
                      { name:"电源",parameter:"DC12V±10%，2A"},
                      { name:"结构",parameter:"金属机壳"},
                      { name:"尺寸（长深高）",parameter:"215×200×49（mm） "},
                      { name:"重量",parameter:"＜4KG"},
                    ]
                  }
                ],
              }, //参数(文字表格)
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
          ],
        },
      ],

      titleIndex: 0, //选择系列得下标;同时作为显示内容的下标
    };
  },
  methods: {
    toDetails(obj) {
      this.$router.push({
        path: "productdetailsppage",
        query: {
          list: JSON.stringify(obj),
        },
      });
    },
  getDivAddr(){
      // 获取要添加动画的dom元素（也可用ref）
      let dom = document.getElementsByClassName('mateDom')
      // 获取页面高度；后期与bottom做对比
      let height = document.documentElement.clientHeight
      // 给每一个添加动画的dom元素添加事件
      for (const iterator of dom) {
        // 获取dom距离可视区域的高度
        let list = iterator.getBoundingClientRect()
        // 利用top bottom距离可视区域顶部的距离判断元素是否在可视区域中
        if(list.bottom>0 && list.top<height){
          // 将预先隐藏的元素显示
          // 注意：用visibility而不是用disablay
          // 添加动画类名
        iterator.style.visibility='visible'
        iterator.classList.add('animate__animated', 'animate__fadeInUp');
      }
      }
    },
    // 监听滚动条事件
    windowScroll() {
      this.getDivAddr()
    },
  },
  mounted(){
    this.getDivAddr()
    // 监听滚动条
    window.addEventListener('scroll', this.windowScroll)
  }
};
</script>
<style lang="less" scoped>
.jiejue {
  width: 100%;
  ul {
    li {
      width: 33.333%;
      padding: 1%;
      height: 100%;
      float: left;
      .jiejueimg {
        width: 100%;
        height: 290px;
        line-height: 290px;
        background-color: rgb(255, 255, 255);
        text-align: center;
        vertical-align: middle;
        overflow: hidden;
        .chanpintu {
          width: 180px;
          height: 200px;
          // overflow: hidden;
          // transition: ease 0.5s;
          margin-top: 40px;
        }
      }
      .neirong {
        background-color: rgb(255, 255, 255);
        border-top: 1px solid #e5e5e5;
        height: 120px;
        text-align: center;
        padding: 28px 20px;
        transition: ease 0.5s;
        .wenzi {
          color: rgb(14, 14, 14);
          font-size: 22px;
          font-weight: 400;
          margin-bottom: 12px;
          transition: ease 0.5s;
        }
        .ttitle {
          font-size: 16px;
          line-height: 30px;
          color: #666;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    li:hover .wenzi {
      color: #0e57a2;
    }
    li:hover .neirong {
      border-top: 1px solid #0e57a2;
      transform: scale(x, y);
    }
    li:hover .chanpintu {
      transform: scale(1.2);
    }
  }
}
.title {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  p {
    margin: 0 20px;
  }
  .titleActive {
    color: #2468c3;
    font-weight: bold;
  }
  .mateDom{
    visibility: hidden;
  }
}
</style>
