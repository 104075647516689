<template>
  <div class="while">
    <el-image
      :src="require('@/assets/imgAll/wisdom/gsBackImg.jpg')"
      fit="cover"
      class="backImg"
    ></el-image>
    <!--公司介绍 开始-->
    <el-main class="xinchuang">

      <div class="jiejuebiaoti mateDom">公司简介</div>
      <div class="xinchuanggongsi mateDom">
        <ul>
          <li>
            <img src="../../assets/imgAll/wisdom/CompanyProfile/gongsiImg.jpg" alt="" />
          </li>
          <li style="padding: 3%">
            <div class="gongsi">深圳市信创信息科技有限公司</div>
            <div class="fenge"></div>
            <div class="qiyewenan">
              信创信息科技有限公司是广东深圳一家专业人工智能产品和和解决方案的高新技术企业公司。<br />
              主要包括：访客智能终端，客户端软件，二维码识别模组，人脸识别门禁一体机，人脸识别测温一体机，人脸识别人员实名制系统、人证合一核查系统、动态人脸识别智能系统、人脸识别智能考勤系统等软硬件产品。
              信创信息科技有限公司凭借在视觉技术领域里多年的沉淀和积累，在智慧门禁、智慧园区、智慧校园、智慧楼宇等领域为客户提供了完整的解决方案，赢得了广大客户的认可和信赖。<br />
              公司非常重视企业的内部管理工作，市场销售、软件研发、技术支持是公司的三大核心部门，现已经建立了一套比较完善的管理体制。在客户服务方面，本着为客户服务的思想，设立了24小时产品咨询电话、24小时售后技术支持电话等多个无障碍通道，为客户提供了高质量的售前和售后的服务和强有力的支持。
            </div>
          </li>
        </ul>
      </div>

      <div class="jiejuebiaoti mateDom">我们是谁 ?</div>
      <div class="contentItem">
        <ul>
          <li class="contentItem_li mateDom">视觉智能服务专家</li>
          <li class="contentItem_li mateDom">专注于智慧城市垂直领域的视觉智能解决方案提供商</li>
          <li class="contentItem_li yellow mateDom">致力于成为智慧城市领域的优选合作伙伴</li>
        </ul>
        <div class="MyContent">
          <div class="MyContentItem mateDom">
            <img src="../../assets/imgAll/wisdom/CompanyProfile/my01.png" alt="">
            <div><p class="p1">AI研发实力 业界领先</p></div>
            <div><p class="p2">来自AI独角兽的 专业AI研发团队</p></div>
          </div>
          <div class="MyContentItem mateDom">
            <img src="../../assets/imgAll/wisdom/CompanyProfile/my02.png" alt="">
            <div><p class="p1 p_heightCenter">聚焦行业解决方案</p></div>
            <div><p class="p2">智慧校园、智慧园区、智慧工地 智慧城管、智慧交通、智慧司法 智慧餐饮、智慧网点、智慧生产 等垂直领域</p></div>
          </div>
          <div class="MyContentItem mateDom">
            <img src="../../assets/imgAll/wisdom/CompanyProfile/my03.png" alt="">
            <div><p class="p1">依托核心技术 构建产品应用生态</p></div>
            <div><p class="p2">拥有访客智能终端，客户端软件，二 维码识别模组，人脸识别门禁一体机， 人脸识别测温一体机，人脸识别人员 实名制系统、人证合一核查系统、动 态人脸识别智能系统、人脸识别智能 考勤系统等软硬件产品</p></div>
          </div>
        </div>
      </div>

      <div class="jiejuebiaoti mateDom">我们的理念</div>
      <div class="contentItem flexDiv mateDom">
        <div class="flexDivItem">
          <el-image
            :src="require('@/assets/imgAll/wisdom/CompanyProfile/linian1.png')"
            fit="cover"
            class="flexDivItem-img"
          ></el-image>
          <p class="p1">简洁 <span class="p2">大道至简</span> </p>
        </div>
        <div class="flexDivItem">
          <el-image
            :src="require('@/assets/imgAll/wisdom/CompanyProfile/linian2.png')"
            fit="cover"
            class="flexDivItem-img"
          ></el-image>
          <p class="p1">高效 <span class="p2">倍道兼行</span> </p>
        </div>
        <div class="flexDivItem">
          <el-image
            :src="require('@/assets/imgAll/wisdom/CompanyProfile/linian3.png')"
            fit="cover"
            class="flexDivItem-img"
          ></el-image>
          <p class="p1">稳定 <span class="p2">周道如砥</span> </p>
        </div>
      </div>

      <div class="jiejuebiaoti mateDom">我们的位置</div>
      <div class="contentItem mateDom">
        <MapPage></MapPage>
      </div>
    </el-main>
    <!--公司介绍 结束-->
  </div>
</template>


<script>
import MapPage from "./MapPage.vue";
import 'animate.css';
export default {
  name: "CompanyProfile",
  components: { MapPage },
  data(){
    return{

    }
  },
  methods:{
    getDivAddr(){
      // 获取要添加动画的dom元素（也可用ref）
      let dom = document.getElementsByClassName('mateDom')
      // 获取页面高度；后期与bottom做对比
      let height = document.documentElement.clientHeight
      // 给每一个添加动画的dom元素添加事件
      for (const iterator of dom) {
        // 获取dom距离可视区域的高度
        let list = iterator.getBoundingClientRect()
        // 利用top bottom距离可视区域顶部的距离判断元素是否在可视区域中
        if(list.bottom>0 && list.top<height-100){
          // 将预先隐藏的元素显示
          // 注意：用visibility而不是用disablay
          // 添加动画类名
        iterator.style.visibility='visible'
        iterator.classList.add('animate__animated', 'animate__fadeInUp');
      }
      }
    },
    // 监听滚动条事件
    windowScroll() {
      this.getDivAddr()
    },
  },
  mounted(){
    this.getDivAddr()
    // 监听滚动条
    window.addEventListener('scroll', this.windowScroll)
  }
};
</script>

<style lang='less' scoped>
.while {
  width: 100%;
  height: auto;
  padding-top: 82px;
  .backImg{
    width: 100%;
    height: 370px;
  }
  .xinchuang {
    //   margin-top: 30px;
    // background-color: rgb(240, 240, 240);
    .xinchuanggongsi {
      margin-bottom: 25px;
      width: 94%;
      height: 430px;
      margin: 0 auto;
      ul {
        height: 430px;
        li {
          width: 50%;
          height: 100%;
          background-color: rgb(255, 255, 255);
          float: left;
          img {
            height: 100%;
            width: 100%;
          }
          .gongsi {
            font-size: 24px;
            color: #333;
            font-weight: 400;
          }
          .fenge {
            margin: 8px 0;
          }
          .qiyewenan {
            font-size: 16px;
            color: #555;
            font-weight: 300;
            line-height: 1.5;
          }
        }
      }
    }
  }
  .jiejuebiaoti {
    font-size: 30px;
    line-height: 50px;
    color: #333;
    text-align: center;
    margin: 30px 0 20px 0;
  }
  .contentItem {
    width: 94%;
    height: auto;
    margin: 0 auto;
    // background-color: #333;
    .contentItem_li{
      list-style-type: square;
      font-size: 21px;
      font-weight: bold;
      color: #585858;
      line-height: 2em;
    }
    .yellow{
      color: #D8B34E;
    }
    .MyContent{
      display: flex;
      justify-content: center;
      margin-top: 50px;
      .MyContentItem{
        flex: 1;
        text-align: center;
        img{
          width: 120px;
          height: 120px;
        }
        .p1{
          font-size: 21px;
          display: inline-block;
          color: #fff;
          background-color: #5E6A92;
          border-radius: 15px;
          padding: 10px 15px;
          margin: 15px 0 10px 0;
          width: 200px;
          min-height: 60px;
        }
        .p_heightCenter{
          line-height: 42px;
        }
        .p2{
          font-size: 15px;
          display: inline-block;
          color: #000;
          font-weight:bold;
          width: 200px;
          line-height: 1.5em;
        }
      }
    }
  }
  .flexDiv {
    display: flex;
    justify-content: space-between;
    height: 360px;
    .flexDivItem {
      box-shadow: 0px 0px 10px 0px rgba(112, 112, 112, 0.2);
      overflow: hidden;
      .p1 {
        width: 100%;
        text-align: center;
        font-size: 20px;
        color: #333;
        line-height: 30px;
        padding: 30px 0 80px;
      }
      .p2 {
        font-size: 18px;
        color: #666;
        display: block;
      }
      .flexDivItem-img{
        transition: all 1s ease 0s;
      }
      // .flexDivItem-img:hover{
        
      // }
    }
    .flexDivItem:hover .flexDivItem-img{
      transform: scale(1.2);
    }
  }
  .mateDom{
    visibility: hidden;
  }
}
</style>