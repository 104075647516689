<template>
  <div class="home" style="width: 100%;height: 100%;">
    <!--返回顶部-->
    <el-backtop :bottom="60"></el-backtop>
    <!--导航栏-->
    <NaviGation></NaviGation>
    <!--内容区域-->
    <router-view></router-view>
    <!--底部-->
    <ContactUs></ContactUs>
  </div>

</template>

<script>

import ContactUs from '@/components/ContactUs.vue'
import NaviGation from '../components/NaviGation.vue'
// import Watermark from '@/watermark'

export default {
  name: 'Home',
  components: {
    ContactUs,
    NaviGation
  },
  data() {
    return {
      aa:'aaaa'
    }
  },
  methods: {},
  mounted () {
  // Watermark.set(this.aa)
},
}
</script>
<style lang="less" scoped>
</style>
