<template>
  <div class="whilo">
    <el-image :src="src" lazy class="tupian" fit="cover"></el-image>
    <!-- 内容区域 -->
    <div class="content">
      <!-- 图解及介绍 -->
      <div class="imgAndjs">
        <div class="imgAndjsItem mateDom">
          <!-- <img :src="cp" alt=""> -->
          <el-image :src="productObj.srcDetails" lazy class="tupian" fit="scale-down"></el-image>
        </div>
        <div class="imgAndjsItem imgAndjsItemRight mateDom">
          <p class="title">{{productObj.name}}</p>
          <p class="text">
            {{productObj.introduce}}
          </p>
        </div>
      </div>
      <!-- 导航区域 -->
      <div class="nav mateDom">
        <span
          class="navItem "
          @click="showNavIndex = 0"
          :class="[showNavIndex == 0 ? 'navActive' : '']"
          v-if="productObj.characteristic"
          >产品特色</span
        >
        <span
          class="navItem"
          @click="showNavIndex = 1"
          :class="[showNavIndex == 1 ? 'navActive' : '']"
          v-if="productObj.effect"
          >产品功能</span
        >
        <span
          class="navItem"
          @click="showNavIndex = 2"
          :class="[showNavIndex == 2 ? 'navActive' : '']"
          v-if="productObj.parameter || productObj.parameterText"
          >设备参数</span
        >
      </div>
      <!-- 产品特色 -->
      <div class="navContent0" v-if="showNavIndex === 0 && productObj.characteristic" >
        <p class="title mateDom">{{productObj.characteristic.title}}</p>
        <div class="contentDiv">
          <div class="contentDivItem mateDom" v-for="(item,index) in productObj.characteristic.content" :key="index">
            <p class="title">{{item.title}}</p>
            <p class="text">
              {{item.content}}
            </p>
          </div>
        </div>
      </div>

      <!-- 产品功能 -->
      <div class="navContent" v-if="showNavIndex === 1 && productObj.effect">
        <p class="title mateDom">{{productObj.effect.title}}</p>
        <ul>
          <li class="text mateDom" v-for="(item,index) in productObj.effect.content" :key="index">
            {{item}}
          </li>
        </ul>
      </div>

      <!-- 设备参数 -->
      <div class="navContent2" v-if="showNavIndex == 2  && productObj.parameter">
        <p class="title mateDom">{{productObj.parameter.title}}</p>
        <div class="imgList mateDom">
            <el-image :src="item" lazy  fit="contain" v-for="(item,index) in productObj.parameter.content" :key="index"></el-image>
        </div>
      </div>
      <!-- 设备参数(文字表格) -->
      <div class="navContent2" v-if="showNavIndex == 2  && productObj.parameterText">
        <p class="title mateDom">{{productObj.parameterText.title}}</p>
        <!-- 如果内容有图片的话，便展示图片 -->
        <div v-if="productObj.parameterImg">
          <el-image :src="item" lazy  fit="contain" v-for="(item,index) in productObj.parameterImg" :key="index"></el-image>
        </div>
        <el-card v-for="(item,index) in productObj.parameterText.content" :key="index" class="box-card parameterTextItem mateDom">
          <p class="parameterTextItemTitle">{{item.title}}</p>
          <!-- 每一条数据展示 -->
          <div v-for="(item,index) in item.contentList" :key="index" class="ListTable">
            <p class="ListTableName ListTable-item">{{item.name}}</p>
            <p class="ListTable-item">{{item.parameter}}</p>
          </div>
        </el-card>
      </div>

      <div class="black ">
        <router-link to="/home/productcenter" class="blackLink mateDom">
          <i class="el-icon-d-arrow-left"></i> 返回产品中心
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  nama: "ProductDetailsPage",
  data() {
    return {
      src: require("../assets/chanpinzhongxin.jpg"),
      showNavIndex: 1,
      productObj:{},
    };
  },
  methods:{
   getDivAddr(){
      // 获取要添加动画的dom元素（也可用ref）
      let dom = document.getElementsByClassName('mateDom')
      // 获取页面高度；后期与bottom做对比
      let height = document.documentElement.clientHeight
      // 给每一个添加动画的dom元素添加事件
      for (const iterator of dom) {
        // 获取dom距离可视区域的高度
        let list = iterator.getBoundingClientRect()
        // 利用top bottom距离可视区域顶部的距离判断元素是否在可视区域中
        if(list.bottom>0 && list.top<height-100){
          // 将预先隐藏的元素显示
          // 注意：用visibility而不是用disablay
          // 添加动画类名
        iterator.style.visibility='visible'
        iterator.classList.add('animate__animated', 'animate__fadeInUp');
      }
      }
    },
    // 监听滚动条事件
    windowScroll() {
      this.getDivAddr()
    },
  },
  mounted(){
    this.getDivAddr()
    // 监听滚动条
    window.addEventListener('scroll', this.windowScroll)
  },
  beforeMount() {
    this.productObj = JSON.parse(this.$route.query.list);
    console.log(JSON.parse(this.$route.query.list));
  },
};
</script>

<style lang="less" scoped>
.mateDom{
    visibility: hidden;
  }
.whilo {
  width: 100%;
  height: auto;
  padding-top: 82px;
  .tupian {
    width: 100%;
    height: 368px;
  }
  .content {
    width: 100%;
    height: auto;
    .imgAndjs {
      display: grid;
      grid-template-columns: 40% 60%;
      grid-template-rows: auto;
      .imgAndjsItem {
        width: 100%;
        background-color: #fff;
        .title {
          font-size: 30px;
          line-height: 40px;
          color: #333;
        }
        .text {
          margin-top: 18px;
          max-height: 120px;
          font-size: 16px;
          line-height: 30px;
          color: #666;
        }
      }
      .imgAndjsItemRight {
        background-color: #f7f7f7;
        padding: 70px 50px 0px 100px;
      }
      img {
        width: 300px;
        height: 320px;
      }
    }
    .nav {
      height: 54px;
      width: 90%;
      margin: 0 auto;
      border-bottom: 2px solid #0e68c3;
      background-color: rgb(255, 255, 255);
      margin-top: 30px;
      margin-bottom: 30px;
      .navItem {
        padding: 10px 20px;
        background: #f7f7f7;
        display: inline-block;
        font-size: 18px;
        line-height: 30px;
        color: #333;
      }
      .navItem:hover {
        cursor: pointer;
      }
      .navActive {
        background-color: #0e68c3;
        color: #fff;
      }
    }
    .navContent {
      width: 90%;
      margin: 0 auto;
      height: auto;
      padding-bottom: 30px;
      border-bottom: 1px solid #e5e5e5;
      .title {
        font-size: 22px;
      }
      .text {
        font-size: 16px;
        line-height: 40px;
        color: #666;
        list-style: disc;
        margin-left: 20px;
      }
    }
    .navContent0 {
      width: 90%;
      margin: 0 auto;
      padding-bottom: 30px;
      border-bottom: 1px solid #e5e5e5;
      .title {
        font-size: 22px;
        text-align: center;
      }
      .contentDiv {
        width: 100%;
        height: auto;
        // background-color: #0e68c3;
        display: flex;
        justify-content: space-between;
        padding: 50px 0;
        flex-wrap: wrap;
        .contentDivItem {
          width: 23%;
          height: 300px;
          background-color: rgb(255, 255, 255);
          box-shadow: 0 0 10px 3px rgb(210 210 210);
          margin-top: 30px;
          border-radius: 25%;
          text-align: center;
          padding: 120px 40px;
          .title {
            font-size: 20px;
            color: #333;
            margin-bottom: 15px;
          }
          .text {
            font-size: 16px;
            color: #999;
          }
        }
        .contentDivItem:hover {
          box-shadow: 0 0 20px 5px rgb(155, 155, 155);
        }
      }
    }

    .navContent2 {
      width: 60%;
      margin: 0 auto;
      padding-bottom: 30px;
    //   border-bottom: 1px solid #e5e5e5;
      .title {
        font-size: 22px;
        text-align: center;
        margin-bottom: 10px;
      }
      
    }
    .black {
      width: 100%;
      height: 120px;
      line-height: 120px;
      text-align: center;
      background-color: #f7f7f7;
      margin: 20px 0;
      .blackLink {
        color: #333;
        background-color: #e3e3e3;
        text-decoration: none;
        padding: 10px;
        border-radius: 5px;
      }
    }
  }
  
  .parameterTextItem{
    // padding: 10px;
    .parameterTextItemTitle{
        font-size: 18px;
        font-weight: 500;
        background-color: rgb(222, 222, 222);
        padding: 10px;
      }
    .ListTable{
      display: grid;
      grid-template-columns: 30% 70%;
      .ListTableName{
        text-align: center;
      }
      .ListTable-item{
        border: 0.5px solid #e3e3e3;
        padding: 10px;
      }
    }
  }
}
</style>