<template>
  <div class="home-lunbo">
    <el-carousel :interval="5000" arrow="always" height="600px">
      <el-carousel-item v-for="item in lunbolist" :key="item.id">
        <el-image :src="item.idView" alt="" class="lunbotu" fit="cover" @click="toProduct(item.id)">
        </el-image>
      </el-carousel-item>
    </el-carousel>
  </div>
</template>

<script>
export default {
  name: 'Home-lunbo',
  components: {
  },
  data() {
    return {
      lunbolist:[
        {
         id:1,idView:require("../../assets/lunbo/3gym.jpg")
        },
        {
          id:2,idView:require("../../assets/lunbo/2gym.jpg")
        },
        {
          id:3,idView:require("../../assets/lunbo/1gym.jpg")
        },
      ]
    }
  },
  methods:{
    toProduct(id){
      if(id == 3){
        console.log(this.$refs.navigation);
        this.$router.push({
          path:'productcenter',
        })
      }
    }
  }
}
</script>
<style lang="less" scoped>
 .el-carousel__item h3 {
    color: #475669;
    font-size: 18px;
    opacity: 0.75;
    line-height: 300px;
    margin: 0;
  }
  
  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }
  
  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
  .lunbotu{
    width: 100%;
    height: 600px;
    //opacity: 0;
  }
</style>
