import { render, staticRenderFns } from "./Vlog.vue?vue&type=template&id=19f69472&scoped=true&"
import script from "./Vlog.vue?vue&type=script&lang=js&"
export * from "./Vlog.vue?vue&type=script&lang=js&"
import style0 from "./Vlog.vue?vue&type=style&index=0&id=19f69472&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19f69472",
  null
  
)

export default component.exports