 <template>
  <div class="TheSolution">
    <el-image :src="src" lazy class="tupian" fit="cover"></el-image>
    <el-main>
      <div class="jiejuebiaoti mateDom">
        信创科技解决方案
        <div class="xiaozi mateDom">以混合生物识别技术、计算机视觉技术与行业深度融合，提升安全管理效率与智能化水平，赋能全场景智慧城市</div>
      </div>
      <div class="jiejue">
        <ul>
          <li  v-for="item in jiejuelist" :key="item.id" @click="toWisdom(item.id)" class="mateDom">
            <div class="fangan">
              <div class="jiejueimg">
                <img :src="item.src" alt="">
              </div>
              <div class="wenzi">{{item.name}}</div>
              <div class="ttitle">
                <div class="chakan">
                  {{item.chakan}}
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </el-main>
  </div>
</template>
<script>
export default {
  name: 'TheSolution',
  components: {},
  data() {
    return {
      src: require('../assets/jiejuefangan.jpg'),

      jiejuelist: [
        {
          id: 1,
          src: require('../assets/jiejuefangan/gongdi.png'),
          name: '智慧工地',
          chakan: '查看更多',
        },
        {
          id: 2,
          src: require('../assets/jiejuefangan/xiaoyuan.jpg'),
          name: '智慧校园',
          chakan: '查看更多',
        },
        {
          id: 3,
          src: require('../assets/jiejuefangan/shequ.jpg'),
          name: '智慧社区',
          chakan: '查看更多',
        },
        {
          id: 4,
          src: require('../assets/jiejuefangan/yuanqu.jpg'),
          name: '名厨亮灶',
          chakan: '查看更多',
        },
        {
          id: 5,
          src: require('../assets/jiejuefangan/yuntingche.jpg'),
          name: '智慧园区',
          chakan: '查看更多',
        },
      ]
    }
  },
  methods: {
    toWisdom(id){
      switch (id) {
        case 1:
          this.$router.push({
          path:'SmartConstructionSite',
          // query: {
          //   content: JSON.stringify(this.jiejuelist[index].contentAll),
          // },
        })
          break;
        case 2:
          this.$router.push({
          path:'smartcampus',
        })
          break;
        case 3:
          this.$router.push({
          path:'SmartCommunity',
        })
          break;
        case 4:
          this.$router.push({
          path:'SmartKitchen',
        })
          break;
          case 5:
          this.$router.push({
          path:'SmartPark',
        })
          break;
        default:
          break;
      }
      
    },
   getDivAddr(){
      // 获取要添加动画的dom元素（也可用ref）
      let dom = document.getElementsByClassName('mateDom')
      // 获取页面高度；后期与bottom做对比
      let height = document.documentElement.clientHeight
      // 给每一个添加动画的dom元素添加事件
      for (const iterator of dom) {
        // 获取dom距离可视区域的高度
        let list = iterator.getBoundingClientRect()
        // 利用top bottom距离可视区域顶部的距离判断元素是否在可视区域中
        if(list.bottom>0 && list.top<height-100){
          // 将预先隐藏的元素显示
          // 注意：用visibility而不是用disablay
          // 添加动画类名
        iterator.style.visibility='visible'
        iterator.classList.add('animate__animated', 'animate__fadeInUp');
      }
      }
    },
    // 监听滚动条事件
    windowScroll() {
      this.getDivAddr()
    },
  },
  mounted(){
    this.getDivAddr()
    // 监听滚动条
    window.addEventListener('scroll', this.windowScroll)
  }
}
</script>

<style lang="less" scoped>
.ttitle {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  .chakan {
    position: absolute;
    top: 50%;
    left: 5%;
    color: #fff;
    font-size: 18px;
    padding: 12px 16px;
    background-color: red;
  }
}
li:hover .ttitle {
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6) no-repeat center;
}

.jiejuebiaoti {
  width: 100%;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin: 0 auto;
  color: #2f2f2f;
  margin-top: 10px;
  margin-bottom: 40px;
  .xiaozi {
    font-size: 16px;
    color: #484748;
    margin: 25px 0 40px;
  }
}
.jiejue {
  background-color: aqua;
  width: 100%;
  //height: 494px;
  ul {
    //height: 494px;
    li {
      width: 33.333%;
      padding: 1%;
      //background-color: red;
      height: 100%;
      float: left;
      .fangan {
        position: relative;
        .jiejueimg {
          position: relative;
          width: 100%;
          height: 366px;
          background-color: black;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .wenzi {
          position: absolute;
          top: 40%;
          left: 5%;
          color: #fff;
          font-size: 22px;
          font-weight: 400;
          z-index: 2;
        }
      }
    }
  }
}
.TheSolution {
  // overflow:scroll;
  margin-top: 82px;
  margin-bottom: 40px;
}
.tupian {
  width: 100%;
  height: 368px;
}
.mateDom{
    visibility: hidden;
  }
</style>
