<template>
   <router-view></router-view>
</template>
<script>


export default {
  name: "MyApp",
}
</script>

<style lang="less">

</style>
