<template>
  <div class="while">
    <!--公司介绍 开始-->
    <el-main :style="elMainStyle">
        <div class="xinchuang">
            <div style="margin: auto;width: 100%;">
                <video src="https://www.neinninfo.com/file/video/caseCollectio.mp4" controls="controls" poster ="../assets/vlog/videoCover.jpg" autoplay="autoplay" muted = “false”  loop width="100%" height="600" object-fit="fill"></video>
            </div>
          <div class="jiejuebiaoti mateDom" style="font-size: 36px; color: #0b3bd3; font-weight:bold;line-height:1.5em;text-indent:2em;margin: 100px 0 20px 0" >1.项目核心</div>
          <div class="mateDom" style="font-size: 24px; line-height:1.5em;text-indent:2em;color:#585858;font-weight:bold;padding:0px 3%">公司于2023年初推出的AI智能影像产品，运用人工智能算法、大数据挖掘、AIGC、虚拟现实等技术，通过多种视频采集设备，基于自研AI算法引擎，打造“人+景”的明星式跟拍Vlog</div>
          <div class="fenge"></div>
          <div class="xinchuanggongsi">
            <ul class="mateDom">
              <li style="padding: 3%">
                <div class="fenge"></div>
                <p style="color: #0b3bd3;font-weight:bold;font-size: 30px;line-height:2em;">AI智能成像系统——旅游VLOG</p>
                <p style="color :#585858;font-size: 24px;line-height:2em;text-indent:2em;">用AI算法为游客自动生产vlog短视频，自动叠加特效，创新生态内容，打造云上宣传，覆盖转化公域流量，实现目的地营销，赋能景区多维数字化增长。该系统只为给游客带来“更美”"更甜”“更难忘”的专属VLOG精彩瞬间。</p>
                <p style="color: #0b3bd3;font-weight:bold;font-size: 30px;line-height:2em;">定制化视频，精细化触点，数据化治理</p>
              </li>
              <li style="width:50%;height:80%">
                <img src="../assets/vlog/vlog1.png" alt="" />
              </li>
            </ul>
          </div>
          <div class="jiejuebiaoti mateDom" style="font-size: 36px; color: #0b3bd3; font-weight:bold;line-height:1.5em;text-indent:2em;margin: 20px 0 20px 0" >2.项目场景 <span style="font-size: 24px;color:#585858">覆盖</span><span style="font-size: 24px;">十余种</span> <span style="font-size: 24px;color:#585858">场景，范围不断扩大中</span></div>
          <div class="jiejuebiaoti mateDom" style="text-align:center">
            <img src="../assets/vlog/scenarios.jpg" alt="" width="1200px" height="350px"/>
          </div>
          <div class="jiejuebiaoti mateDom" style="font-size: 36px; color: #0b3bd3; font-weight:bold;line-height:1.5em;text-indent:2em;margin: 20px 0 20px 0" >3.使用流程 <span style="font-size: 24px;color:#585858">微信小程序，一键扫脸，</span><span style="font-size: 24px;">15秒极速生成VLOG精彩短视频</span></div>
          <div class="jiejuebiaoti mateDom" style="text-align:center">
            <img src="../assets/vlog/flow.jpg" alt="" width="1200px" height="450px" />
          </div>
          <div class="fenge"></div>
          <div class="jiejuebiaoti mateDom" style="text-align: center; font-weight: bold;">合作案例</div>

            <div class="xinchuanggongsi">
              <div style="font-size: 20px; line-height:1.5em;text-indent:2em;font-weight:bold;text-indent:40%;margin-top: 10px;" class="mateDom">三亚亚龙湾某著名打卡点（5A）</div>
              <ul style="height: 450px; text-align: center;" class="mateDom">
                <li >
                  <img src="../assets/vlog/YLW.jpg" alt="" style="width:500px;height: 375px; float: right;"/>
                </li>
                <li style="">
                  <img src="../assets/vlog/YLWdata.jpg" alt="" style="width:500px;height: 375px; float: left;"/>
                </li>
              </ul>
              <div style="font-size: 20px; line-height:1.5em;text-indent:2em;font-weight:bold;text-indent:45%;" class="mateDom">浙江垂云通天河</div>
               <ul style="height: 480px;" class="mateDom">
                <li >
                  <img src="../assets/vlog/TTH.jpg" alt="" style="width:500px;height: 375px; float: right;"/>
                </li>
                <li style="">
                  <img src="../assets/vlog/TTHdata.jpg" style="width:500px;height: 375px; float: left;"/>
                </li>
              </ul>

              <div style="font-size: 20px; line-height:1.5em;text-indent:2em;font-weight:bold;text-indent:45%;" class="mateDom">东莞桥头生态园</div>
              <ul style="height: 480px;" class="mateDom">
              <li >
                <img src="../assets/vlog/QT2.jpg" alt="" style="width:500px;height: 375px; float: right;"/>
              </li>
              <li style="">
                <img src="../assets/vlog/QTdata.jpg" alt="" style="width:500px;height: 375px; float: left;"/>
              </li>
              </ul>
              <div style="font-size: 20px; line-height:1.5em;text-indent:2em;font-weight:bold;text-indent:45%;">湘潭窑湾古镇</div>
              <ul style="height: 480px;">
              <li >
                <img src="../assets/vlog/XT1.gif" alt="" style="width:500px;height: 375px; float: right;"/>
              </li>
              <li style="">
                <img src="../assets/vlog/XT2.jpg" alt="" style="width:500px;height: 375px; float: left;"/>
              </li>
              </ul>
    <!--
              <div style="font-size: 20px; line-height:1.5em;text-indent:2em;font-weight:bold;text-indent:45%;margin-top: 70px;">广州融创乐园</div>
              <div class="jiejuebiaoti mateDom" style="text-align:center;margin-top: -7px;">
            <img src="../assets/vlog/RC.jpg" alt="" width="100%" height="100%" />
          </div> -->
          <ul style="height: 850px;width: 1200px; margin:50px auto;box-shadow: 0px 0px 15px rgba(0,0,0,.35);display:flex;justify-content:center;" class="mateDom">
              <li >
                <div style="text-align:center;margin:20px 1%; width: 30%;height: 190px;float: left;">
                <img src="../assets/vlog/O1.jpg" alt="" width="100%" height="100%"/>
              </div>
              <div style="text-align:center;margin:20px 1%; width: 30%;height: 190px;float: left;">
                <img src="../assets/vlog/O2.jpg" alt="" width="100%" height="100%"/>
              </div>
              <div style="text-align:center;margin:20px 1%; width: 30%;height: 180px;float: left;">
                <img src="../assets/vlog/O3.gif" alt="" width="100%" height="100%"/>
              </div>
              <div style="text-align:center;margin:30px 1%; width: 40%;height: 200px;float: left;">
                <img src="../assets/vlog/O4.gif" alt="" width="100%" height="100%"/>
              </div>
              <div style="text-align:center;margin:30px 1%; width: 40%;height: 200px;float: left;">
                <img src="../assets/vlog/O5.jpg" alt="" width="100%" height="100%"/>
              </div>
              <div style="text-align:center;margin:20px 1%; width: 50%;height: 250px;float: left;">
                <img src="../assets/vlog/O6.gif" alt="" width="100%" height="100%"/>
              </div>
              <div style="text-align:center;margin:80px 1%; width: 260px;height: 140px;float: left;">
                <img src="../assets/vlog/O8.png" alt="" width="100%" height="100%"/>
              </div>
              </li>
              <li style="height: 700px;margin:70px 0px;">
                <img src="../assets/vlog/O7.jpg" alt="" />
              </li>
              </ul>
              <div  class="jiejuebiaoti mateDom" style="font-size: 38px; color: #0b3bd3; font-weight:bold;text-indent:2em;margin: 10px 20px 0px 0px" >合作方式，灵活可选</div>
              <div class="jiejuebiaoti mateDom" style="text-align:center">
            <img src="../assets/vlog/coopType.png" alt="" width="800px" height="400px"/>
          </div>
          <div  class="jiejuebiaoti mateDom" style="font-size: 38px; color: #0b3bd3; font-weight:bold;text-indent:2em;margin: 10px 20px 0px 0px" >合作流程</div>
              <div class="jiejuebiaoti mateDom" style="text-align:center">
            <img src="../assets/vlog/coopFlow.jpg" alt="" width="1250px" height="400px" />
          </div>
            </div>
        </div>
    </el-main>
    <!--公司介绍 结束-->
  </div>
</template>


<script>
import 'animate.css';
export default {
  name: "CompanyProfile",
  data(){
    return{
        elMainStyle:"overflow:hidden;height: 6500px;"
    }
  },
  methods:{
    setElementHeight() {
      // const screenWidth =  window.screen.width * window.devicePixelRatio;
      const screenHeight = window.screen.height * window.devicePixelRatio;

      // 根据不同的分辨率设置高度
      if (screenHeight >= 4320){
          // 8k屏
          this.elMainStyle = 'overflow:visible;height: 15000px;';
      }else if (screenHeight >= 2880){
          // 5k屏
          this.elMainStyle = 'overflow:visible;height: 12000px;';
      }else if (screenHeight >= 2160) {
          // 4k屏
          this.elMainStyle = 'overflow:visible;height: 10000px;';
      } else if (screenHeight >= 1440) {
          // 2k屏
          this.elMainStyle = 'overflow:visible;height: 8600px;';
      } else if (screenHeight >= 1080){
          // 1080p屏
          this.elMainStyle = 'overflow:visible;height: 6500px;';
      } else if (screenHeight >= 720){
          // 720p屏
          this.elMainStyle = 'overflow:visible;height: 6300px;';
      } else{
          // 480p屏
          this.elMainStyle = 'overflow:visible;height: 6000px;';
      }
    },
    getDivAddr(){
      // 获取要添加动画的dom元素（也可用ref）
      let dom = document.getElementsByClassName('mateDom')
      // 获取页面高度；后期与bottom做对比
      let height = document.documentElement.clientHeight
      // 给每一个添加动画的dom元素添加事件
      for (const iterator of dom) {
        // 获取dom距离可视区域的高度
        let list = iterator.getBoundingClientRect()
        // 利用top bottom距离可视区域顶部的距离判断元素是否在可视区域中
        if(list.bottom>0 && list.top<height-100){
          // 将预先隐藏的元素显示
          // 注意：用visibility而不是用disablay
          // 添加动画类名
        iterator.style.visibility='visible'
        iterator.classList.add('animate__animated', 'animate__fadeInUp');
      }
      }
    },
    // 监听滚动条事件
    windowScroll() {
      this.getDivAddr()
    },
  },
  mounted(){
    this.getDivAddr()
    // 监听滚动条
    window.addEventListener('scroll', this.windowScroll)
    // this.setElementHeight()
  }
};
</script>

<style lang='less' scoped>
.while {
  width: 100%;
  height: auto;
  padding-top: 82px;
  .xinchuang {
    //   margin-top: 30px;
    // background-color: rgb(240, 240, 240);
    .xinchuanggongsi {
      margin-bottom: 25px;
      width: 94%;
      height: 430px;
      margin: 0 auto;
      ul {
        height: 430px;
        li {
          width: 50%;
          height: 100%;
          background-color: rgb(255, 255, 255);
          float: left;
          img {
            height: 100%;
            width: 100%;
          }
          .gongsi {
            font-size: 24px;
            //color: #0b3bd3;
            color :#585858;
            font-weight: 400;
            line-height:1.5;
            text-indent:2em
          }
          .fenge {
            margin: 8px 0;
          }
        }
      }
    }
  }
  .jiejuebiaoti {
    font-size: 30px;
    line-height: 50px;
    color: #333;
    margin: 30px 0 20px 0;
  }
  .flexDiv {
    display: flex;
    justify-content: space-between;
    height: 360px;
    .flexDivItem {
      box-shadow: 0px 0px 10px 0px rgba(112, 112, 112, 0.2);
      overflow: hidden;
      .p1 {
        width: 100%;
        text-align: center;
        font-size: 20px;
        color: #333;
        line-height: 30px;
        padding: 30px 0 80px;
      }
      .p2 {
        font-size: 18px;
        color: #666;
        display: block;
      }
      .flexDivItem-img{
        transition: all 0s ease 0s;
      }
      // .flexDivItem-img:hover{

      // }
    }
    .flexDivItem:hover .flexDivItem-img{
      transform: scale(1.2);
    }
  }
  .mateDom{
    visibility: hidden;
  }
}
</style>
