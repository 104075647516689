<template>
  <div class="while">
    <el-image :src="require('@/assets/imgAll/wisdom/SmartCampus/zhxy.jpg')" fit="cover" class="backImg"></el-image>
    
    <!-- 内容区域 -->
    <div class="content">
      <p class="type animate__animated animate__backInLeft"><i class="el-icon-guide"></i> 智慧校园</p>
      <p class="title">选择教育</p>
      <el-card class="box-card">
        <div class="card">
          <div class="cardItem">
            <p class="cardItem_p">
              信创信息是一家以生物识别为核心技术，专业提供智慧身份核验、智慧出入口管理、智慧办公产品及解决方案的国家高新技术企业。
            </p>
            <p class="cardItem_p">
              在校园，我们发现多场景都有人脸产品的身影，比如校园大门的人脸识别通道、车牌识别、进出校园安检，校园宿舍的进出签到，教职工办公区的门禁，校园内的视频监控、AI智能分析、周界、一键求助等等。
            </p>
            <p class="cardItem_p">
              系统目前已经覆盖了门禁、考勤、消费、梯控、访客、停车、巡更、视频、一键求助、人证、通道、周界管理、AI智能分析等多个子系统。平台具备强大的集成能力和用户可视化数据展现分析功能，真正实现大规模全系统的安防智能化管理。为校园安全管理及数字化建设提供技术支撑。
            </p>
          </div>
          <div class="cardItem">
            <!-- <el-image src="../../assets/imgAll/wisdom/SmartCampus/gsImg01.jpg"  ></el-image> -->
            <el-image :src="require('@/assets/imgAll/wisdom/SmartCampus/tu2.png')" fit="contain"></el-image>
          </div>
          <div class="cardItem">
              <el-image :src="require('@/assets/imgAll/wisdom/SmartCampus/tu1.png')" fit="contain"></el-image>
          </div>
        </div>
      </el-card>
      <p class="title">建设内容</p>
      <el-card class="box-card">
        <p class="card-title">连接熵基万傲瑞联快速上线SaaS轻应用</p>
        <div class="card-left">
          <p class="card-text">
           启用熵基智联开启服务，即可使用目前已经上线的门禁、考勤等业务，同时应用模块还在持续更新，未来将持续扩展访客、视频、人证核验等云应用。用户无需部署系统，拿到设备连接互联网扫码注册即可，同时设备还支持识别腾讯微卡移动端个人身份电子码，实现卡、码、脸、指纹等通行方式。
          </p>
          <el-image
              :src="require('@/assets/imgAll/wisdom/SmartCampus/nr1.png')" fit="cover" class="faIMg"></el-image>
        </div>
        
        <p class="card-title">连接熵基熵安云通打通线下全场景</p>
        <div class="card-right">
          <el-image
              :src="require('@/assets/imgAll/wisdom/SmartCampus/nr2.png')" fit="cover" class="faIMg"></el-image>
          <p class="card-text">
            基于腾讯的开放能力，信创信息将线下场景与云端应用相互融合，将线下考勤、门禁、停车、AI智能视频、消费、宿舍管理、电子围栏、视频周界、一键求助、访客等全场景应用融入到了腾讯生态。为校园师生提供安全、工作、生活多维度数字化应用。
          </p>
        </div>
        
        <p class="card-title">校门访客</p>
        <div class="card-left">
          <p class="card-text"></p>
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartCampus/nr3.png')" fit="cover" class="faIMg"></el-image>
        </div>
        
        <p class="card-title">教职工考勤</p>
        <div class="card-right">
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartCampus/nr4.png')" fit="cover" class="faIMg"></el-image>
          <p class="card-text">
           考勤管理，为校园教职工提供考勤签到、考勤规则设定、考勤报表等功能，考勤记录支持考勤机签到、手机定位签到、门禁通行记录/车牌识别记录当考勤等，基于考勤记录结合丰富的考勤规则，输出多维度（日明细表、月汇总表）报表供管理者查看。
          </p>
        </div>
        
        
        <p class="card-title">安检、防疫测温</p>
        <div class="card-left">
          <p class="card-text">
           设备界面输出结果，红码禁止通行，黄码禁止通行需人工核验，绿码请通行，记录实时上传至熵安云通存档，红码、黄码、绿码是否通行做成参数可配，在设备中进行配置，同时上传门禁记录到熵安云通时，需要带上健康码信息;门禁设备支持开启/关闭核验健康码、口罩识别、测温功能，当开启时才走后台验证验证健康码或是否佩戴口罩或体温是否正常，同时设备界面、语音有响应提醒（如：请佩戴口罩、体温异常），关闭则正常验证身份和门禁权限即可
          </p>
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartCampus/nr5.png')" fit="contain" class="faIMg"></el-image>
        </div>

      </el-card>
      <p class="title">方案优势</p>
      <el-card class="box-card">
        <div class="card">
          <div class="cardItem">
            <p class="card-item-p">
              ①
              助学校：打造数字化校园、构筑学校安全智能化管理，实现全校师生考勤门禁管理、数据可视化管理，实现学校技防与信息化互联互通，确保校园实现全方位的综合管控。
            </p>
            <p class="card-item-p">
              ②
              助家长：加强家校互动、规范学生消费、获取学校通知、掌握学生考勤、监督学生，实现家校共育。
            </p>
            <p class="card-item-p">
              ①
              助学校：打造数字化校园、构筑学校安全智能化管理，实现全校师生考勤门禁管理、数据可视化管理，实现学校技防与信息化互联互通，确保校园实现全方位的综合管控。
            </p>
          </div>
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartCampus/ysImg01.jpg')" fit="cover" class="faIMg"
          ></el-image>
        </div>
      </el-card>
      <p class="title">特色功能</p>
      <el-card class="box-card">
        <div class="fanan">
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartCampus/gnImg01.jpg')"
            fit="cover"
            class="faIMg"
          ></el-image>
        </div>
      </el-card>
      <p class="title">成功案例</p>
      <el-card class="box-card">
        <div class="fanan">
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartCampus/al1.png')"
            fit="cover"
            class="faIMg"
          ></el-image>
        </div>
        <p class="card-title">案例：昆明西南联大研究院附属学校</p>
        <div>
          <p class="card-text">
           昆明西南联大附属学校建设电子学生卡、校园消费、校园门禁等场景，自建设以来，为学校师生稳定可靠提供超过170万次校园消费服务。为学校减少了充值中心老师的工作量，为学校商户提供了备餐依据，减少浪费的同时增加收入，为家长解决了孩子在校就餐消费不可知等问题，同时也为学生提供了稳定可靠的食堂、门禁系统，令学生在校更便捷，更安全。
          </p>
          <div class="alImgList">
            <el-image
              :src="require('@/assets/imgAll/wisdom/SmartCampus/al2.png')" fit="cover" class="alImg"></el-image>
            <el-image
              :src="require('@/assets/imgAll/wisdom/SmartCampus/al3.png')" fit="cover" class="alImg"></el-image>
            <el-image
              :src="require('@/assets/imgAll/wisdom/SmartCampus/al4.png')" fit="cover" class="alImg"></el-image>
          </div>
        </div>
        <p class="card-title">更多荣誉及案例</p>
        <ul>
          <li class="card-li">使用微校经费发放功能，帮助市局有效了解财政经费流向及使用情况，同时提高下属学校工作效率，实现局、校、家有机联动，为晋江教育局智慧校园建设提供了重要助力</li>
          <li class="card-li">重庆市巴蜀中学校于2021年正式启用微校校园卡，目前微校已落地了校园支付、家校沟通等场景，在学校内，已经有2万多名学生及家长领取了电子校园卡，日均使用人次超过了5万余次，大幅提高了消费场景效率的同时减少了学校的运营成本及压力，受到了师生们的一致好评。</li>
          <li class="card-li">“使用企业微信+微卡，学生通过人脸识别技术实现刷脸吃饭，在方便就餐的同时，帮助学校和家长及时了解学生消费情况，配合微卡自带办公应用有效提高我校移动办公管理水平，保护孩子健康成长。”——绵阳中学资阳育才学校</li>
          <li class="card-li">“农村大部分孩子是留守儿童，使用微校解决老人家现金支付的问题，也让出外打工父母有了许多校园生活的互动，目前学校每个学生每天在校都会通过微校完成2笔以上的食堂消费。在家长放心学生用钱和支付更便捷的条件下，学校食堂的加餐收入也增加了4倍。”——高州市石鼓中学</li>
        </ul>
        <div class="alImgList">
            <el-image
              :src="require('@/assets/imgAll/wisdom/SmartCampus/al5.png')" fit="cover" class="alImg"></el-image>
            <el-image
              :src="require('@/assets/imgAll/wisdom/SmartCampus/al6.png')" fit="cover" class="alImg"></el-image>
            <el-image
              :src="require('@/assets/imgAll/wisdom/SmartCampus/al7.png')" fit="cover" class="alImg"></el-image>
          </div>
      </el-card>
    </div>
    <div class="black">
        <router-link to="/home/thesolution" class="blackLink">
          <i class="el-icon-d-arrow-left"></i> 返回解决方案
        </router-link>
      </div>
  </div>
</template>

<script>
import 'animate.css';
export default {
  name: "SmartCampus",
  data() {
    return {
      obj: {},
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.while {
  width: 100%;
  height: auto;
  padding-top: 82px;
  .backImg{
    width: 100%;
    height: 370px;
  }
  .type{
    text-align: right;
    font-size: 21px;
    // font-weight: 500;
    font-family: STHupo;
  }
  .title {
    font-size: 18px;
    border-left: 2px solid #78bc27;
    padding-left: 10px;
    margin: 20px 0 30px 0;
  }
  .card {
    display: grid;
    grid-template-columns: 60% 40%;
    .cardItem {
      .cardItem_p {
        text-indent: 2em;
        font-size: 16px;
        color: #666;
        line-height: 32px;
        font-weight: 500;
      }
      .card-item-p {
        line-height: 2;
        font-size: 16px;
      }
    }
  }
  .card-title {
    font-weight: bold;
    font-size: 18px;
    margin: 20px 0;
  }
  .card-text {
    text-indent: 2em;
    font-size: 16px;
    line-height: 2;
  }
  
  .fanan {
    text-align: center;
    // width: 60%;
    // margin: 0 auto;
    .faIMg{
    width: 60%;
    margin: 0 auto;
  }
  }
  .content {
    width: 100%;
    height: auto;
    padding: 20px 3%;
  }
  .black {
      width: 100%;
      height: 120px;
      line-height: 120px;
      text-align: center;
      background-color: #f7f7f7;
      margin: 20px 0;
      .blackLink {
        color: #333;
        background-color: #e3e3e3;
        text-decoration: none;
        padding: 10px;
        border-radius: 5px;
      }
    }
    .card-left{
      display: grid;
      grid-template-columns: 40% 60%;
    }
    .card-right{
      display: grid;
      grid-template-columns: 60% 40%;
    }
    .alImgList{
      display: grid;
      grid-template-columns: auto auto auto;
      grid-template-rows: 300px;
      padding: 25px 50px;
      grid-gap: 50px 50px;
    }
    .card-li{
      // text-indent: 2em;
      font-size: 16px;
      line-height: 2;
      list-style:disc ;
      margin-left: 20px;
    }
}
</style>