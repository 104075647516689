<template>
  <div class="Wisdom-financial">
    <div class="jiejuebiaoti mateDom">
      <span class="zhuangshi"></span>
      功能介绍
      <span class="zhuangshi"></span>
    </div>
    <div class="jiejue">
      <ul class="clearfix">
        <li v-for="item in chanpinneironglist" :key="item.id" class="mateDom">
          <div class="jiejueimg">
            <el-image :src="item.src" alt="" lazy class="chanpintu" fit="scale-down"></el-image>
          </div>
          <div class="neirong">
            <div class="wenzi">{{item.name}}</div>
            <div class="ttitle">{{item.chakan}}</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="jiejuebiaoti mokuai mateDom">
      <span class="zhuangshi"></span>
      产品演示
      <span class="zhuangshi"></span>
    </div>
    <div class="yanshitu mateDom">
      <el-image :src="src"></el-image>
    </div>
    <div class="jiejuebiaoti mokuai mateDom">
      <span class="zhuangshi"></span>
      核心技术
      <span class="zhuangshi"></span>
    </div>
     <div class="heixin">
      <ul class="clearfix">
        <li v-for="item in heixinlist" :key="item.id" class="mateDom">
          <div class="jiejueimg">
            <el-image :src="item.src" alt="" lazy class="chanpintu" fit="scale-down"></el-image>
          </div>
          <div class="neirong">
            <div class="wenzi">{{item.name}}</div>
            <div class="ttitle">{{item.chakan}}</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="jiejuebiaoti mokuai mateDom">
      <span class="zhuangshi"></span>
      应用场景
      <span class="zhuangshi"></span>
    </div>
    <div class="yingyong">
        <ul class="clearfix">
          <li v-for="item in yingyonglist" :key="item.id" class="mateDom">
            <div class="ruanjian">
              <div class="jiejueimg">
                <el-image :src="item.src" alt="" lazy class="chanpintu" fit="fill"></el-image>
              </div>
              <div class="neirong">
                <div class="wenzi">{{item.name}}</div>
                <div class="ttitle">{{item.chakan}}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Wisdom-financial',
  components: {},
  data() {
    return {
      src: require('../../assets/zhihuijinrong/uasj.jpg'),
      chanpinneironglist: [
        {
          id: 1,
          src: require('../../assets/zhihuijinrong/wpar.jpg'),
          name: '用户画像',
          chakan: '根据用户自然属性、社会属性、行为等信息抽象出的一个标签化的用户模型，并且基于营销需求， 可以构建场景，划分主题，将数据分层，及时更新。'
        },
        {
          id: 2,
          src: require('../../assets/zhihuijinrong/x3x8.jpg'),
          name: '决策引擎',
          chakan: '决策引擎是策略管理BRMS解决方案的核心产品，提供相关业务策略的全生命周期的统一管理，包括可重用的简单规则、决策表、决策树和规则流等组件的编辑部署、运行、监控等功能。'
        },
        {
          id: 3,
          src: require('../../assets/zhihuijinrong/1rii.jpg'),
          name: '智能机器学习平台',
          chakan: '智能机器学习平台集合了模型设计平台，与机器学习平台的功能实现拖拽式操作的模型策略实验环境 用户可以便捷地筛选、清洗源数据，高效的加工模型因子和变量，并通过可视化的操作过程，将机器学习算法应用到实际的生产环境中。'
        }
      ],
      heixinlist: [
        {
          id: 1,
          src: require('../../assets/zhihuijinrong/26r9.png'),
          name: 'AI风控模型',
          chakan: '将机器学习、人工智能应用到风控模型中，提升效果和效率'
        },
        {
          id: 2,
          src: require('../../assets/zhihuijinrong/38pt.png'),
          name: '黑产名单',
          chakan: '识别市场上存在或可能存在黑产用户'
        },
        {
          id: 3,
          src: require('../../assets/zhihuijinrong/eukr.png'),
          name: '多维数据整合',
          chakan: '包含机器学习、深度学习、自然语言'
        },
        {
          id: 4,
          src: require('../../assets/zhihuijinrong/pbnw.png'),
          name: '建模',
          chakan: '由算法训练好的模型可以保存到模型模块'
        }
      ],
      yingyonglist: [
        {
          id: 1,
          src: require('../../assets/zhihuijinrong/3hqz.png'),
          name: '营销获客',
          chakan: '为客群进行划分提升营销效率'
        },
        {
          id: 2,
          src: require('../../assets/zhihuijinrong/h9wc.png'),
          name: '风控策略制定',
          chakan: '业务经营分析以及竞争分析，影响企业发展战略'
        },
        {
          id: 3,
          src: require('../../assets/zhihuijinrong/ap9w.png'),
          name: '金融信贷',
          chakan: '贷前审批信用风险、贷中额度评定，贷后行为监控、辅助催收策略'
        }
      ]
    }
  },
  methods:{
   getDivAddr(){
      // 获取要添加动画的dom元素（也可用ref）
      let dom = document.getElementsByClassName('mateDom')
      // 获取页面高度；后期与bottom做对比
      let height = document.documentElement.clientHeight
      // 给每一个添加动画的dom元素添加事件
      for (const iterator of dom) {
        // 获取dom距离可视区域的高度
        let list = iterator.getBoundingClientRect()
        // 利用top bottom距离可视区域顶部的距离判断元素是否在可视区域中
        if(list.bottom>0 && list.top<height-100){
          // 将预先隐藏的元素显示
          // 注意：用visibility而不是用disablay
          // 添加动画类名
        iterator.style.visibility='visible'
        iterator.classList.add('animate__animated', 'animate__fadeInUp');
      }
      }
    },
    // 监听滚动条事件
    windowScroll() {
      this.getDivAddr()
    },
  },
  mounted(){
    this.getDivAddr()
    // 监听滚动条
    window.addEventListener('scroll', this.windowScroll)
  }
}
</script>
<style lang="less" scoped>
.mateDom{
    visibility: hidden;
  }
.yanshitu {
  text-align: center;
}
.mokuai {
  padding-top: 50px;
}
/* 清除浮动 */
.clearfix:after {
  visibility: hidden;
  clear: both;
  display: block;
  content: '.';
  height: 0;
}

.clearfix {
  *zoom: 1;
}

.jiejuebiaoti {
  width: 100%;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin: 0 auto;
  color: #2f2f2f;
  //margin-top: 10px;
  margin-bottom: 25px;
  .zhuangshi {
    width: 140px;
    height: 1px;
    display: inline-block;
    background-color: rgb(204, 204, 204);
    //float: left;
    margin: 0 30px;
    margin-bottom: 8.5px;
  }
}

.jiejue {
  width: 100%;
  ul {
    li {
      width: 33.333%;
      padding: 1%;
      height: 100%;
      float: left;
      .jiejueimg {
        width: 100%;
        height: 290px;
        line-height: 290px;
        background-color: rgb(255, 255, 255);
        text-align: center;
        overflow: hidden;
        .chanpintu {
          width: 60%;
          height: 60%;
          vertical-align: middle;
          overflow: hidden;
          transition: ease 0.5s;
        }
      }
      .neirong {
        background-color: rgb(255, 255, 255);
        border-top: 1px solid #e5e5e5;
        height: 230px;
        text-align: center;
        padding: 28px 18px;
        transition: ease 0.5s;
        .wenzi {
          color: rgb(14, 14, 14);
          font-size: 22px;
          font-weight: 400;
          margin-bottom: 12px;
          transition: ease 0.5s;
        }
        .ttitle {
          font-size: 16px;
          line-height: 30px;
          color: #666;
          text-align: center;
        }
      }
    }
    li:hover .wenzi {
      color: #0e57a2;
    }
    li:hover .neirong {
      border-top: 1px solid #0e57a2;
      transform: scale(x, y);
    }
    li:hover .chanpintu {
      transform: scale(1.2);
    }
  }
}

.heixin {
  width: 100%;
  ul {
    li {
      width: 25%;
      padding: 1%;
      height: 100%;
      float: left;
      .jiejueimg {
        width: 100%;
        height: 290px;
        line-height: 290px;
        background-color: rgb(255, 255, 255);
        text-align: center;
        overflow: hidden;
        .chanpintu {
          width: 40%;
          height: 40%;
          vertical-align: middle;
          overflow: hidden;
          transition: ease 0.5s;
        }
      }
      .neirong {
        background-color: rgb(255, 255, 255);
        border-top: 1px solid #e5e5e5;
        height: 140px;
        text-align: center;
        padding: 28px 18px;
        transition: ease 0.5s;
        .wenzi {
          color: rgb(14, 14, 14);
          font-size: 22px;
          font-weight: 400;
          margin-bottom: 12px;
          transition: ease 0.5s;
        }
        .ttitle {
          font-size: 16px;
          line-height: 30px;
          color: #666;
          text-align: center;
        }
      }
    }
    li:hover .wenzi {
      color: #0e57a2;
    }
    li:hover .neirong {
      border-top: 1px solid #0e57a2;
      transform: scale(x, y);
    }
    li:hover .chanpintu {
      transform: scale(1.2);
    }
  }
}

.yingyong {
  width: 100%;
  ul {
    li {
      width: 33.333%;
      padding: 2%;
      //height: 100%;
      float: left;
      .ruanjian {
        box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.35);
        border-radius: 4px;
        background-color: rgb(255, 255, 255);
        overflow: hidden;
        transition: ease 0.5s;
        .jiejueimg {
          width: 100%;
          height: 290px;
          line-height: 290px;
          background-color: rgba(0, 0, 0, 0.7);
          text-align: center;
          vertical-align: middle;
          overflow: hidden;
          .chanpintu {
            width: 100%;
            height: 100%;
            overflow: hidden;
            transition: ease 0.5s;
          }
        }
        .neirong {
          height: 150px;
          text-align: center;
          padding: 20px 16px;
          transition: ease 0.5s;
          .wenzi {
            color: rgb(14, 14, 14);
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 18px;
            transition: ease 0.5s;
          }
          .ttitle {
            font-size: 14px;
            line-height: 30px;
            color: #666;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    li:hover .ruanjian .wenzi {
      color: #0e57a2;
    }
    li:hover .ruanjian {
      transform: scale(1.05);
    }
  }
}
</style>
