<template>
  <div class="homepage">
    <!--轮播  开始-->
    <Home-lunbo></Home-lunbo>
    <!--轮播  结束-->
    <!--解决方案  开始-->
    <el-main>
      <div class="jiejuebiaoti mateDom">
        致力于成为智慧城市领域的优选合作伙伴
      </div>
      <div class="jiejue">
        <ul>
          <!-- @click="toWisdom(item.id)" -->
          <li @mouseenter="yiru(item.id)" @mouseleave="yichu(item.id)" v-for="item in jiejuelist" :key="item.id" class="mateDom" >
            <router-link :to=item.path class="link">
              <div class="fangan">
              <div class="jiejueimg">
                <img :src="item.src" alt="">
              </div>
              <div class="ttitle" v-if="item.isActive">{{item.name}}</div>
              <div class="ttitle_bianhuan" v-else>{{item.name}}</div>
            </div>
            <div class="wenzi">
              <div class="txt">{{item.text}}</div>
              <div class="more">&gt;&gt;{{item.chakan}}</div>
            </div>
            </router-link>
            
          </li>
        </ul>
      </div>
    </el-main>
    <!--解决方案 结束-->
    <!--公司介绍 开始-->
    <el-main class="xinchuang">
      <div class="jiejuebiaoti mateDom">
        公司简介
      </div>
      <div class="xinchuanggongsi mateDom">
        <ul>
          <li>
            <img src="../assets/imgAll/wisdom/CompanyProfile/gongsiImg.jpg" alt="">
          </li>
          <li style="padding: 3%;">
            <div class="gongsi">深圳市信创信息科技有限公司</div>
            <div class="fenge"></div>
            <div class="qiyewenan">
              信创信息科技有限公司是广东深圳一家专业人工智能产品和和解决方案的高新技术企业公司。<br />
              主要包括：访客智能终端，客户端软件，二维码识别模组，人脸识别门禁一体机，人脸识别测温一体机，人脸识别人员实名制系统、人证合一核查系统、动态人脸识别智能系统、人脸识别智能考勤系统等软硬件产品。 信创信息科技有限公司凭借在视觉技术领域里多年的沉淀和积累，在智慧门禁、智慧园区、智慧校园、智慧楼宇等领域为客户提供了完整的解决方案，赢得了广大客户的认可和信赖。<br />
              公司非常重视企业的内部管理工作，市场销售、软件研发、技术支持是公司的三大核心部门，现已经建立了一套比较完善的管理体制。在客户服务方面，本着为客户服务的思想，设立了24小时产品咨询电话、24小时售后技术支持电话等多个无障碍通道，为客户提供了高质量的售前和售后的服务和强有力的支持。

            </div>
          </li>
        </ul>
      </div>
    </el-main>
    <!--公司介绍 结束-->
    <!--案例介绍 开始-->
    <el-main class="anli">
      <div class="jiejuebiaoti mateDom">
        信创科技为相关行业提供定制化解决方案
      </div>
      <el-carousel :interval="4000" type="card" height="280px" class="mateDom">
        <el-carousel-item v-for="item in anlilist" :key="item.id">
          <!-- <h3 class="medium">{{ item }}</h3> -->
          <div class="anlineirong">
            <el-image :src="item.src" alt="" class="img" fit="scale-down"></el-image>
            <div class="anliwenzi">{{item.name}}</div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </el-main>
    <!--案例介绍 结束-->
    
  </div>
</template>
<script>
import HomeLunbo from './Home-lunbo/Lunbo.vue'
export default {
  name: 'HomePage',
  components: {
    HomeLunbo,
  },
  data() {
    return {
      anlilist: [
        {
          id: 1,
          src: require('../assets/anli/1507604229.png'),
          name: '南京小牛智能科技有限公司'
        },
        {
          id: 2,
          src: require('../assets/anli/da0e3846e740963afa912113b72ca108.png'),
          name: '熵基科技股份有限公司'
        },
        {
          id: 3,
          src: require('../assets/anli/6378891425632303807291599.png'),
          name: '厦门科拓通讯技术股份有限公司'
        },
        {
          id: 4,
          src: require('../assets/anli/logo_new_hover.2a536506.png'),
          name: '北京旷视科技有限公司'
        },
        {
          id: 5,
          src: require('../assets/anli/55a462dd050372049493777438b26290.jpg'),
          name: '深圳市利恩信息技术股份有限公司'
        },
        {
          id: 6,
          src: require('../assets/anli/logo.png'),
          name: '郑州天迈科技股份有限公司'
        },
        {
          id: 7,
          src: require('../assets/anli/guangxinlogo.jpg'),
          name: '广信恒生科技发展有限公司'
        },
        {
          id: 8,
          src: require('../assets/anli/haomoulogo.png'),
          name: '皓眸智能科技有限公司'
        },
        {
          id: 9,
          src: require('../assets/anli/zhishenglogo.jpg'),
          name: '至盛信息技术股份有限公司'
        },
        {
          id: 10,
          src: require('../assets/anli/tianbo.webp'),
          name: '广东天波信息技术股份有限公司'
        },
        {
          id: 11,
          src: require('../assets/anli/julong.jpg'),
          name: '深圳市巨龙创视科技有限公司'
        },
        {
          id: 12,
          src: require('../assets/anli/chuangshu.jpg'),
          name: '深圳市创数智享科技有限公司'
        }
      ],
      jiejuelist: [
        {
          id: 1,
          src: require('../assets/jiejue/mj_bg1.png'),
          name: '智能校园解决方案',
          text: '信创信息智慧校园解决方案，以“人脸识别”技术提升校园...',
          chakan: '查看更多',
          isActive: true,
          path:'smartcampus'
        },
        {
          id: 2,
          src: require('../assets/jiejue/mj_bg01.png'),
          name: '智慧社区解决方案',
          text: '信创信息人脸门禁方案采用AI人脸识别技术，对内部人员和...',
          chakan: '查看更多',
          isActive: true,
          path:'SmartCommunity'
        },
        {
          id: 3,
          src: require('../assets/jiejue/mj_bg2.png'),
          name: '智慧园区解决方案',
          text: '信创信息人脸智慧园区解决方案，对员工/访客的进出记录...',
          chakan: '查看更多',
          isActive: true,
          path:'SmartPark'
        },
        {
          id: 4,
          src: require('../assets/jiejue/mj_bg3.png'),
          name: '智慧工地解决方案',
          text: '信创信息智慧工地解决方案结合一系列软硬件，轻松实现工...',
          chakan: '查看更多',
          isActive: true,
          path:'SmartConstructionSite'
        }
      ]
    }
  },
  methods: {
    yiru(id) {
      this.jiejuelist.some(item => {
        if (item.id == id) {
          item.isActive = false
        }
      })
    },
    yichu(id) {
      this.jiejuelist.some(item => {
        if (item.id == id) {
          item.isActive = true
        }
      })
    },
   getDivAddr(){
      // 获取要添加动画的dom元素（也可用ref）
      let dom = document.getElementsByClassName('mateDom')
      // 获取页面高度；后期与bottom做对比
      let height = document.documentElement.clientHeight
      // 给每一个添加动画的dom元素添加事件
      for (const iterator of dom) {
        // 获取dom距离可视区域的高度
        let list = iterator.getBoundingClientRect()
        // 利用top bottom距离可视区域顶部的距离判断元素是否在可视区域中
        if(list.bottom>0 && list.top<height-100){
          // 将预先隐藏的元素显示
          // 注意：用visibility而不是用disablay
          // 添加动画类名
        iterator.style.visibility='visible'
        iterator.classList.add('animate__animated', 'animate__fadeInUp');
      }
      }
    },
    // 监听滚动条事件
    windowScroll() {
      this.getDivAddr()
    },
  },
  mounted(){
    this.getDivAddr()
    // 监听滚动条
    window.addEventListener('scroll', this.windowScroll)
  }
}
</script>

<style lang="less" scoped>
.homepage{
  margin-top: 82px;
}
.anlineirong {
  position: relative;
  width: 100%;
  .img {
    width: 100%;
    height: 280px;
    background-color: #fff;
  }
  .anliwenzi {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 20%;
    background: rgba(0, 0, 0, 0.6) no-repeat center;
    color: #fff;
    font-size: 18px;
    text-align: center;
    line-height: 56px;
  }
}
.anli {
  //height: 350px;
  padding-top: 60px;
  background: url('../assets/05.jpg') no-repeat;
  width: 100%;
  height: 490px;
  background-size: 100% 100% !important;
  .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n + 1) {
    background-color: #d3dce6;
  }
}

.xinchuang {
  margin-top: 30px;
  background-color: rgb(240, 240, 240);
  .xinchuanggongsi {
    margin-top: 40px;
    margin-bottom: 25px;
    width: 100%;
    height: 430px;
    ul {
      height: 430px;
      li {
        width: 50%;
        height: 100%;
        background-color: rgb(255, 255, 255);
        float: left;
        img {
          height: 100%;
          width: 100%;
        }
        .gongsi {
          font-size: 24px;
          color: #333;
          font-weight: 400;
        }
        .fenge {
          margin: 8px 0;
        }
        .qiyewenan {
          font-size: 16px;
          color: #555;
          font-weight: 300;
          line-height: 1.5;
        }
      }
    }
  }
}
.fenge::before {
  content: '';
  display: inline-block;
  vertical-align: middle;
  min-height: inherit;
  width: 50px;
  // background-color: red;
  height: 2px;
}

.jiejuebiaoti {
  width: 100%;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin: 0 auto;
  color: #2f2f2f;
  margin-top: 10px;
  margin-bottom: 40px;
}
.jiejue {
  background-color: aqua;
  width: 100%;
  //height: 494px;
  ul {
    //height: 494px;
    li {
      width: 25%;
      padding: 1%;
      //background-color: red;
      height: 100%;
      float: left;
      .fangan {
        position: relative;
        .jiejueimg {
          position: relative;
          width: 100%;
          height: 366px;
          background-color: black;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .ttitle {
          //display: none;
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 20%;
          background: rgba(0, 0, 0, 0.6) no-repeat center;
          color: #fff;
          font-size: 18px;
          padding-left: 30px;
          line-height: 74px;
        }
        .ttitle_bianhuan {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 20%;
          background: rgba(255, 0, 0, 0.6) no-repeat center;
          color: #fff;
          font-size: 18px;
          padding-left: 30px;
          line-height: 74px;
        }
      }
      .wenzi {
        width: 100%;
        height: 128px;
        border: 1px solid #cecece;
        padding: 25px;
        .txt {
          font-size: 16px;
          line-height: 20px;
          color: #000;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          margin-bottom: 18px;
        }
        .more {
          font-size: 14px;
          line-height: 20px;
          color: #999;
        }
      }
    }
  }
  .mateDom{
    visibility: hidden;
  }
  .link{
    text-decoration: none;
  }
}
</style>
