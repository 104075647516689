<template>
  <div class="while">
    <el-image
      :src="require('@/assets/imgAll/wisdom/SmartCommunity/backImg.jpg')"
      fit="cover"
      class="backImg"
    ></el-image>

    <!-- 内容区域 -->
    <div class="content">
      <p class="type animate__animated animate__backInLeft">
        <i class="el-icon-guide"></i> 智慧社区
      </p>
      <p class="title">行业概述</p>
      <el-card class="box-card">
        <div class="card">
          <div class="cardItem">
            <p class="cardItem_p">
              智慧社区是利用物联网、云计算、移动互联网、信息智能终端等新一代信息技术
              ,
              通过对各类与社区居民生活密切相关信息的自动感知、及时传送、及时发布和信息资源的整合共享
              , 实现对社区居民 “ 吃、住、行、游、购、娱、健 ”
              生活七大要素的数字化、网络化、智能化、互动化和协同化。
            </p>
          </div>
          <div class="cardItem">
            <!-- <el-image src="../../assets/imgAll/wisdom/SmartCampus/gsImg01.jpg"  ></el-image> -->
            <img
              src="../../assets/imgAll/wisdom/SmartCommunity/zhsqImg01.jpg"
            />
          </div>
          <div class="cardItem">
            <img
              src="../../assets/imgAll/wisdom/SmartCommunity/zhsqImg02.jpg"
            />
          </div>
        </div>
      </el-card>
      <p class="title">需求分析</p>
      <el-card class="box-card">
        <div class="fanan">
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartCommunity/fxImg01.png')"
            fit="cover"
            class="faIMg"
          ></el-image>
        </div>
      </el-card>
      <p class="title">解决方案</p>
      <el-card class="box-card">
        <p class="box-card_p">
          通过利用物联网、移动互联网、云计算等技术，对社区入口、社区公共区域、社区单元门口、住户家庭等进行高度整合及统一平台，形成“平台+产品+运营”的服务模式，提升社区治理和管理水平，完善社区公共服务和便民利民智能化服务，打造安全、便利、高品质智慧社区。
        </p>
        <div class="fanan">
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartCommunity/faImg01.png')"
            fit="cover"
            class="faIMg"
          ></el-image>
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartCommunity/faImg02.jpg')"
            fit="cover"
            class="faIMg"
          ></el-image>
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartCommunity/faImg03.jpg')"
            fit="cover"
            class="faIMg"
          ></el-image>
        </div>
      </el-card>
      <p class="title">方案优势</p>
      <el-card class="box-card">
        <div class="fanan">
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartCommunity/ysImg01.jpg')"
            fit="cover"
            class="faIMg"
          ></el-image>
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartCommunity/ysImg02.jpg')"
            fit="cover"
            class="faIMg"
          ></el-image>
        </div>
      </el-card>
      <p class="title">特色功能</p>
      <el-card class="box-card">
        <div class="fanan">
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartCommunity/gnImg01.jpg')"
            fit="cover"
            class="faIMg"
          ></el-image>
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartCommunity/gnImg02.jpg')"
            fit="cover"
            class="faIMg"
          ></el-image>
        </div>
      </el-card>
    </div>
    <div class="black">
      <router-link to="/home/thesolution" class="blackLink">
        <i class="el-icon-d-arrow-left"></i> 返回解决方案
      </router-link>
    </div>
  </div>
</template>

<script>
import "animate.css";
export default {
  name: "SmartCommunity",
  data() {
    return {
      obj: {},
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.while {
  width: 100%;
  height: auto;
  padding-top: 82px;
  .backImg{
    width: 100%;
    height: 370px;
  }
  .type {
    text-align: right;
    font-size: 21px;
    // font-weight: 500;
    font-family: STHupo;
  }
  .title {
    font-size: 18px;
    border-left: 2px solid #78bc27;
    padding-left: 10px;
    margin: 20px 0 30px 0;
  }
  .card {
    display: grid;
    grid-template-columns: 60% 40%;
    .cardItem {
      .cardItem_p {
        text-indent: 2em;
        font-size: 16px;
        color: #666;
        line-height: 32px;
        font-weight: 500;
      }
      .card-item-p {
        line-height: 2;
        font-size: 16px;
      }
    }
  }
  .card-title {
    font-weight: bold;
    font-size: 18px;
    margin: 20px 0;
  }
  .card-text {
    text-indent: 2em;
    font-size: 16px;
    line-height: 2;
  }
  .fanan {
    text-align: center;
  }
  .content {
    width: 100%;
    height: auto;
    padding: 20px 3%;
  }
  .black {
    width: 100%;
    height: 120px;
    line-height: 120px;
    text-align: center;
    background-color: #f7f7f7;
    margin: 20px 0;
    .blackLink {
      color: #333;
      background-color: #e3e3e3;
      text-decoration: none;
      padding: 10px;
      border-radius: 5px;
    }
  }
  .box-card_p {
    line-height: 2;
    font-size: 16px;
    margin-bottom: 20px;
  }
}
</style>