<template>
  <div class="container">
    <div id="container"></div>
  </div>
</template>

<script>
export default {
  name: "MapPage",
  data() {
    return {
      markerLayer: {},
      map: {},
    };
  },
  methods: {
    // 地图初始化函数
    initMap(lat,lng) {
      console.log(lat,lng);
      // var center = new TMap.LatLng(lat,lng);//标记点
      var center = new TMap.LatLng(22.584404,113.864578);//标记点

      // 初始化地图
      var map = new TMap.Map("container", {
        zoom: 14, // 设置地图缩放
        center: center, // 设置地图中心点坐标，
        pitch: 45, // 俯仰度
        rotation: 0, // 旋转角度
      });

      // MultiMarker文档地址：https://lbs.qq.com/webApi/javascriptGL/glDoc/glDocMarker
      var marker = new TMap.MultiMarker({
        map: map,
        styles: {
          // 点标记样式
            marker: new TMap.MarkerStyle({
            width: 20, // 样式宽
            height: 30, // 样式高
            anchor: { x: 10, y: 30 }, // 描点位置
          }),
        },
        geometries: [
          // 点标记数据数组
          {
            // 标记位置(纬度，经度，高度)
            position: center,
            id: "marker",
          },
        ],
      });
    },

    initMap2() {
        //定义地图中心点坐标
        var center = new TMap.LatLng(22.584404,113.864578)
        //定义map变量，调用 TMap.Map() 构造函数创建地图
        var map = new TMap.Map(document.getElementById('container'), {
            center: center,//设置地图中心点坐标
            zoom: 14,   //设置地图缩放级别
            pitch: 45,  //设置俯仰角
            rotation: 0    //设置地图旋转角度
        });

        //创建并初始化MultiMarker
        var markerLayer = new TMap.MultiMarker({
            map: map,  //指定地图容器
            //样式定义
            styles: {
                //创建一个styleId为"myStyle"的样式（styles的子属性名即为styleId）
                "myStyle": new TMap.MarkerStyle({ 
                    "width": 35,  // 点标记样式宽度（像素）
                    "height": 35, // 点标记样式高度（像素）
                    //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
                    "anchor": { x: 16, y: 32 },
                    "src":require("@/assets/imgAll/marker.png")
                }) 
            },
            //点标记数据数组
            geometries: [{
                "id": "1",   //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
                "styleId": 'myStyle',  //指定样式id
                "position": new TMap.LatLng(22.584404,113.864578),  //点标记坐标位置
            }]
        });
    },

    // 根据地址获取坐标
    addrToGetCoordinate(addr) {
        let that=this
        this.$jsonp('https://apis.map.qq.com/ws/geocoder/v1/', {
        key: 'WU5BZ-Y7JK2-IYFUL-CLBU7-FJN7Q-DFBJG',
        output: 'jsonp',
        address: addr
      })
        .then((res) => {
          console.log(res)
          if (res.status === 0) {
            // 处理得到的经纬度
            // this.coordinate.lat = res.result.location.lat.toFixed(6)
            // this.coordinate.lng = res.result.location.lng.toFixed(6)
            // this.postForm.store_longitude = res.result.location.lng
            // this.postForm.store_latitude = res.result.location.lat
            // 用获取到的经纬度，修改地图的中心点
            that.initMap(res.result.location.lat.toFixed(6),res.result.location.lng.toFixed(6))

          }
        })
        .catch((e) => {
          console.log(e)
        })
    },
  },
  mounted() {
    // this.addrToGetCoordinate("深圳市宝安区西乡街道华丰互联网创意园A座一楼101")
    this.initMap2()
  },
};
</script>

<style scoped lang='less'>
#container {
  width: 100%;
  height: 300px;
  #container {
      width: 100%;
      height: 100%;
    }
    .btnContainer {
      position: absolute;
      left: 20px;
      top: 20px;
      z-index: 10000;
    }

    .btnContainer button {
      padding: 10px 14px;
      box-sizing: border-box;
      border: none;
      background-color: #3876ff;
      border-radius: 2px;
      color: #fff;
      font-size: 14px;
      line-height: 14px;
      margin-bottom: 10px;
    }
}
</style>