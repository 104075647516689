<template>
  <div class="dukaqi">
    <div class="title">
      <p
        @click="titleIndex = index"
        :class="[titleIndex == index ? 'titleActive' : '']"
        v-for="(item, index) in chanpinneironglist"
        :key="index"
      >
        {{ item.title }}
      </p>
    </div>

    <div class="jiejue">
      <ul>
        <li
          v-for="item in chanpinneironglist[titleIndex].contentList"
          :key="item.id"
          @click="toDetails(item)"
          class="mateDom"
        >
          <div class="jiejueimg">
            <el-image :src="item.src" alt="" lazy class="chanpintu" fit="contain"></el-image>
            <!-- <el-image :src="productObj.srcDetails" lazy class="tupian" ></el-image> -->
          </div>
          <div class="neirong">
            <div class="wenzi">{{ item.name }}</div>
            <div class="ttitle">{{ item.chakan }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Caijiqi",
  components: {},
  data() {
    return {
      chanpinneironglist: [
        {
          id: 0,
          title: "智慧校园系列",
          contentList: [
            {
              id: 1,
              src: require("../../assets/imgAll/sexiang/HX-XM100/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/HX-XM100/sxt1.png"), //产品详情页图
              introduce: `校门高密签到系统`, //介绍
              name: "HX-XM100",
              effect: {
                title: "产品功能",
                content: [
                  "前端高效人脸抓拍，配合后端，完成智能签到。",
                  "人脸抓拍识别率高，支持多人脸通过抓拍。",
                  "布控简单，安装方便，无感签到，无需主动配合。",
                  "支持人脸识别、人脸比对。",
                  "采用深度学习算法。",
                  "学生未到校预案管理。",
                  "离校情况实时提示。",
                  "未到校二次复核补签。",
                  "学生到校统计报表4K超清大范围高速人脸抓拍识别。",
                  "可疑人员预警，保障校园安全。",
                  "支持万级人脸库离线识别。",
                  "自动人脸曝光调整，适应性更强。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 2,
              src: require("../../assets/imgAll/sexiang/HX-SG101/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/HX-SG101/sxt1.png"), //产品详情页图
              introduce: `动态无感知人脸识别宿管系统`, //介绍
              name: "HX-SG101",
              effect: {
                title: "产品功能",    
                content: [
                  "前端高效人脸抓拍，配合后端，完成智能签到。",
                  "人脸抓拍识别率高，支持多人脸通过抓拍。",
                  "布控简单，安装方便，无感签到，无需主动配合。",
                  "支持人脸识别、人脸比对。",
                  "采用深度学习算法。",
                  "学生归寝预案管理。",
                  "未归寝情况实时提示。",
                  "未归寝二次复核补签。",
                  "学生归寝统计报表4K超清大范围高速人脸抓拍识别。",
                  "可疑人员预警，保障校园安全多人并行人脸抓拍识别。",
                  "10万级人脸库人脸比对。",
                  "支持进出宿舍双向管理。",
                  "宿舍归寝情况查询，报表生成。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 3,
              src: require("../../assets/imgAll/sexiang/HX-DM102/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/HX-DM102/sxt1.png"), //产品详情页图
              introduce: `教室无感点名球`, //介绍
              name: "HX-DM102",
              effect: {
                title: "产品功能",    
                content: [
                  "内嵌智能人脸算法，实现智能自动课堂点名，无需排队等待签到，规避代打卡、代答等现象。",
                  "支持人脸识别区域选择；多种参数设定，人脸参数设置，专业级抓拍率。",
                  "布控简单，安装方便，学生无感点名，无需主动配合。",
                  "支持人脸30*30-300*300像素以上的人脸检测。",
                  "支持人脸图片及原图上传，人脸图片编码质量可调。",
                  "支持点名次数可设。",
                  "配合后端业务智能产品，实现更多业务应用，可对目标图片进行二次分析。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 4,
              src: require("../../assets/imgAll/sexiang/HX-BK103/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/HX-BK103/sxt1.png"), //产品详情页图
              introduce: `校园周界人脸布控系统`, //介绍
              name: "HX-BK103",
              effect: {
                title: "产品功能",    
                content: [
                  "内置深度学习人脸人形识别抓拍算法。",
                  "支持多种智能报警联动输出。",
                  "支持目标计数、电子围栏、逆行检测、离岗侦测。",
                  "支持自动电子快门功能,适应不同监控环境。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 5,
              src: require("../../assets/imgAll/sexiang/HX-W9131IHBB79/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/HX-W9131IHBB79/sxt1.png"), //产品详情页图
              introduce: `360°教室全景系统`, //介绍
              name: "HX-W9131IHBB79",
              effect: {
                title: "产品功能",    
                content: [
                  "最高分辨率可达12M(4000*3000  @ 20fps)。",
                  "支持低码率、低延时、ROI感兴趣区域增强编码。",
                  "H.265高效压缩，节省带宽和存储空间。",
                  "支持多行OSD，OSD 颜色自选。",
                  "高效红外灯,使用寿命长,照射距离可达15-20米。",
                  "支持Smart IR，防止夜间红外过曝。",
                  "ICR红外滤片式自动切换,实现真正的日夜监控。",
                  "支持PoE供电功能（选配）。",
                  "支持Micro SD卡(128G)本地存储。",
                  "支持3D数字降噪, 支持DWDR。",
                  "支持自动电子快门功能,适应不同监控环境。",
                  "支持双向语音对讲。",
                  "支持2路报警输入/1路报警输出。",
                  "支持GB28181接入,支持Onvif接入。",
                  "支持Email、FTP、NTP服务。",
                  "支持HTTPS加密传输。",
                  "初始设备开机修改密码，保障密码安全。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 6,
              src: require("../../assets/imgAll/sexiang/HX-DM8K01/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/HX-DM8K01/sxt1.png"), //产品详情页图
              introduce: `操场全景智能系统`, //介绍
              name: "HX-DM8K01",
              effect: {
                title: "产品功能",    
                content: [
                  "最高分辨率可达8192X3840",
                  "支持低码率、低延时、ROI感兴趣区域增强编码。",
                  "180°全景拼接，增加广阔环境下监控区域。",
                  "自主图像校正算法，优化拼缝。",
                  "支持Micro SD卡(128G)本地存储。",
                  "支持3D数字降噪, 支持白平衡可调整。",
                  "ICR红外滤片式自动切换,实现真正的日夜监控。",
                  "支持PoE供电功能（选配）。",
                  "支持Micro SD卡(128G)本地存储。",
                  "支持3D数字降噪, 支持DWDR。",
                  "支持自动电子快门功能,适应不同监控环境。",
                  "支持双向语音对讲。",
                  "支持2路报警输入/1路报警输出。",
                  "支持Onvif,GB28181(可选)。",
                  "支持Email、FTP、NTP服务。",
                  "支持HTTPS加密传输。",
                  "初始设备开机修改密码，保障密码安全。",
                  "支持防雷、防浪涌、防静电。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 7,
              src: require("../../assets/imgAll/sexiang/W6235IHBB87/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/W6235IHBB87/sxt1.png"), //产品详情页图
              introduce: `声光报警人形检测摄像机(支持声光警戒可选自带藏线盒6/8mm镜头可选)`, //介绍
              name: "W6235IHBB87 3516CV500+IMX307人形检测",
              effect: {
                title: "产品功能",    
                content: [
                  "H.265",
                  "1080P 分辨率。",
                  "1/2.8  星光级CMOS。",
                  "ICR。",
                  "0Lux(红外开)。",
                  "3.6mm 镜头。",
                  "2颗红外灯。",
                  "10-15M 红外距离。",
                  "人车检测智能功能 (人员计数，电子围栏，离岗侦测，人员逆行)。",
                  "真实宽动态。",
                  "ROI。",
                  "3D降噪。",
                  "支持三码流。",
                  "IP65。",
                  "DC12V（POE可选）。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 8,
              src: require("../../assets/imgAll/sexiang/ZH-WD262-10X/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/ZH-WD262-10X/sxt1.png"), //产品详情页图
              introduce: `教室点名球（200W像素4.寸迷你智能人脸比对点名高速球）`, //介绍
              name: "ZH-WD262-10X",
              effect: {
                title: "产品功能",    
                content: [
                  "记录操作过程,支持最大水平180°扫描，支持最大30个轨迹记忆点,云台速度、机芯倍率可调",
                  "支持预置位巡视点名模式:最大支持2条,每条最大支持20个预置位号,可设置停留时间。",
                  "支持人脸点名任务定时设置,最多5个时间段；支持周一至周日任务勾选。",
                  "支持最远20M范围内的人脸抓拍并比对,满足大、中、小学教室使用需求。",
                  "内置精密电动驱动,设备反应灵敏、运转平稳,实现图像无抖动。",
                  "布控简单，安装方便，学生无感点名，无需主动配合。",
                  "内嵌智能深度学习人脸算法，自身提取目标特征，形成深层可供学习的人脸图像。",
                  "支持前端人脸比对，支持对运动人脸进行检测、跟踪、抓拍、评分、特征提取、识别，输出最优人脸比对结果。",
                  "支持多种人脸抓拍模式：快速、间隔、离开后（有：距离选优、质量选优）等人脸抓拍模式，抓拍次数可设。",
                  "支持每帧最多32个人脸检测；支持人脸30*30-500*500像素之间的人脸检测。",
                  "支持最多3个人脸库的管理，支持最多22400张人脸库；支持多个布防时间段设置。",
                  "支持合计人脸库的存储空间最大16GB，单张人脸支持150*150-960*960像素区间。",
                  "支持黑白名单比对，VIP名单库比对，非人脸库人员识别，完成报警输出。",
                  "内置10倍电动变焦镜头，操作简易，变焦过程平稳。",
                  "配合后端业务智能产品，实现更多业务应用，可对目标图片进行二次分析。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 9,
              src: require("../../assets/imgAll/sexiang/HX-HW-FB-03/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/HX-HW-FB-03/sxt1.png"), //产品详情页图
              introduce: ` AI行为表情智能盒子（比例-时间、行为表情比例-时间等趋势分析课堂专注度，通过综合量化和归一化处理得出课堂专注度。在学生方面，通过学生表情散点-时间、行为散点-时间趋势分布分析学生课堂专注度。相关教师或家长可通过课堂专注度/学生课堂专注度初步评估课程教学质量与学生学习态度及学习质量。）`, //介绍
              name: "HX-HW-FB-03",
              effect: {
                title: "产品功能",    
                content: [
                  "A53+NPU处理器",
                  "主频 1.8 GHz。",
                  "高性能+强大的Ai 算力。",
                  "3GB DDR3。",
                  "内置16GB EMMC。",
                  "GPU 采用 Mali-T860MP4。",
                  "支持 1080P 视频编解码、H.265硬解码。",
                  "低功耗绿色省电、防尘。",
                  "支持7*24小时工作。",
                  "支持Video、Flash、Office、图片、文本等多媒体文件组合播放。",
                  "支持全屏播放及自由分屏/组合播放。",
                  "默认播放、周期播放、定时播放、即时插播、字幕插播。",
                  "远程设定自动开关机时间。",
                  "多存储介质支持功能：可支持U 盘和 SD 卡。",
                  "多国语言：支持中文、英文等绝大多数国家语言，TTF 矢量字库支持，显示不失真。",
                  "支持蓝牙功能。人脸检测模块、人脸识别模块、表情分析模块、课堂行为分析模块软件授权超高的3 TFLOPs AI边缘算力。",
                  "电源DC12V/2A。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
          ],
        },
        {
          id: 1,
          title: "200万高清网络摄像机",
          contentList: [
            {
              id: 1,
              src: require("../../assets/imgAll/sexiang/Hi3516DV300+IMX327/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/Hi3516DV300+IMX327/sxt1.png"), //产品详情页图
              introduce: `人脸抓拍枪型摄像机`, //介绍
              name: "FW6237IHZ55 Hi3516DV300+IMX327人脸抓拍",
              effect: {
                title: "产品功能",
                content: [
                  "2MP人脸抓拍摄像机。",
                  "H.265/H.264/MJPEG 三码流编码。",
                  "1/2.8 星光级CMOS。",
                  "Color: 0.01Lux。",
                  "B/W: 0.001Lux。",
                  "8mm镜头。",
                  "最大人脸检测数≤30。",
                  "人脸抓拍大小：人脸像素≥96*96pxl。",
                  "姿态要求：俯仰30°内,左右30°内。",
                  "支持择优输出,去重复功能。",
                  "曝光/增益/色饱和度等可调。",
                  "RJ45，DC12V。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 2,
              src: require("../../assets/imgAll/sexiang/Hi3516DV300+IMX327/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/Hi3516DV300+IMX327/sxt1.png"), //产品详情页图
              introduce: `人脸抓拍枪型摄像机`, //介绍
              name: "FW6236IHZ55 Hi3516DV300+IMX385人脸抓拍",
              effect: {
                title: "产品功能",
                content: [
                  "2MP人脸抓拍摄像机。",
                  "H.265/H.264/MJPEG 三码流编码。",
                  "1/2 星光级CMOS。",
                  "Color: 0.01Lux。",
                  "B/W: 0.001Lux。",
                  "8mm镜头。",
                  "最大人脸检测数≤30。",
                  "人脸抓拍大小：人脸像素≥96*96pxl。",
                  "姿态要求：俯仰30°内,左右30°内。",
                  "支持择优输出,去重复功能。",
                  "曝光/增益/色饱和度等可调。",
                  "RJ45，DC12V。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 3,
              src: require("../../assets/imgAll/sexiang/Hi3516DV300+IMX327/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/Hi3516DV300+IMX327/sxt1.png"), //产品详情页图
              introduce: `人脸抓拍枪型摄像机`, //介绍
              name: "FW6173IHZ55 CV28S33M+IMX327人脸抓拍",
              effect: {
                title: "产品功能",
                content: [
                  "2MP人脸抓拍摄像机。",
                  "H.265/H.264/MJPEG 三码流编码。",
                  "1/2.8 星光级CMOS。",
                  "Color: 0.01Lux。",
                  "B/W: 0.001Lux。",
                  "8mm镜头。",
                  "最大人脸检测数≤30。",
                  "人脸抓拍大小：人脸像素≥96*96pxl。",
                  "姿态要求：俯仰30°内,左右30°内。",
                  "支持择优输出,去重复功能。",
                  "曝光/增益/色饱和度等可调。",
                  "RJ45，DC12V。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 4,
              src: require("../../assets/imgAll/sexiang/FW6237IHBB55A/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/FW6237IHBB55A/sxt1.png"), //产品详情页图
              introduce: `人脸抓拍半球摄像机(标配6-22mm电动镜头)`, //介绍
              name: "FW6237IHBB55A Hi3516DV300+IMX327人脸抓拍",
              effect: {
                title: "产品功能",
                content: [
                  "2MP人脸抓拍摄像机。",
                  "H.265/H.264/MJPEG 三码流编码。",
                  "1/2.8 Sony 星光级CMOS。",
                  "Color: 0.01Lux。",
                  "B/W: 0.001Lux。",
                  "6-22mm MZF镜头。",
                  "最大人脸检测数≤30。",
                  "人脸抓拍大小：人脸像素≥96*96pxl。",
                  "姿态要求：俯仰30°内,左右30°内。",
                  "支持择优输出,去重复功能。",
                  "曝光/增益/色饱和度等可调。",
                  "1路报警输出，2路报警输入。",
                  "TF卡。",
                  "RS485,RJ45,POE,DC12V。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 5,
              src: require("../../assets/imgAll/sexiang/FW6237IHBB55A/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/FW6237IHBB55A/sxt1.png"), //产品详情页图
              introduce: `人脸抓拍半球摄像机(标配6-22mm电动镜头)`, //介绍
              name: "FW6236IHBB55A Hi3516DV300+IMX385人脸抓拍",
              effect: {
                title: "产品功能",
                content: [
                  "2MP人脸抓拍摄像机。",
                  "H.265/H.264/MJPEG 三码流编码。",
                  "1/2 Sony 星光级CMOS。",
                  "Color: 0.01Lux。",
                  "B/W: 0.001Lux。",
                  "6-22mm MZF镜头。",
                  "最大人脸检测数≤30。",
                  "人脸抓拍大小：人脸像素≥96*96pxl。",
                  "姿态要求：俯仰30°内,左右30°内。",
                  "支持择优输出,去重复功能。",
                  "曝光/增益/色饱和度等可调。",
                  "1路报警输出，2路报警输入。",
                  "TF卡。",
                  "RS485,RJ45,POE,DC12V。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
          ],
        },
        {
          id: 2,
          title: "全景系列",
          contentList: [
            {
              id: 1,
              src: require("../../assets/imgAll/sexiang/W9131IHBB79/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/W9131IHBB79/sxt1.png"), //产品详情页图
              introduce: `全景鱼眼机`, //介绍
              name: "W9131IHBB79 Hi3519V101+IMX226",
              effect: {
                title: "产品功能",
                content: [
                  "H.265。",
                  "4K 分辨率。",
                  "1/1.7 星光级 1200MP 星光级 CMOS。",
                  "360° 视角, 0Lux(红外开)",
                  "180° 鱼眼 ICR 镜头",
                  "4颗阵列红外灯",
                  "10-15M 红外距离。",
                  "数字宽动态。",
                  "3D降噪。",
                  "RS485。",
                  "TF卡。",
                  "重置键。",
                  "双向语音对讲。",
                  "2路报警输入, 1路报警输出。",
                  "支持三码流。",
                  "PoE, AC24V/DC12V。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 2,
              src: require("../../assets/imgAll/sexiang/4kand8k/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/4kand8k/sxt1.png"), //产品详情页图
              introduce: `全景拼接摄像机`, //介绍
              name: "4K全景拼接摄像机",
              effect: {
                title: "产品功能",
                content: [
                  "多目拼接180度全景网络摄苽机。",
                  "4颗1/2 SONY 2MP像素星光级CMOS。",
                  "最高分辨率可达3840×2160",
                  "支持低码率、低延时",
                  "ROI感兴趣区域增强编码",
                  "H.265高效压缩。",
                  "节省带宽和存储空间。",
                  "180°全景拼接。",
                  "增加广阔环境下监控区域。",
                  "支持Micro SD卡(128G)本地存储。",
                  "支持双向语音对讲。",
                  "支持2路报警输入/1路报警输出。",
                  "IP66防护。",
                  "PoE，DC12V。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 3,
              src: require("../../assets/imgAll/sexiang/4kand8k/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/4kand8k/sxt1.png"), //产品详情页图
              introduce: `全景拼接摄像机`, //介绍
              name: "8K全景拼接摄像机",
              effect: {
                title: "产品功能",
                content: [
                  "多目拼接180度全景网络摄苽机。",
                  "4颗1/8 SONY 8MP像素星光级CMOS。",
                  "最高分辨率可达7680×4320",
                  "支持低码率、低延时",
                  "ROI感兴趣区域增强编码",
                  "H.265高效压缩。",
                  "节省带宽和存储空间。",
                  "180°全景拼接。",
                  "增加广阔环境下监控区域。",
                  "支持Micro SD卡(128G)本地存储。",
                  "支持双向语音对讲。",
                  "支持2路报警输入/1路报警输出。",
                  "IP66防护。",
                  "PoE，DC12V。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 4,
              src: require("../../assets/imgAll/sexiang/W6134IHBB85M/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/W6134IHBB85M/sxt1.png"), //产品详情页图
              introduce: `半球摄像机`, //介绍
              name: "W6134IHBB85M 3516EV200+IMX307",
              effect: {
                title: "产品功能",
                content: [
                  "H.265。",
                  "1080P 分辨率。",
                  "1/2.8 星光级 CMOS",
                  "ICR",
                  "0Lux(红外开)",
                  "3.6mm 镜头。",
                  "12颗 红外灯。",
                  "15-20M 红外距离。",
                  "数字宽动态。",
                  "3D降噪。",
                  "支持双码流。",
                  "IP65。",
                  "DC12V。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 5,
              src: require("../../assets/imgAll/sexiang/W6134IHBB85M/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/W6134IHBB85M/sxt1.png"), //产品详情页图
              introduce: `半球摄像机`, //介绍
              name: "W3131IHBB85M 3516EV300+IMX335",
              effect: {
                title: "产品功能",
                content: [
                  "H.265。",
                  "4MP@25fps分辨率。",
                  "1/2.8 索尼CMOS",
                  "ICR",
                  "0Lux(红外开)",
                  "3.6mm 镜头。",
                  "12颗 红外灯。",
                  "15-20M 红外距离。",
                  "数字宽动态。",
                  "3D降噪。",
                  "支持三码流。",
                  "智能功能(目标计数，区域检测，物品检测，越界检测)。",
                  "IP65。",
                  "DC12V。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 6,
              src: require("../../assets/imgAll/sexiang/W6136IHBB55A/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/W6136IHBB55A/sxt1.png"), //产品详情页图
              introduce: `半球摄像机`, //介绍
              name: "W6136IHBB55A 3516EV300+IMX307",
              effect: {
                title: "产品功能",
                content: [
                  "H.265。",
                  "2MP@25fps分辨率。",
                  "1/2.8 索尼CMOS",
                  "ICR",
                  "0Lux(红外开)",
                  "2.8-12mm 电动镜头。",
                  "12颗 红外灯。",
                  "15-20M 红外距离。",
                  "数字宽动态。",
                  "3D降噪。",
                  "支持三码流。",
                  "智能功能(目标计数，区域检测，物品检测，越界检测)。",
                  "TF卡槽。",
                  "复位键。",
                  "报警2入1出，音频1入1出。",
                  "IP66/IK10。",
                  "POE。",
                  "DC12V。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 7,
              src: require("../../assets/imgAll/sexiang/W6136IHBB55A/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/W6136IHBB55A/sxt1.png"), //产品详情页图
              introduce: `半球摄像机`, //介绍
              name: "W3131IHBB55A 3516EV300+IMX335",
              effect: {
                title: "产品功能",
                content: [
                  "H.265。",
                  "4MP@25fps分辨率。",
                  "1/2.8 索尼CMOS",
                  "ICR",
                  "0Lux(红外开)",
                  "2.8-12mm 电动镜头。",
                  "12颗 红外灯。",
                  "15-20M 红外距离。",
                  "数字宽动态。",
                  "3D降噪。",
                  "支持三码流。",
                  "智能功能(目标计数，区域检测，物品检测，越界检测)。",
                  "TF卡槽。",
                  "复位键。",
                  "报警2入1出，音频1入1出。",
                  "IP66/IK10。",
                  "POE。",
                  "DC12V。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 8,
              src: require("../../assets/imgAll/sexiang/W6136IHBB60/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/W6136IHBB60/sxt1.png"), //产品详情页图
              introduce: `半球摄像机`, //介绍
              name: "W6136IHBB60 3516EV300+IMX307",
              effect: {
                title: "产品功能",
                content: [
                  "H.265。",
                  "1080P 分辨率。",
                  "1/2.8 星光级CMOS",
                  "3.6mm 镜头",
                  "0Lux(红外开)",
                  "2.8-12mm 电动镜头。",
                  "6颗 红外灯。",
                  "5-10M 红外距离。",
                  "数字宽动态。",
                  "3D降噪。",
                  "支持三码流。",
                  "智能功能(目标计数，区域检测，物品检测，越界检测)。",
                  "TF卡槽。",
                  "防暴。",
                  "IP66。",
                  "POE。",
                  "DC12V。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 9,
              src: require("../../assets/imgAll/sexiang/W6134IHZ23/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/W6134IHZ23/sxt1.png"), //产品详情页图
              introduce: `红外柱形机`, //介绍
              name: "W6134IHZ23 3516EV200+IMX307",
              effect: {
                title: "产品功能",
                content: [
                  "H.265。",
                  "1080P 分辨率。",
                  "1/2.8 星光级CMOS",
                  "ICR",
                  "3.6mm 镜头",
                  "0Lux(红外开)",
                  "2.8-12mm 电动镜头。",
                  "6颗 红外灯。",
                  "5-10M 红外距离。",
                  "数字宽动态。",
                  "3D降噪。",
                  "支持双码流。",
                  "IP66。",
                  "DC12V（POE可选）。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 10,
              src: require("../../assets/imgAll/sexiang/W6134IHZ23/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/W6134IHZ23/sxt1.png"), //产品详情页图
              introduce: `红外柱形机`, //介绍
              name: "W3131IHZ23 3516EV300+IMX335",
              effect: {
                title: "产品功能",
                content: [
                  "H.265。",
                  "4MP@25fps分辨率。",
                  "1/2.8 索尼CMOS",
                  "ICR",
                  "3.6mm 镜头",
                  "0Lux(红外开)",
                  "2.8-12mm 电动镜头。",
                  "16颗 红外灯。",
                  "5-10M 红外距离。",
                  "数字宽动态。",
                  "3D降噪。",
                  "支持三码流。",
                  "智能功能(目标计数，区域检测，物品检测，越界检测)",
                  "IP66。",
                  "DC12V（POE可选）。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 11,
              src: require("../../assets/imgAll/sexiang/W6632IHZ79F/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/W6632IHZ79F/sxt1.png"), //产品详情页图
              introduce: `红外柱形机`, //介绍
              name: "W6632IHZ79F 3516EV300+IMX327",
              effect: {
                title: "产品功能",
                content: [
                  "H.265。",
                  "2MP@25fps分辨率。",
                  "1/2.8 索尼CMOS",
                  "ICR",
                  "00Lux(红外开)",
                  "2.8-12mm 电动镜头。",
                  "3颗IR III 红外灯。",
                  "40-50M 红外距离。",
                  "数字宽动态。",
                  "3D降噪。",
                  "支持双码流。",
                  "智能功能(目标计数，区域检测，物品检测，越界检测)",
                  "TF卡槽",
                  "复位键",
                  "报警2入1出,音频1入1出",
                  "IP66。",
                  "POE, DC12V。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 12,
              src: require("../../assets/imgAll/sexiang/W6632IHZ79F/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/W6632IHZ79F/sxt1.png"), //产品详情页图
              introduce: `红外柱形机`, //介绍
              name: "W3131IHZ79F 3516EV300+IMX335",
              effect: {
                title: "产品功能",
                content: [
                  "H.265。",
                  "4MP@25fps分辨率。",
                  "1/2.8 索尼CMOS",
                  "ICR",
                  "00Lux(红外开)",
                  "2.8-12mm 电动镜头。",
                  "3颗IR III 红外灯。",
                  "40-50M 红外距离。",
                  "数字宽动态。",
                  "3D降噪。",
                  "支持双码流。",
                  "智能功能(目标计数，区域检测，物品检测，越界检测)",
                  "TF卡槽",
                  "复位键",
                  "报警2入1出,音频1入1出",
                  "IP66。",
                  "POE, DC12V。",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 13,
              src: require("../../assets/imgAll/sexiang/yuntai/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/yuntai/sxt1.png"), //产品详情页图
              introduce: `制高点超长视距云台摄像机`, //介绍
              name: "云台摄像机",
              effect: {
                title: "产品功能",
                content: [
                  "60倍光学变焦云台摄像机。",
                  "1/2 索尼CMOS。",
                  "f=10mm-600mm",
                  "云台转动范围：水平365度，垂直-10度至60度",
                  "支持255个预置位",
                  "4组巡航扫描，8组花样扫描。",
                  "支持宽动态。",
                  "ROI。",
                  "透雾。",
                  "3D降噪。",
                  "IP66防护等级。",
                  "适用于森林防火、港口码头、边海防等",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 14,
              src: require("../../assets/imgAll/sexiang/WD262-6105/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/WD262-6105/sxt1.png"), //产品详情页图
              introduce: `高速球`, //介绍
              name: "WD262-6105 安霸A5S66+IMX222",
              effect: {
                title: "产品功能",
                content: [
                  "迷你高清网络高速球。",
                  "6颗 红外灯。",
                  "80M 红外距离",
                  "H.264",
                  "内置1080P安霸机芯",
                  "10X 光学变焦。",
                  "最小速度: 0.1°/分钟,最大速度 240°/分钟水平和垂直快速旋转。",
                  "支持TF卡。",
                  "3D降噪。",
                  "数字宽动态。",
                  "双向语音对讲",
                  "2路报警输入, 1路报警输出",
                  "IP66防水",
                  "POE, DC24V",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 15,
              src: require("../../assets/imgAll/sexiang/WD262-6102/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/WD262-6102/sxt1.png"), //产品详情页图
              introduce: `高速球`, //介绍
              name: "WD262-6102 3516DV300+IMX327",
              effect: {
                title: "产品功能",
                content: [
                  "迷你高清网络高速球。",
                  "6颗 红外灯。",
                  "80M 红外距离",
                  "H.264",
                  "内置2MP海思智能机芯",
                  "10X 光学变焦。",
                  "最小速度: 0.1°/分钟,最大速度 240°/分钟水平和垂直快速旋转。",
                  "支持TF卡。",
                  "3D降噪。",
                  "真实宽动态。",
                  "2路报警输入, 1路报警输出",
                  "支持人脸识别",
                  "可内置1W人脸库",
                  "人车检测智能功能 (目标计数，离岗检测，逆行检测，电子围栏)",
                  "IP66",
                  "POE, DC24V",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 16,
              src: require("../../assets/imgAll/sexiang/WD232C-6233/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/WD232C-6233/sxt1.png"), //产品详情页图
              introduce: `高速球`, //介绍
              name: "WD232C-6233 S2L66+IMX290+HI3516D",
              effect: {
                title: "产品功能",
                content: [
                  "H.265。",
                  "SMART 星光级 WDR 红外 网络高速球。",
                  "内置2MP 安霸机芯",
                  "23X 光学变焦",
                  "4.7mm~108.1mm",
                  "彩色: 0.002lux@F1.6;黑白: 0.0002Lux@F1.6。",
                  "0 Lux(红外开)。",
                  "最小速度: 0.1°/分钟,最大速度 200°/分钟水平和垂直快速旋转。",
                  "8颗钨丝 红外灯智能分组控制。",
                  "180M 红外距离。",
                  "支持 128G TF卡",
                  "3D降噪",
                  "真实宽动态",
                  "智能功能 (目标计数，区域检测，物品检测，越界检测，智能移动侦测)",
                  "IP66防水",
                  "POE, DC24V",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 17,
              src: require("../../assets/imgAll/sexiang/WD232C-620B/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/WD232C-620B/sxt1.png"), //产品详情页图
              introduce: `高速球`, //介绍
              name: "WD232C-620B 3516DV300+IMX327",
              effect: {
                title: "产品功能",
                content: [
                  "H.265。",
                  "SMART 星光级 WDR 红外 网络高速球。",
                  "内置2MP海思智能机芯",
                  "20X 光学变焦",
                  "4.7mm~94mm",
                  "彩色: 0.01lux@F1.6;黑白: 0.001Lux@F1.6。",
                  "0 Lux(红外开)。",
                  "最小速度: 0.1°/分钟,最大速度 200°/分钟水平和垂直快速旋转。",
                  "8颗钨丝 红外灯智能分组控制。",
                  "120M 红外距离。",
                  "支持 128G TF卡",
                  "3D降噪",
                  "真实宽动态",
                  "支持人脸识别，可内置1W人脸库",
                  "人车检测智能功能 (目标计数，离岗检测，逆行检测，电子围栏)",
                  "IP66防水",
                  "POE, DC24V",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 18,
              src: require("../../assets/imgAll/sexiang/WD232C-630B/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/WD232C-630B/sxt1.png"), //产品详情页图
              introduce: `高速球`, //介绍
              name: "WD232C-630B 3516DV300+IMX327",
              effect: {
                title: "产品功能",
                content: [
                  "H.265。",
                  "SMART 星光级 WDR 红外 网络高速球。",
                  "内置2MP海思智能机芯",
                  "30X 光学变焦",
                  "4.5mm~135mm",
                  "彩色: 0.01lux@F1.6;黑白: 0.001Lux@F1.6。",
                  "0 Lux(红外开)。",
                  "最小速度: 0.1°/分钟,最大速度 200°/分钟水平和垂直快速旋转。",
                  "8颗钨丝 红外灯智能分组控制。",
                  "120M 红外距离。",
                  "支持 128G TF卡",
                  "3D降噪",
                  "真实宽动态",
                  "支持人脸识别，可内置1W人脸库",
                  "人车检测智能功能 (目标计数，离岗检测，逆行检测，电子围栏)",
                  "IP66防水",
                  "POE, DC24V",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 19,
              src: require("../../assets/imgAll/sexiang/WD212C-620B/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/WD212C-620B/sxt1.png"), //产品详情页图
              introduce: `高速球`, //介绍
              name: "WD212C-620B 3516DV300+IMX327",
              effect: {
                title: "产品功能",
                content: [
                  "H.265。",
                  "SMART 星光级 WDR 红外 网络高速球。",
                  "内置2MP海思智能机芯",
                  "20X 光学变焦",
                  "4.7mm~94mm",
                  "彩色: 0.01lux@F1.6;黑白: 0.001Lux@F1.6。",
                  "0 Lux(红外开)。",
                  "最小速度: 0.1°/分钟,最大速度 200°/分钟水平和垂直快速旋转。",
                  "14颗钨丝 红外灯智能分组控制。",
                  "150M 红外距离。",
                  "支持 128G TF卡",
                  "3D降噪",
                  "真实宽动态",
                  "支持人脸识别，可内置1W人脸库",
                  "人车检测智能功能 (目标计数，离岗检测，逆行检测，电子围栏)",
                  "IP66防水",
                  "POE, DC12V",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 20,
              src: require("../../assets/imgAll/sexiang/WD212C-630B/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/WD212C-630B/sxt1.png"), //产品详情页图
              introduce: `高速球`, //介绍
              name: "WD212C-630B 3516DV300+IMX327",
              effect: {
                title: "产品功能",
                content: [
                  "H.265。",
                  "SMART 星光级 WDR 红外 网络高速球。",
                  "内置2MP海思智能机芯",
                  "30X 光学变焦",
                  "4.5mm~135mm",
                  "彩色: 0.01lux@F1.6;黑白: 0.001Lux@F1.6。",
                  "0 Lux(红外开)。",
                  "最小速度: 0.1°/分钟,最大速度 200°/分钟水平和垂直快速旋转。",
                  "14颗钨丝 红外灯智能分组控制。",
                  "150M 红外距离。",
                  "支持 128G TF卡",
                  "3D降噪",
                  "真实宽动态",
                  "支持人脸识别，可内置1W人脸库",
                  "人车检测智能功能 (目标计数，离岗检测，逆行检测，电子围栏)",
                  "IP66防水",
                  "POE, DC12V",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 21,
              src: require("../../assets/imgAll/sexiang/WD212C-3351/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/WD212C-3351/sxt1.png"), //产品详情页图
              introduce: `高速球`, //介绍
              name: "WD212C-3351 3516DV300+IMX347",
              effect: {
                title: "产品功能",
                content: [
                  "H.265。",
                  "SMART 星光级 WDR 红外 网络高速球。",
                  "内置4MP海思智能机芯",
                  "35X 光学变焦",
                  "6.0mm~210mm",
                  "彩色: 0.01lux@F1.6;黑白: 0.001Lux@F1.6。",
                  "0 Lux(红外开)。",
                  "最小速度: 0.1°/分钟,最大速度 200°/分钟水平和垂直快速旋转。",
                  "14颗红外灯智能分组控制。",
                  "150M 红外距离。",
                  "支持 128G TF卡",
                  "3D降噪",
                  "真实宽动态",
                  "支持人脸识别，可内置1W人脸库",
                  "人车检测智能功能 (目标计数，离岗检测，逆行检测，电子围栏)",
                  "IP66防水",
                  "POE, DC12V",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 22,
              src: require("../../assets/imgAll/sexiang/WD272-9311/sxt1.png"), //产品图
              srcDetails: require("../../assets/imgAll/sexiang/WD272-9311/sxt1.png"), //产品详情页图
              introduce: `高速球`, //介绍
              name: "WD272-9311 3519A+IMX334",
              effect: {
                title: "产品功能",
                content: [
                  "H.265。",
                  "SMART 星光级 WDR 红外 网络高速球。",
                  "内置8MP海思智能机芯",
                  "31X 光学变焦",
                  "4.0mm~124mm",
                  "彩色: 0.01lux@F1.6;黑白: 0.005Lux@F1.6。",
                  "0 Lux(红外开)。",
                  "最小速度: 0.1°/分钟,最大速度 200°/分钟水平和垂直快速旋转。",
                  "14颗红外灯智能分组控制。",
                  "150M 红外距离。",
                  "支持 128G TF卡",
                  "3D降噪",
                  "真实宽动态",
                  "支持人脸识别，可内置1W人脸库",
                  "人车检测智能功能 (目标计数，离岗检测，逆行检测，电子围栏)",
                  "IP66防水",
                  "POE, DC12V",
                ], //功能
              },
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
          ],
        },
      ],

      titleIndex: 0, //选择系列得下标;同时作为显示内容的下标
    };
  },
  methods: {
    toDetails(obj) {
      this.$router.push({
        path: "productdetailsppage",
        query: {
          list: JSON.stringify(obj),
        },
      });
    },
  getDivAddr(){
      // 获取要添加动画的dom元素（也可用ref）
      let dom = document.getElementsByClassName('mateDom')
      // 获取页面高度；后期与bottom做对比
      let height = document.documentElement.clientHeight
      // 给每一个添加动画的dom元素添加事件
      for (const iterator of dom) {
        // 获取dom距离可视区域的高度
        let list = iterator.getBoundingClientRect()
        // 利用top bottom距离可视区域顶部的距离判断元素是否在可视区域中
        if(list.bottom>0 && list.top<height-100){
          // 将预先隐藏的元素显示
          // 注意：用visibility而不是用disablay
          // 添加动画类名
        iterator.style.visibility='visible'
        iterator.classList.add('animate__animated', 'animate__fadeInUp');
      }
      }
    },
    // 监听滚动条事件
    windowScroll() {
      this.getDivAddr()
    },
  },
  mounted(){
    this.getDivAddr()
    // 监听滚动条
    window.addEventListener('scroll', this.windowScroll)
  }
};
</script>
<style lang="less" scoped>
.jiejue {
  width: 100%;
  ul {
    li {
      width: 33.333%;
      padding: 1%;
      height: 100%;
      float: left;
      .jiejueimg {
        width: 100%;
        height: 290px;
        line-height: 290px;
        background-color: rgb(255, 255, 255);
        text-align: center;
        vertical-align: middle;
        overflow: hidden;
        .chanpintu {
          width: 180px;
          height: 200px;
          // overflow: hidden;
          // transition: ease 0.5s;
          margin-top: 40px;
        }
      }
      .neirong {
        background-color: rgb(255, 255, 255);
        border-top: 1px solid #e5e5e5;
        height: 120px;
        text-align: center;
        padding: 28px 20px;
        transition: ease 0.5s;
        .wenzi {
          color: rgb(14, 14, 14);
          font-size: 22px;
          font-weight: 400;
          margin-bottom: 12px;
          transition: ease 0.5s;
        }
        .ttitle {
          font-size: 16px;
          line-height: 30px;
          color: #666;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    li:hover .wenzi {
      color: #0e57a2;
    }
    li:hover .neirong {
      border-top: 1px solid #0e57a2;
      transform: scale(x, y);
    }
    li:hover .chanpintu {
      transform: scale(1.2);
    }
  }
}
.title {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  p {
    margin: 0 20px;
  }
  .titleActive {
    color: #2468c3;
    font-weight: bold;
  }
  .mateDom{
    visibility: hidden;
  }
}
</style>
