<template>
  <div class="ProductCenter">
    <el-image :src="src" lazy class="tupian" fit="cover"></el-image>

    <div class="jiejuebiaoti">
      <el-header height="60px">
        <div class="wenzi">
          <ul>
            <li v-for="item in chanpinlist" :key="item.id" @click="dianji(item.id)">
              <div class="lianjie" v-if="item.isActive"> {{item.name}}</div>
              <div class="lianjiehou" v-else> {{item.name}}</div>
            </li>
          </ul>
        </div>
      </el-header>
    </div>
    <el-main>

      <keep-alive>
        <component :is="comName"></component>
      </keep-alive>

    </el-main>
    
  </div>
</template>
<script>
import Dukaqi from './ProductCenter-fenlei/dukaqi.vue'
import Caijiqi from './ProductCenter-fenlei/caijiqi.vue'
import Fangke from './ProductCenter-fenlei/Fangke.vue'
import Shexiang from './ProductCenter-fenlei/shexiang.vue'
import Renzheng from './ProductCenter-fenlei/renzheng.vue'
export default {
  name: 'ProductCenter',
  components: {
    Dukaqi,
    Caijiqi,
    Fangke,
    Shexiang,
    Renzheng
  },
  data() {
    return {
      comName: 'Dukaqi',
      src: require('../assets/chanpinzhongxin.jpg'),
      chanpinlist: [
        { id: 1, isActive: false, name: 'AI人脸门禁系列', dongtai: 'Dukaqi' },
        { id: 2, isActive: true, name: '人证机系列', dongtai: 'Renzheng' },
        { id: 3, isActive: true, name: 'AI摄像头系列', dongtai: 'Caijiqi' },
        { id: 4, isActive: true, name: '边缘计算盒', dongtai: 'Fangke' },
        // { id: 5, isActive: true, name: '智能网络摄像机', dongtai: 'Shexiang' }
      ]
    }
  },
  methods: {
    dianji(id) {
      this.chanpinlist.filter(item => {
        if (item.id == id) {
          item.isActive = false
          this.comName = item.dongtai
        } else {
          item.isActive = true
        }
      })
    }
  },
   beforeRouteUpdate(to,from,next){
    //可以获取 this
    // /a/123 /a/456  当 组件被复用时，触发此方法
    // console.log('beforeRouteUpdate',to,from)
    next()    
    // console.log(this.$route.query.id);
    this.dianji(this.$route.query.id)
  },
  
}
</script>

<style lang="less" scoped>
.lianjiehou {
  display: block;
  font-size: 18px;
  font-weight: 500;
  padding: 0 30px;
  color: #ffffff;
  background-color: #0e57a2;
}

.ProductCenter {
  // overflow:scroll;
  margin-top: 82px;
  //margin-bottom: 40px;
  background-color: #f7f7f7;
  .tupian {
    width: 100%;
    height: 368px;
  }
  .jiejuebiaoti {
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 60px;
    text-align: center;
    margin: -2px auto;
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 34px;
    .wenzi {
      float: left;
      ul {
        li {
          float: left;
          height: 60px;
          line-height: 60px;
          .lianjie {
            display: block;
            height: 100%;
            text-decoration: none;
            color: black;
            font-size: 18px;
            font-weight: 500;
            padding: 0 30px;
          }
        }
        li:hover .lianjie {
          color: #0e57a2;
        }
      }
    }
  }
}
</style>
