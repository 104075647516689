<template>
  <div class="shexiang">
    <div class="jiejue">
      <ul>
        <li v-for="item in chanpinneironglist" :key="item.id">
          <div class="jiejueimg">
            <el-image :src="item.src" alt="" lazy class="chanpintu"></el-image>
          </div>
          <div class="neirong">
            <div class="wenzi">{{item.name}}</div>
            <div class="ttitle">{{item.chakan}}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Shexiang',
  components: {},
  data() {
    return {
      chanpinneironglist: [
        {
          id: 1,
          src: require('../../assets/Intelligent-network-camera/1 (1).png'),
          name: '混合智能枪型摄像机',
          chakan: '适用功能和场景:人脸抓拍/门禁通行/视频监控'
        },
        {
          id: 2,
          src: require('../../assets/Intelligent-network-camera/1 (2).png'),
          name: '敏观人脸识别枪机',
          chakan: '适用功能和场景:人脸抓拍/门禁通行/视频监控'
        },
        {
          id: 3,
          src: require('../../assets/Intelligent-network-camera/1 (3).png'),
          name: '旷鹰人脸抓拍定焦海螺',
          chakan: '适用功能和场景:人脸抓拍/门禁通行/视频监控'
        },
        {
          id: 4,
          src: require('../../assets/Intelligent-network-camera/1 (4).png'),
          name: '旷鹰人脸抓拍定焦/变焦筒机',
          chakan: '适用功能和场景:人脸抓拍/门禁通行/视频监控'
        },
        {
          id: 5,
          src: require('../../assets/Intelligent-network-camera/1 (5).png'),
          name: '敏观人脸识别变焦筒机',
          chakan: '适用功能和场景:人脸抓拍/门禁通行/视频监控'
        },
        {
          id: 6,
          src: require('../../assets/Intelligent-network-camera/1 (6).png'),
          name: '敏观人脸识别变焦半球',
          chakan: '适用功能和场景:人脸抓拍/门禁通行/视频监控'
        },
        {
          id: 7,
          src: require('../../assets/Intelligent-network-camera/1 (7).png'),
          name: '结构化摄像机',
          chakan: '适用功能和场景:人脸抓拍/门禁通行/视频监控'
        },
        {
          id: 8,
          src: require('../../assets/Intelligent-network-camera/1 (8).png'),
          name: '混合智能一体化筒机',
          chakan: '适用功能和场景:人脸抓拍/门禁通行/视频监控'
        },
        {
          id: 9,
          src: require('../../assets/Intelligent-network-camera/1 (9).png'),
          name: '敏观人脸抓拍定焦海螺',
          chakan: '适用功能和场景:人脸抓拍/门禁通行/视频监控'
        },
        {
          id: 10,
          src: require('../../assets/Intelligent-network-camera/1 (10).png'),
          name: '敏观人脸抓拍定焦筒机',
          chakan: '适用功能和场景:人脸抓拍/门禁通行/视频监控'
        }
      ]
    }
  }
}
</script>
<style lang="less" scoped>
.jiejue {
  width: 100%;
  ul {
    li {
      width: 33.333%;
      padding: 1%;
      height: 100%;
      float: left;
      .jiejueimg {
        width: 100%;
        height: 290px;
        line-height: 290px;
        background-color: rgb(255, 255, 255);
        text-align: center;
        vertical-align: middle;
        overflow: hidden;
        .chanpintu {
          width: 100%;
          height: 100%;
          overflow: hidden;
          transition: ease 0.5s;
        }
      }
      .neirong {
        background-color: rgb(255, 255, 255);
        border-top: 1px solid #e5e5e5;
        height: 120px;
        text-align: center;
        padding: 28px 20px;
        transition: ease 0.5s;
        .wenzi {
          color: rgb(14, 14, 14);
          font-size: 22px;
          font-weight: 400;
          margin-bottom: 12px;
          transition: ease 0.5s;
        }
        .ttitle {
          font-size: 16px;
          line-height: 30px;
          color: #666;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    li:hover .wenzi {
      color: #0e57a2;
    }
    li:hover .neirong {
      border-top: 1px solid #0e57a2;
      transform: scale(x, y);
    }
    li:hover .chanpintu {
      transform: scale(1.2);
    }
  }
}
</style>
