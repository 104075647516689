<template>
  <div class="Information-Technology-services">
    <div class="jiejuebiaoti mateDom">
      <span class="zhuangshi"></span>
      软件服务
      <span class="zhuangshi"></span>
    </div>
    <div class="jiejue">
      <ul class="clearfix">
        <li v-for="item in chanpinneironglist" :key="item.id" class="mateDom">
          <div class="jiejueimg">
            <el-image :src="item.src" alt="" lazy class="chanpintu" fit="scale-down"></el-image>
          </div>
          <div class="neirong">
            <div class="wenzi">{{item.name}}</div>
            <div class="ttitle">{{item.chakan}}</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="jiejuebiaoti mokuai mateDom">
      <span class="zhuangshi"></span>
      信创特色
      <span class="zhuangshi"></span>
    </div>
     <div class="heixin">
      <ul class="clearfix">
        <li v-for="item in heixinlist" :key="item.id" class="mateDom">
          <div class="jiejueimg">
            <el-image :src="item.src" alt="" lazy class="chanpintu" fit="scale-down"></el-image>
          </div>
          <div class="neirong">
            <div class="wenzi">{{item.name}}</div>
            <div class="ttitle">{{item.chakan}}</div>
          </div>
        </li>
      </ul>
    </div>
    <div class="jiejuebiaoti mokuai mateDom">
      <span class="zhuangshi"></span>
      我们的服务
      <span class="zhuangshi"></span>
    </div>
    <div class="yingyong">
        <ul class="clearfix">
          <li v-for="item in yingyonglist" :key="item.id" class="mateDom">
            <div class="ruanjian">
              <div class="jiejueimg">
                <el-image :src="item.src" alt="" lazy class="chanpintu" fit="fill"></el-image>
              </div>
              <div class="neirong">
                <div class="wenzi">{{item.name}}</div>
                <div class="ttitle">{{item.chakan}}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
  </div>
</template>

<script>
export default {
  name: 'Information-Technology-services',
  components: {},
  data() {
    return {
      chanpinneironglist: [
        {
          id: 1,
          src: require('../../assets/xinxijishufuwu/1rug.jpg'),
          name: '需求信息发布',
          chakan: '对招聘需求进行发布，进行人员集结、多维面试、定级入职。招聘团队对人员进行多维把控，进行面试，综合评估，形成更加全面的人员信息'
        },
        {
          id: 2,
          src: require('../../assets/xinxijishufuwu/f2zv.jpg'),
          name: '人员需求获取与分析',
          chakan: '获取相应的人员需求，构建相应招聘团队，对客户需求进行确认，并对人员需求进行人员分析，对需求发布。'
        },
        {
          id: 3,
          src: require('../../assets/xinxijishufuwu/a4gs.jpg'),
          name: '维稳、回撤',
          chakan: '对人员进行人文关怀，了解人员需求，确保人员心态，并对人员技能进行相应培训，加强相应的知识储备。项目完结，进行资源评估，有序的进行人力资源的回撤。'
        }
      ],
      heixinlist: [
        {
          id: 1,
          src: require('../../assets/xinxijishufuwu/763x.png'),
          name: '面向需求体系',
          chakan: '对人员需求进行深度解析​最优成本、最大价值​智能化服务'
        },
        {
          id: 2,
          src: require('../../assets/xinxijishufuwu/w76x.png'),
          name: '优良的服务',
          chakan: '专业的人员对接​优质的心理辅导、人文​关怀加强技能培训'
        },
        {
          id: 3,
          src: require('../../assets/xinxijishufuwu/lyt7.png'),
          name: '高效率搜索',
          chakan: '多家招聘网站资源进行​人员搜索多HR进行人员对接招聘'
        }
      ],
      yingyonglist: [
        {
          id: 1,
          src: require('../../assets/xinxijishufuwu/mkkx.jpg'),
          name: '专业团队服务',
          chakan: '团队拥有多年的项目经验和最佳实践，为客户提供面向企业内部的专业IT支持服务。最优服务、最优成本、最大价值'
        },
        {
          id: 2,
          src: require('../../assets/xinxijishufuwu/v83d.png'),
          name: '我们的服务',
          chakan: '我们的业务包括IT咨询、需求开发、概要设计、详细设计、编码、单元测试、集成测试、系统测试、软件运维等软件全生命周期作业或部分作业。我们的信息技术服务系列分为ITO和BPO两个子产品/服务'
        },
        {
          id: 3,
          src: require('../../assets/xinxijishufuwu/685u.jpg'),
          name: '共享服务',
          chakan: '将方法论、人、流程和技术完美结合，助力您构建一个组织与个体互联互通、有机协，有效提高了工作效率'
        }
      ]
    }
  },
  methods:{
   getDivAddr(){
      // 获取要添加动画的dom元素（也可用ref）
      let dom = document.getElementsByClassName('mateDom')
      // 获取页面高度；后期与bottom做对比
      let height = document.documentElement.clientHeight
      // 给每一个添加动画的dom元素添加事件
      for (const iterator of dom) {
        // 获取dom距离可视区域的高度
        let list = iterator.getBoundingClientRect()
        // 利用top bottom距离可视区域顶部的距离判断元素是否在可视区域中
        if(list.bottom>0 && list.top<height-100){
          // 将预先隐藏的元素显示
          // 注意：用visibility而不是用disablay
          // 添加动画类名
        iterator.style.visibility='visible'
        iterator.classList.add('animate__animated', 'animate__fadeInUp');
      }
      }
    },
    // 监听滚动条事件
    windowScroll() {
      this.getDivAddr()
    },
  },
  mounted(){
    this.getDivAddr()
    // 监听滚动条
    window.addEventListener('scroll', this.windowScroll)
  }
}
</script>
<style lang="less" scoped>
.mateDom{
    visibility: hidden;
  }
.yanshitu {
  text-align: center;
}
.mokuai {
  padding-top: 50px;
}
/* 清除浮动 */
.clearfix:after {
  visibility: hidden;
  clear: both;
  display: block;
  content: '.';
  height: 0;
}

.clearfix {
  *zoom: 1;
}

.jiejuebiaoti {
  width: 100%;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin: 0 auto;
  color: #2f2f2f;
  //margin-top: 10px;
  margin-bottom: 25px;
  .zhuangshi {
    width: 140px;
    height: 1px;
    display: inline-block;
    background-color: rgb(204, 204, 204);
    //float: left;
    margin: 0 30px;
    margin-bottom: 8.5px;
  }
}

.jiejue {
  width: 100%;
  ul {
    li {
      width: 33.333%;
      padding: 1%;
      height: 100%;
      float: left;
      .jiejueimg {
        width: 100%;
        height: 290px;
        line-height: 290px;
        background-color: rgb(255, 255, 255);
        text-align: center;
        overflow: hidden;
        .chanpintu {
          width: 60%;
          height: 60%;
          vertical-align: middle;
          overflow: hidden;
          transition: ease 0.5s;
        }
      }
      .neirong {
        background-color: rgb(255, 255, 255);
        border-top: 1px solid #e5e5e5;
        height: 230px;
        text-align: center;
        padding: 28px 18px;
        transition: ease 0.5s;
        .wenzi {
          color: rgb(14, 14, 14);
          font-size: 22px;
          font-weight: 400;
          margin-bottom: 12px;
          transition: ease 0.5s;
        }
        .ttitle {
          font-size: 16px;
          line-height: 30px;
          color: #666;
          text-align: center;
        }
      }
    }
    li:hover .wenzi {
      color: #0e57a2;
    }
    li:hover .neirong {
      border-top: 1px solid #0e57a2;
      transform: scale(x, y);
    }
    li:hover .chanpintu {
      transform: scale(1.2);
    }
  }
}

.heixin {
  width: 100%;
  ul {
    li {
      width: 33.333%;
      padding: 1%;
      height: 100%;
      float: left;
      .jiejueimg {
        width: 100%;
        height: 290px;
        line-height: 290px;
        background-color: rgb(255, 255, 255);
        text-align: center;
        overflow: hidden;
        .chanpintu {
          width: 40%;
          height: 40%;
          vertical-align: middle;
          overflow: hidden;
          transition: ease 0.5s;
        }
      }
      .neirong {
        background-color: rgb(255, 255, 255);
        border-top: 1px solid #e5e5e5;
        height: 140px;
        text-align: center;
        padding: 28px 18px;
        transition: ease 0.5s;
        .wenzi {
          color: rgb(14, 14, 14);
          font-size: 22px;
          font-weight: 400;
          margin-bottom: 12px;
          transition: ease 0.5s;
        }
        .ttitle {
          font-size: 16px;
          line-height: 30px;
          color: #666;
          text-align: center;
        }
      }
    }
    li:hover .wenzi {
      color: #0e57a2;
    }
    li:hover .neirong {
      border-top: 1px solid #0e57a2;
      transform: scale(x, y);
    }
    li:hover .chanpintu {
      transform: scale(1.2);
    }
  }
}

.yingyong {
  width: 100%;
  ul {
    li {
      width: 33.333%;
      padding: 2%;
      //height: 100%;
      float: left;
      .ruanjian {
        box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.35);
        border-radius: 4px;
        background-color: rgb(255, 255, 255);
        overflow: hidden;
        transition: ease 0.5s;
        .jiejueimg {
          width: 100%;
          height: 290px;
          line-height: 290px;
          background-color: rgba(0, 0, 0, 0.7);
          text-align: center;
          vertical-align: middle;
          overflow: hidden;
          .chanpintu {
            width: 100%;
            height: 100%;
            overflow: hidden;
            transition: ease 0.5s;
          }
        }
        .neirong {
          height: 150px;
          text-align: center;
          padding: 20px 16px;
          transition: ease 0.5s;
          .wenzi {
            color: rgb(14, 14, 14);
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 18px;
            transition: ease 0.5s;
          }
          .ttitle {
            font-size: 14px;
            line-height: 30px;
            color: #666;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    li:hover .ruanjian .wenzi {
      color: #0e57a2;
    }
    li:hover .ruanjian {
      transform: scale(1.05);
    }
  }
}
</style>
