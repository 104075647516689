<template>
  <div class="while">
    <el-image
      :src="require('@/assets/imgAll/wisdom/SmartPark/blackImg.jpg')"
      fit="cover"
      class="backImg"
    ></el-image>

    <!-- 内容区域 -->
    <div class="content">
      <p class="type animate__animated animate__backInLeft">
        <i class="el-icon-guide"></i> 智慧园区
      </p>
      <p class="title">行业概述</p>
      <el-card class="box-card">
        <div class="card">
          <div class="cardItem">
            <p class="cardItem_p">
              智慧园区综合管理平台基于生物识别技术，提供人、车、物为一体的智能安防综合管理平台，在一个平台 下即可实现多子系统的统一管理与互联互动，真正做到“一体化”的管理，提高用户的易用性和管理效率。
            </p>
            <p class="cardItem_p">
              系统目前覆盖了门禁、考勤、消费、梯控、访客、停车、巡更、视频、报警、人证、通道、信息屏、面部感知、智能分析及系统管理等多个子系统。平台具备强大的集成能力和用户可视化数据展现分析功能，真正实现大规模全系统的安防智能化管理，广泛应用于企业办公园区、生产厂区等，满足领域内设备综合管理的迫切需求。
            </p>    
          </div>
          <div class="cardItem">
            <!-- <el-image src="../../assets/imgAll/wisdom/SmartCampus/gsImg01.jpg"  ></el-image> -->
            <img
              src="../../assets/imgAll/wisdom/SmartPark/hygsImg01.jpg"
            />
          </div>
          <div class="cardItem">
            <img
              src="../../assets/imgAll/wisdom/SmartPark/hygsImg02.png"
            />
          </div>
        </div>
      </el-card>
      <p class="title">需求分析</p>
      <el-card class="box-card">
        <div class="fanan">
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartPark/xqfx01.png')"
            fit="cover"
            class="faIMg"
          ></el-image>
        </div>
      </el-card>
      <p class="title">解决方案</p>
      <el-card class="box-card">
     
        <div class="fanan">
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartPark/jjfa01.png')"
            fit="cover"
            class="faIMg"
          ></el-image>
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartPark/jjfa02.png')"
            fit="cover"
            class="faIMg"
          ></el-image>
        </div>
      </el-card>
      <p class="title">方案优势</p>
      <el-card class="box-card">
        <ul>
          <li class="card-li">智慧园区解决方案目前覆盖了人事、门禁、考勤、 离线消费/在线消费、梯控、访客、停车、巡更、视频、人证、通道、信息屏、面部感知、协同办公、智能分析及系统管理等多个子系统，是一款智能化安防综合管理平台。</li>
          <li class="card-li">平台模块化设计，自由选择各功能模块，勾选安装，减少服务器资源的占用。后续根据需求添加功能模块，实现无痛升级。</li>
          <li class="card-li">支持APP、小程序、H5等作为移动端载体，多项移动端应用人性化选择。</li>
          <li class="card-li">支持云平台扩展移动互联网服务，自由开通云服务功能，根据企业需要可绑定主-从企业关系。整合硬件设备、管理平台、云服务、移动端应用，充分调动软硬件能力，形成一站式安全解决方案。</li>
        </ul>
      </el-card>
      <p class="title">特色功能</p>
      <el-card class="box-card">
        <div class="fanan">
          <el-image
            :src="require('@/assets/imgAll/wisdom/SmartPark/tsgn01.png')"
            fit="cover"
            class="faIMg"
          ></el-image>
        </div>
      </el-card>
    </div>
    <div class="black">
      <router-link to="/home/thesolution" class="blackLink">
        <i class="el-icon-d-arrow-left"></i> 返回解决方案
      </router-link>
    </div>
  </div>
</template>

<script>
import "animate.css";
export default {
  name: "SmartPark",
  data() {
    return {
      obj: {},
    };
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.while {
  width: 100%;
  height: auto;
  padding-top: 82px;
  .backImg{
    width: 100%;
    height: 370px;
  }
  .type {
    text-align: right;
    font-size: 21px;
    // font-weight: 500;
    font-family: STHupo;
  }
  .title {
    font-size: 18px;
    border-left: 2px solid #78bc27;
    padding-left: 10px;
    margin: 20px 0 30px 0;
  }
  .card {
    display: grid;
    grid-template-columns: 60% 40%;
    .cardItem {
      .cardItem_p {
        text-indent: 2em;
        font-size: 16px;
        color: #666;
        line-height: 32px;
        font-weight: 500;
      }
      .card-item-p {
        line-height: 2;
        font-size: 16px;
      }
    }
  }
  .card-title {
    font-weight: bold;
    font-size: 18px;
    margin: 20px 0;
  }
  .card-text {
    text-indent: 2em;
    font-size: 16px;
    line-height: 2;
  }
  .fanan {
    text-align: center;
  }
  .content {
    width: 100%;
    height: auto;
    padding: 20px 3%;
  }
  .black {
    width: 100%;
    height: 120px;
    line-height: 120px;
    text-align: center;
    background-color: #f7f7f7;
    margin: 20px 0;
    .blackLink {
      color: #333;
      background-color: #e3e3e3;
      text-decoration: none;
      padding: 10px;
      border-radius: 5px;
    }
  }
  .box-card_p {
    line-height: 2;
    font-size: 16px;
    margin-bottom: 20px;
  }
  .card-li{
      // text-indent: 2em;
      font-size: 16px;
      line-height: 2;
      list-style:disc ;
      margin-left: 20px;
    }
}
</style>