<template>
  <div class="SoftwareTechnology">
    <el-image :src="src" lazy class="tupian" fit="cover"></el-image>
    <div class="jiejuebiaoti">
      <el-header height="60px">
        <div class="wenzi">
          <ul>
            <li v-for="item in chanpinlist" :key="item.id" @click="dianji(item.id)">
              <div class="lianjie" v-if="item.isActive"> {{item.name}}</div>
              <div class="lianjiehou" v-else> {{item.name}}</div>
            </li>
          </ul>
        </div>
      </el-header>
    </div>

    <el-main>
     
       <keep-alive>
       
        <component :is="comName"></component>
      </keep-alive>
    </el-main>
  </div>
</template>
<script>
import CoreTechnology from './SoftwareTechnology-leimu/Core-technology.vue'
import WisdomFinancial from './SoftwareTechnology-leimu/Wisdom-financial.vue'
import Information from './SoftwareTechnology-leimu/Information-Technology-services.vue'
export default {
  name: 'SoftwareTechnology',
  components: {
    CoreTechnology,
    WisdomFinancial,
    Information
  },
  data() {
    return {
      comName: 'CoreTechnology',
      src: require('../assets/ruanjianjishu.jpg'),
      chanpinlist: [   
        { id: 1, isActive: false, name: '核心技术', dongtai: 'CoreTechnology' },
        { id: 2, isActive: true, name: '软件服务', dongtai: 'Information' },
        { id: 3, isActive: true, name: '智慧金融', dongtai: 'WisdomFinancial' },
      ]
    }
  },
  methods: {
    dianji(id) {
      this.chanpinlist.filter(item => {
        if (item.id == id) {
          item.isActive = false
          this.comName = item.dongtai
        } else {
          item.isActive = true
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.lianjiehou {
  display: block;
  font-size: 18px;
  font-weight: 500;
  padding: 0 30px;
  color: #ffffff;
  background-color: #0e57a2;
}

.SoftwareTechnology {
  // overflow:scroll;
  margin-top: 82px;
  //margin-bottom: 40px;
  background-color: #f7f7f7;
  .tupian {
    width: 100%;
    height: 368px;
  }
  .jiejuebiaoti {
    background-color: rgb(255, 255, 255);
    width: 100%;
    height: 60px;
    text-align: center;
    margin: -2px auto;
    border-bottom: 1px solid #f2f2f2;
    margin-bottom: 34px;
    .wenzi {
      float: left;
      ul {
        li {
          float: left;
          height: 60px;
          line-height: 60px;
          .lianjie {
            display: block;
            height: 100%;
            text-decoration: none;
            color: black;
            font-size: 18px;
            font-weight: 500;
            padding: 0 30px;
          }
        }
        li:hover .lianjie {
          color: #0e57a2;
        }
      }
    }
  }
}
</style>
