<template>
  <div class="dukaqi">
    <div class="title">
      <p
        @click="titleIndex = index"
        :class="[titleIndex == index ? 'titleActive' : '']"
        v-for="(item, index) in chanpinneironglist"
        :key="index"
      >
        {{ item.title }}
      </p>
    </div>

    <div class="jiejue">
      <ul>
        <li
          v-for="item in chanpinneironglist[titleIndex].contentList"
          :key="item.id"
          @click="toDetails(item)"
          class="mateDom"
        >
          <div class="jiejueimg">
            <el-image :src="item.src" alt="" lazy class="chanpintu" fit="contain"></el-image>
            <!-- <el-image :src="productObj.srcDetails" lazy class="tupian" ></el-image> -->
          </div>
          <div class="neirong">
            <div class="wenzi">{{ item.name }}</div>
            <div class="ttitle">{{ item.chakan }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "renzheng",
  components: {},
  data() {
    return {
      chanpinneironglist: [
        {
          id: 0,
          title: "单屏桌面智能终端",
          contentList: [
            {
              id: 1,
              src: require("../../assets/imgAll/renzhen/TW-S01/rz1.png"), //产品图
              srcDetails: require("../../assets/imgAll/renzhen/TW-S01/rz1.png"), //产品详情页图
              introduce: `FC01桌面式人证核验机是一款采用高性能处理器和高精度人证核验算法的andriod/linux智能终端。内嵌身份证解密模块，搭配双目摄像头，满足离线人证核验需求。`, //介绍
              name: "单屏桌面智能终端 TW-S01",
              effect: {
                title: "产品功能",
                content: [
                  "双目宽动态摄像头，成像清晰，准确识别。",
                  "摄像头可180度水平转动。",
                  "支持身份证识别。",
                  "支持人证比对。",
                ], //功能
              },
              parameterText: {
                title: "设备参数",
                content: [
                  {
                    title:"显示屏参数",
                    contentList:[
                      { name:"面板尺寸",parameter:"10.1"},
                      { name:"显示模式",parameter:"16:9"},
                      { name:"分辨率",parameter:"1280(RGB)×800"},
                      { name:"显示色彩",parameter:"16.7M"},
                      { name:"亮度",parameter:"300 cd/m² "},
                      { name:"可视角度",parameter:"89/89/89/89 (Min.)(CR≥10)"}
                    ]
                  },
                   {
                    title:"触摸屏参数",
                    contentList:[
                      { name:"产品尺寸",parameter:"10.1寸"},
                      { name:"触摸特性",parameter:"手指/触摸笔"},
                      { name:"显示模式",parameter:"16:9，宽屏"},
                      { name:"触摸点数",parameter:"10点电容触控"},
                    ]
                  },
                  {
                    title:"硬件参数",
                    contentList:[
                      { name:"主板",parameter:"RK3288"},
                      { name:"处理器",parameter:"1.8 G CPU"},
                      { name:"内存",parameter:"2GB"},
                      { name:"储存体",parameter:"8GB"},
                      { name:"操作系统",parameter:"安卓7.1"},
                      { name:"输出/入端口",parameter:"USB2.0*2、LAN端口*1、音频(Line Out)接口*1、Mic接口*1、HDMI Out*1"},
                      { name:"喇叭",parameter:"2*3W (8Ω)"},
                      { name:"网络接入",parameter:"有线网络、WIFI"},
                    ]
                  },
                  {
                    title:"使用环境",
                    contentList:[
                      { name:"工作温度",parameter:"-10℃～50℃"},
                      { name:"储存温度",parameter:"-20℃～60℃"},
                      { name:"操作湿度",parameter:"<85%"},
                      { name:"工作电压",parameter:"12V ~3A  功率大约:35W"},
                    ]
                  },
                  {
                    title:"内置外设",
                    contentList:[
                      { name:"摄像头",parameter:"双目摄像头、宽动态、抗逆光、摄像头角度可45度上下调节"},
                      { name:"身份证阅读器",parameter:"符合居民身份证验证机具通用技术要求，兼容ISO14443（TypeB/TypeA）标准；阅读距离：0-3cm；阅读时间：<0.8s。"},
                    ]
                  }
                ],
              }, //参数(文字表格)
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
          ],
        },
        {
          id: 1,
          title: "双屏桌面智能终端",
          contentList: [
            {
              id: 1,
              src: require("../../assets/imgAll/renzhen/TW-D01/rz1.png"), //产品图
              srcDetails: require("../../assets/imgAll/renzhen/TW-D01/rz1.png"), //产品详情页图
              introduce: `FC04双屏桌面智能终端，搭配10.1寸+13.3寸双面LCD显示屏幕，内嵌深度学习人脸识别算法。内嵌身份证解密模块和指纹模块。该产品具备人脸识别、人脸支付、人证比对、活体检测等功能。可广泛应用于食堂消费、学校出入口、酒店入住，电子政府办理以及写字楼等场景。`, //介绍
              name: "双屏桌面智能终端 TW-D01",
              effect: {
                title: "产品功能",
                content: [
                  "支持多款摄像头，包括双目摄像头、3D结构光摄像头、单目摄像头，满足人脸识别和人脸消费等各种场景。",
                  "双屏大小可自由搭配、触摸可选，如双10.1寸、双13.3寸、13.3寸+10.1寸、10.1寸+13.3寸等，支持双屏异显、异触，满足多种交互方式。",
                  "3.集成多种外设，如身份证阅读器、二维码扫描器、指纹仪、测温探头等。",
                  "4.支持多种系统，安卓系统(适配方案RK3288/RK3399等)、Windows系统(适配方案J1900/7200U等)。",
                ], //功能
              },
              parameterImg:[require("@/assets/imgAll/renzhen/TW-D01/xq1.png"),require("@/assets/imgAll/renzhen/TW-D01/xq2.png")],
              parameterText: {
                title: "设备参数",
                content: [
                  {
                    title:"显示参数",
                    contentList:[
                      { name:"面板尺寸",parameter:"13.3"},
                      { name:"显示模式",parameter:"16:9"},
                      { name:"分辨率",parameter:"1920(RGB)×1080 [FHD]"},
                      { name:"显示色彩",parameter:"16.7M"},
                      { name:"亮度",parameter:"300 cd/m² (Typ.)"},
                      { name:"对比度",parameter:"1400:1 (Typ.) (透射)"},
                      { name:"可视角度",parameter:"89/89/89/89 (Min.)(CR≥10)"}
                    ]
                  },
                   {
                    title:"触摸屏参数",
                    contentList:[
                      { name:"产品尺寸",parameter:"13.3寸"},
                      { name:"触摸特性",parameter:"手指/触摸笔"},
                      { name:"显示模式",parameter:"16:9，宽屏"},
                      { name:"触摸点数",parameter:"10点电容触控"},
                    ]
                  },
                  {
                    title:"硬件参数",
                    contentList:[
                      { name:"主板",parameter:"RK3288"},
                      { name:"处理器",parameter:"1.8 G CPU"},
                      { name:"内存",parameter:"2GB/4GB"},
                      { name:"储存体",parameter:"8G/16G"},
                      { name:"操作系统",parameter:"安卓7.1"},
                      { name:"输出/入端口",parameter:"USB2.0*4、LAN端口*1、音频(Line Out)接口*1、Mic接口*1、HDMI Out*1"},
                      { name:"喇叭",parameter:"2*3W (8Ω)"},
                      { name:"网络接入",parameter:"有线网络、WIFI"},
                    ]
                  },
                  {
                    title:"使用环境",
                    contentList:[
                      { name:"工作温度",parameter:"-10℃～50℃"},
                      { name:"储存温度",parameter:"-20℃～60℃"},
                      { name:"操作湿度",parameter:"<85%"},
                      { name:"工作电压",parameter:"12V ~3A  功率大约:35W"},
                    ]
                  },
                  {
                    title:"内置外设",
                    contentList:[
                      { name:"摄像头（标配）",parameter:"双目摄像头、宽动态、抗逆光、摄像头角度可45度上下调节"},
                      { name:"扫码（标配）",parameter:"一、二维扫码平台，单次扫描速度：小于200mms/次；支持纸质/电子一维二维码扫码读取。"},
                      { name:"身份证阅读器（选配）",parameter:"符合居民身份证验证机具通用技术要求，兼容ISO14443（TypeB/TypeA）标准；阅读距离：0-3cm；阅读时间：<0.8s。"},
                      { name:"小票打印机（选配）",parameter:"内置式小票打印机，858mm纸宽，手撕纸。预缺纸（少纸）检测、缺纸检测、缺纸报警。"},
                    ]
                  }
                ],
              }, //参数(文字表格)
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 2,
              src: require("../../assets/imgAll/renzhen/TW-D02/rz1.png"), //产品图
              srcDetails: require("../../assets/imgAll/renzhen/TW-D02/rz1.png"), //产品详情页图
              introduce: `双屏桌面智能终端FC06，搭配15.6寸+10.1寸双面LCD显示屏幕。外观时尚大气，功能强大。`, //介绍
              name: "双屏桌面智能终端 TW-D02",
              effect: {
                title: "产品功能",
                content: [
                  "支持多款摄像头，包括双目摄像头、3D结构光摄像头、单目摄像头，满足人脸识别和人脸消费等各种场景。",
                  "支持双屏异显、异触，满足多种交互方式。",
                  "集成多种外设，如身份证阅读器、二维码扫描器、指纹仪等。",
                  "支持多种系统，安卓系统(适配方案RK3288/RK3399等)、Windows系统(适配方案J1900/7200U等)。",
                ], //功能
              },
              parameterImg:[require("@/assets/imgAll/renzhen/TW-D02/xq1.png")],
              parameterText: {
                title: "设备参数",
                content: [
                  {
                    title:"显示参数",
                    contentList:[
                      { name:"面板尺寸",parameter:"10.1"},
                      { name:"显示模式",parameter:"16:9"},
                      { name:"分辨率",parameter:"1280(RGB)×800"},
                      { name:"显示色彩",parameter:"16.7M"},
                      { name:"亮度",parameter:"300 cd/m² (Typ.)"},
                      { name:"对比度",parameter:"1400:1 (Typ.) (透射)"},
                      { name:"可视角度",parameter:"89/89/89/89 (Min.)(CR≥10)"}
                    ]
                  },
                   {
                    title:"触摸屏参数",
                    contentList:[
                      { name:"产品尺寸",parameter:"10.1寸"},
                      { name:"触摸特性",parameter:"手指/触摸笔"},
                      { name:"显示模式",parameter:"16:9，宽屏"},
                      { name:"触摸点数",parameter:"10点电容触控"},
                    ]
                  },
                  {
                    title:"硬件参数",
                    contentList:[
                      { name:"主板",parameter:"RK3288"},
                      { name:"处理器",parameter:"1.8 G CPU"},
                      { name:"内存",parameter:"2GB/4GB"},
                      { name:"储存体",parameter:"8G/16G"},
                      { name:"操作系统",parameter:"安卓7.1"},
                      { name:"输出/入端口",parameter:"USB2.0*4、LAN端口*1、音频(Line Out)接口*1、Mic接口*1、HDMI Out*1"},
                      { name:"喇叭",parameter:"2*3W (8Ω)"},
                      { name:"网络接入",parameter:"有线网络、WIFI"},
                    ]
                  },
                  {
                    title:"使用环境",
                    contentList:[
                      { name:"工作温度",parameter:"-10℃～50℃"},
                      { name:"储存温度",parameter:"-20℃～60℃"},
                      { name:"操作湿度",parameter:"<85%"},
                      { name:"工作电压",parameter:"12V ~3A  功率大约:35W"},
                    ]
                  },
                  {
                    title:"内置外设",
                    contentList:[
                      { name:"摄像头（标配）",parameter:"双目摄像头、宽动态、抗逆光、摄像头角度可45度上下调节"},
                      { name:"扫码（标配）",parameter:"一、二维扫码平台，单次扫描速度：小于200mms/次；支持纸质/电子一维二维码扫码读取。"},
                      { name:"身份证阅读器（选配）",parameter:"符合居民身份证验证机具通用技术要求，兼容ISO14443（TypeB/TypeA）标准；阅读距离：0-3cm；阅读时间：<0.8s。"},
                      { name:"小票打印机（选配）",parameter:"内置式小票打印机，858mm纸宽，手撕纸。预缺纸（少纸）检测、缺纸检测、缺纸报警。"},
                      { name:"指纹仪（选配）",parameter:"电容指纹传感器，采集窗口尺寸:20.4MM*33.4MM；图像大小：256*288pixel；图像像素：500DPI"},
                    ]
                  }
                ],
              }, //参数(文字表格)
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 3,
              src: require("../../assets/imgAll/renzhen/TW-D03/rz1.png"), //产品图
              srcDetails: require("../../assets/imgAll/renzhen/TW-D03/rz1.png"), //产品详情页图
              introduce: `FC08双屏桌面智能终端，搭配双11.6高清LCD显示屏幕，设备精致小巧，科技感强。内嵌身份证解密模块和二维码扫码器模块。该产品具备人脸识别、人证比对、活体检测等功能。可广泛应用于食堂消费、学校出入口、酒店入住，电子政府办理以及写字楼等场景。`, //介绍
              name: "双屏桌面智能终端 TW-D03",
              effect: {
                title: "产品功能",
                content: [
                  "支持多款摄像头，包括双目摄像头、单目摄像头、3D结构光摄像头，满足人脸识别和人脸消费等各种场景。",
                  "双11.6高清显示，支持双屏异显、异触，满足多种交互方式。",
                  "集成多种外设，如身份证阅读器、二维码扫描器等。",
                  "支持多种系统，安卓系统(适配方案RK3288/RK3399等)、Windows系统(适配方案J1900/7200U等)。",
                ], //功能
              },
              parameterImg:[require("@/assets/imgAll/renzhen/TW-D03/xq1.png")],
              parameterText: {
                title: "设备参数",
                content: [
                  {
                    title:"显示参数",
                    contentList:[
                      { name:"面板尺寸",parameter:"11.6"},
                      { name:"显示模式",parameter:"16:9"},
                      { name:"分辨率",parameter:"1366*768"},
                      { name:"显示色彩",parameter:"16.7M"},
                      { name:"亮度",parameter:"300 cd/m² (Typ.)"},
                    ]
                  },
                   {
                    title:"触摸屏参数",
                    contentList:[
                      { name:"产品尺寸",parameter:"11.6寸"},
                      { name:"触摸特性",parameter:"手指/触摸笔"},
                      { name:"显示模式",parameter:"16:9，宽屏"},
                      { name:"触摸点数",parameter:"10点电容触控"},
                    ]
                  },
                  {
                    title:"硬件参数",
                    contentList:[
                      { name:"主板",parameter:"RK3288"},
                      { name:"处理器",parameter:"1.8 G CPU"},
                      { name:"内存",parameter:"2GB"},
                      { name:"储存体",parameter:"16G"},
                      { name:"操作系统",parameter:"安卓7.1"},
                      { name:"输出/入端口",parameter:"USB2.0*2、LAN端口*1、音频(Line Out)接口*1、Mic接口*1、HDMI Out*1  "},
                      { name:"喇叭",parameter:"2*3W (8Ω)"},
                      { name:"网络接入",parameter:"有线网络、WIFI"},
                    ]
                  },
                  {
                    title:"使用环境",
                    contentList:[
                      { name:"工作温度",parameter:"-10℃～50℃"},
                      { name:"储存温度",parameter:"-20℃～60℃"},
                      { name:"操作湿度",parameter:"<85%"},
                      { name:"工作电压",parameter:"12V ~3A  功率大约:35W"},
                    ]
                  },
                  {
                    title:"内置外设",
                    contentList:[
                      { name:"摄像头（标配）",parameter:"双目摄像头、宽动态、抗逆光、摄像头角度可45度上下调节"},
                      { name:"扫码（标配）",parameter:"一、二维扫码平台，单次扫描速度：小于200mms/次；支持纸质/电子一维二维码扫码读取。"},
                      { name:"身份证阅读器（选配）",parameter:"符合居民身份证验证机具通用技术要求，兼容ISO14443（TypeB/TypeA）标准；阅读距离：0-3cm；阅读时间：<0.8s。"},
                    ]
                  }
                ],
              }, //参数(文字表格)
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
          ],
        },
      ],

      titleIndex: 0, //选择系列得下标;同时作为显示内容的下标
    };
  },
  methods: {
    toDetails(obj) {
      this.$router.push({
        path: "productdetailsppage",
        query: {
          list: JSON.stringify(obj),
        },
      });
    },
  getDivAddr(){
      // 获取要添加动画的dom元素（也可用ref）
      let dom = document.getElementsByClassName('mateDom')
      // 获取页面高度；后期与bottom做对比
      let height = document.documentElement.clientHeight
      // 给每一个添加动画的dom元素添加事件
      for (const iterator of dom) {
        // 获取dom距离可视区域的高度
        let list = iterator.getBoundingClientRect()
        // 利用top bottom距离可视区域顶部的距离判断元素是否在可视区域中
        if(list.bottom>0 && list.top<height-100){
          // 将预先隐藏的元素显示
          // 注意：用visibility而不是用disablay
          // 添加动画类名
        iterator.style.visibility='visible'
        iterator.classList.add('animate__animated', 'animate__fadeInUp');
      }
      }
    },
    // 监听滚动条事件
    windowScroll() {
      this.getDivAddr()
    },
  },
  mounted(){
    this.getDivAddr()
    // 监听滚动条
    window.addEventListener('scroll', this.windowScroll)
  }
};
</script>
<style lang="less" scoped>
.jiejue {
  width: 100%;
  ul {
    li {
      width: 33.333%;
      padding: 1%;
      height: 100%;
      float: left;
      .jiejueimg {
        width: 100%;
        height: 290px;
        line-height: 290px;
        background-color: rgb(255, 255, 255);
        text-align: center;
        vertical-align: middle;
        overflow: hidden;
        .chanpintu {
          width: 180px;
          height: 200px;
          // overflow: hidden;
          // transition: ease 0.5s;
          margin-top: 40px;
        }
      }
      .neirong {
        background-color: rgb(255, 255, 255);
        border-top: 1px solid #e5e5e5;
        height: 120px;
        text-align: center;
        padding: 28px 20px;
        transition: ease 0.5s;
        .wenzi {
          color: rgb(14, 14, 14);
          font-size: 22px;
          font-weight: 400;
          margin-bottom: 12px;
          transition: ease 0.5s;
        }
        .ttitle {
          font-size: 16px;
          line-height: 30px;
          color: #666;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    li:hover .wenzi {
      color: #0e57a2;
    }
    li:hover .neirong {
      border-top: 1px solid #0e57a2;
      transform: scale(x, y);
    }
    li:hover .chanpintu {
      transform: scale(1.2);
    }
  }
}
.title {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  p {
    margin: 0 20px;
  }
  .titleActive {
    color: #2468c3;
    font-weight: bold;
  }
  .mateDom{
    visibility: hidden;
  }
}
</style>
