<template>
  <div class="Contact-us">
    <!--联系我们 开始-->
    <!-- <el-main class="anli">
      <div class="jiejuebiaoti mateDom">
        了解信创科技如何帮助您
      </div>
      <div class="lianxi">
        <ul>
          <li v-for="item in lianxilist" :key="item.id" class="mateDom">
            <div class="neirong">
              <div :class="[item.activeClass ,item.errorClass]"></div>
              <div class="wenzi">{{item.name}}</div>
            </div>
          </li>
        </ul>
      </div>

    </el-main> -->
    <div class="foot">
        <div></div>
        <ul>
          <li>电话：18589058595</li>
          <li>邮箱：xiewei@neinninfo.com</li>
          <li>微信：xw812407989</li>
          <li>地址：深圳市宝安区西乡街道华丰互联网创意园A座一楼101</li>
          <!-- <li>Copyright © 2009-2029 Cld, 深圳市信创信息科技有限公司 版权所有 粤ICP备19067867号-1</li> -->
        </ul>
        <div class="img">
          <img src="@/assets/imgAll/wisdom/CompanyProfile/code.jpg" alt="">
          <span>微信公众号</span>
        </div>

      </div>
    <!--联系我们 结束-->
    <el-main class="beian">Copyright © 2009-2029 Cld, 深圳市信创信息科技有限公司 版权所有 粤ICP备19067867号-1</el-main>
  </div>
</template>

<script>
export default {
  name: 'Contact-us',
  components: {},
  data() {
    return {
      lianxilist: [
        {
          id: 1,
          activeClass: 'iconfont ',
          errorClass: 'icon-24gf-phoneLoudspeaker',
          name: '18589058595'
        },
        {
          id: 2,
          activeClass: 'iconfont ',
          errorClass: 'icon-youjian',
          name: 'xiewei@neinninfo.com'
        },
        {
          id: 3,
          activeClass: 'iconfont ',
          errorClass: 'icon-weixin',
          name: 'xw812407989'
        },
        {
          id: 4,
          activeClass: 'iconfont ',
          errorClass: 'icon-daohangdizhi',
          name: '深圳市宝安区西乡街道华丰互联网创意园A座一楼101'
        }
      ]
    }
  },
  methods:{
  getDivAddr(){
      // 获取要添加动画的dom元素（也可用ref）
      let dom = document.getElementsByClassName('mateDom')
      // 获取页面高度；后期与bottom做对比
      let height = document.documentElement.clientHeight
      // 给每一个添加动画的dom元素添加事件
      for (const iterator of dom) {
        // 获取dom距离可视区域的高度
        let list = iterator.getBoundingClientRect()
        // 利用top bottom距离可视区域顶部的距离判断元素是否在可视区域中
        if(list.bottom>0 && list.top<height-100){
          // 将预先隐藏的元素显示
          // 注意：用visibility而不是用disablay
          // 添加动画类名
        iterator.style.visibility='visible'
        iterator.classList.add('animate__animated', 'animate__fadeInUp');
      }
      }
    },
    // 监听滚动条事件
    windowScroll() {
      this.getDivAddr()
    },
  },
  mounted(){
    this.getDivAddr()
    // 监听滚动条
    window.addEventListener('scroll', this.windowScroll)
  }
}
</script>
<style lang="less">
.foot{
  width: 100%;
  height: auto;
  background-color: #000;
  display: grid;
  grid-template-columns: 25% 45% 30%;
  color: #ffffff;
  padding: 20px;
  ul{
    margin-top: 30px;
  }
  li{
    line-height: 2em;
  }
  .img{
    width: 120px;
    margin-top: 10px;
    text-align: center;
    img{
      width: 120px;
      margin-bottom: 5px;
    }
  }
}

.beian {
  text-align: center;
  background-color: #000;
  color: #999;
  font-size: 14px;
}
.anli {
  height: 350px;
  padding-top: 40px;
  background: url('../assets/lianxiwomen.jpg') no-repeat;
  width: 100%;
  background-size: 100% 100% !important;
  .jiejuebiaoti {
    width: 100%;
    font-size: 32px;
    font-weight: 600;
    text-align: center;
    margin: 0 auto;
    color: #ffffff;
    margin-top: 10px;
    margin-bottom: 40px;
  }
  .lianxi {
    width: 100%;
    // height: 120px;
    ul {
      height: 160px;
      li {
        width: 25%;
        padding: 1% 2%;
        height: 100%;
        float: left;
        .neirong {
          width: 100%;
          height: 100%;
          background-color: rgba(90, 90, 90, 0.8);
          padding: 15px;
          .iconfont {
            font-size: 45px;
            color: #ffffff;
            text-align: center;
          }
          .wenzi {
            margin-top: 10px;
            color: #ffffff;
            text-align: center;
            line-height: 1.5;
          }
        }
        .neirong:hover {
          background-color: #0e57a2;
        }
      }
    }
  }
  .mateDom{
    visibility: hidden;
  }
}
</style>
