import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'

import HomePage from '@/views/HomePage.vue'
import TheSolution from '@/views/TheSolution.vue'
import ProductCenter from '@/views/ProductCenter.vue'
import SoftwareTechnology from '@/views/SoftwareTechnology.vue' 
import ProductDetailsPage from '@/views/ProductDetailsPage.vue'
import SmartCampus from '@/views/SmartEngineering/SmartCampus.vue'
import SmartCommunity from '@/views/SmartEngineering/SmartCommunity.vue'
import SmartConstructionSite from '@/views/SmartEngineering/SmartConstructionSite.vue'
import SmartKitchen from '@/views/SmartEngineering/SmartKitchen.vue'
import CompanyProfile from '@/views/SmartEngineering/CompanyProfile.vue'
import Vlog from '@/views/Vlog.vue'
import SmartPark from '@/views/SmartEngineering/SmartPark.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    //name:'Home',
    component: Home,
    redirect: '/home/page',
    children: [
      {
        path: 'page',
        //name:'HomePage',
        component: HomePage
      },
      {
        path: 'thesolution',
        //name:'TheSolution',
        component: TheSolution
      },
      {
        path: 'productcenter',
        //name:'productcenter',
        component: ProductCenter
      },
      {
        path: 'softwaretechnology',
        //name:'SoftwareTechnology',
        component: SoftwareTechnology
      },
      {
        path:'productdetailsppage',
        component:ProductDetailsPage
      },
      {
        // 智慧校园
        path:'smartcampus',
        component:SmartCampus
      },
      {
        // 智慧社区
        path:'SmartCommunity',
        component:SmartCommunity
      },
      {
        //智慧工地
        path:'SmartConstructionSite',
        component:SmartConstructionSite
      },
      {
        //名厨亮灶
        path:'SmartKitchen',
        component:SmartKitchen
      },
      {
        //智慧园区
        path:'SmartPark',
        component:SmartPark
      },
      {
        //vlog
        path:'Vlog',
        component:Vlog
      },
      {
        //公司简介
        path:'CompanyProfile',
        component:CompanyProfile
      }
    ]
  }
]

const router = new VueRouter({
  //mode: 'history',
  base: process.env.BASE_URL,
  
  routes
})

export default router
