<template>
  <div class="Core-technology">
   
      <div class="jiejuebiaoti mateDom">
        <span class="zhuangshi"></span>
        核心技术
        <span class="zhuangshi"></span>
      </div>
      <div class="jiejue">
        <ul class="clearfix"> 
          <li v-for="item in hexinjishulist" :key="item.id" class="clearfix mateDom">
            <div class="ruanjian">
              <div class="jiejueimg">
                <el-image :src="item.src" alt="" lazy class="chanpintu"></el-image>
              </div>
              <div class="neirong">
                <div class="wenzi">{{item.name}}</div>
                <div class="ttitle">{{item.chakan}}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>

      <div class="jiejuebiaoti mokuai mateDom">
        <span class="zhuangshi"></span>
        产品服务
        <span class="zhuangshi"></span>
      </div>
      <div class="jiejue">
        <ul class="clearfix">
          <li v-for="item in chanpinfuwulist" :key="item.id" class="mateDom" >
            <div class="ruanjian">
              <div class="jiejueimg">
                <el-image :src="item.src" alt="" lazy class="chanpintu"></el-image>
              </div>
              <div class="neirong">
                <div class="wenzi">{{item.name}}</div>
                <div class="ttitle">{{item.chakan}}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
   

  </div>
</template>

<script>
export default {
  name: 'Core-technology',
  components: {},
  data() {
    return {
      hexinjishulist: [
        {
          id: 1,
          src: require('../../assets/heixinjishu/vein.jpg'),
          name: '静脉识别',
          chakan: '一般分为穿透式与反射式两种样式其中指静脉主要采用:穿透式成像，掌静脉、手静脉等主要采用反射式成像'
        },
        {
          id: 2,
          src: require('../../assets/heixinjishu/palm.jpg'),
          name: '手掌识别',
          chakan: '针对复杂光线环境、姿态高容忍度及大容量识别的需求开发出的一款极为优秀的近红外手掌识别算法'
        },
        {
          id: 3,
          src: require('../../assets/heixinjishu/iris.jpg'),
          name: '虹膜识别',
          chakan: '利用虹膜交错的斑点、细丝、冠状、条纹、隐窝等细节特征以及虹膜特征的唯一性，作为身份识别的一种方法'
        },
        {
          id: 4,
          src: require('../../assets/heixinjishu/ic_zwsb.png'),
          name: '指纹识别',
          chakan: '指纹纹路在图案、断点和交叉点上各不相同，利用这种唯一性和稳定性，作为生物识别的身份核验'
        },
        {
          id: 5,
          src: require('../../assets/heixinjishu/ic_rlsb.png'),
          name: '人脸识别',
          chakan: '在人脸库中查找相似人脸，比较两张人脸的相似度，做性别和年龄的属性分析，检测是否真人，预防恶意攻击'
        },
        {
          id: 6,
          src: require('../../assets/heixinjishu/ic_erke.png'),
          name: '可信身份认证',
          chakan: '与中关村安信联盟CTID“互联网+”可信身份认证平台实现数据互联互通，覆盖全国14亿人口库数据'
        }
      ],
      chanpinfuwulist: [
        {
          id: 1,
          src: require('../../assets/chanpinfuwu/duanxin.jpg'),
          name: '短信',
          chakan: '提供访客预约、邀约、审核进行短信通知服务'
        },
        {
          id: 2,
          src: require('../../assets/chanpinfuwu/huotijiance.png'),
          name: '活体检测SDK',
          chakan: '活体检测可判断操作用户是否为真人，有效抵御照片、视频、模具等作弊攻击，增强了系统的安全性'
        },
        {
          id: 3,
          src: require('../../assets/chanpinfuwu/ic_nmtht2x.png'),
          name: '多模态后台比对系统',
          chakan: '混合生物识别后台比对系统，支持20万指纹模板+1万可见光人脸模板+5000近红外人脸模板'
        },
        {
          id: 4,
          src: require('../../assets/chanpinfuwu/lianxi.png'),
          name: 'AI人脸服务器',
          chakan: '先进的人脸识别算法，识别率高，误判低，支持5万 人脸底库，可进行二次开发以满足不同的应用场景需求'
        },
        {
          id: 5,
          src: require('../../assets/chanpinfuwu/rzhy.jpg'),
          name: '人证核验',
          chakan: '支持集身份证读取、指纹核验、人脸比对等多种验证方式于一体'
        },
        {
          id: 6,
          src: require('../../assets/chanpinfuwu/smrz.jpg'),
          name: '实名认证',
          chakan: '支持〖姓名+身份证号〗二要素认证对接权威数据源及验证服务，精确判断用户身份真实性'
        },
        {
          id: 7,
          src: require('../../assets/chanpinfuwu/srrz.jpg'),
          name: '实人认证',
          chakan: '支持〖姓名+身份证号+人脸图片〗三要素认证对接权威数据源及验证服务,精确判断用户身份真实性'
        },
        {
          id: 8,
          src: require('../../assets/chanpinfuwu/wangzhengyingyong.png'),
          name: '网证应用',
          chakan: '网证是法定实体证件的网上映射可提供网证下载、赋码、验码等接口服务'
        },
        {
          id: 9,
          src: require('../../assets/chanpinfuwu/xcx.jpg'),
          name: '小程序',
          chakan: '提供员工、访客进行预约、邀约、审核等访客应用服务'
        }
      ]
    }
  },
  methods: {
    
   getDivAddr(){
      // 获取要添加动画的dom元素（也可用ref）
      let dom = document.getElementsByClassName('mateDom')
      // 获取页面高度；后期与bottom做对比
      let height = document.documentElement.clientHeight
      // 给每一个添加动画的dom元素添加事件
      for (const iterator of dom) {
        // 获取dom距离可视区域的高度
        let list = iterator.getBoundingClientRect()
        // 利用top bottom距离可视区域顶部的距离判断元素是否在可视区域中
        if(list.bottom>0 && list.top<height-100){
          // 将预先隐藏的元素显示
          // 注意：用visibility而不是用disablay
          // 添加动画类名
        iterator.style.visibility='visible'
        iterator.classList.add('animate__animated', 'animate__fadeInUp');
      }
      }
    },
    // 监听滚动条事件
    windowScroll() {
      this.getDivAddr()
    },
  },
  mounted(){
    this.getDivAddr()
    // 监听滚动条
    window.addEventListener('scroll', this.windowScroll)
  }
}
</script>
<style lang="less" scoped>
.mateDom{
    visibility: hidden;
  }
/* 清除浮动 */
.clearfix:after {
    visibility: hidden;
    clear: both;
    display: block;
    content: ".";
    height: 0
}

.clearfix {
    *zoom: 1
}
.mokuai {
  padding-top: 50px;
}
.jiejuebiaoti {
  width: 100%;
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  margin: 0 auto;
  color: #2f2f2f;
  //margin-top: 10px;
  margin-bottom: 25px;
  .zhuangshi {
    width: 140px;
    height: 1px;
    display: inline-block;
    background-color: rgb(204, 204, 204);
    //float: left;
    margin: 0 30px;
    margin-bottom: 8.5px;
  }
}
.jiejue {
  width: 100%;
  ul {
    li {
      width: 33.333%;
      padding: 2%;
      //height: 100%;
      float: left;
      .ruanjian {
        box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.35);
        border-radius: 4px;
        background-color: rgb(255, 255, 255);
        overflow: hidden;
        transition: ease 0.5s;
        .jiejueimg {
          width: 100%;
          height: 290px;
          line-height: 290px;
          background-color: rgba(0, 0, 0, 0.7);
          text-align: center;
          vertical-align: middle;
          overflow: hidden;
          .chanpintu {
            width: 100%;
            height: 100%;
            overflow: hidden;
            transition: ease 0.5s;
          }
        }
        .neirong {
          height: 150px;
          text-align: center;
          padding: 20px 16px;
          transition: ease 0.5s;
          .wenzi {
            color: rgb(14, 14, 14);
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 18px;
            transition: ease 0.5s;
          }
          .ttitle {
            font-size: 14px;
            line-height: 30px;
            color: #666;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }
    }
    li:hover .ruanjian .wenzi {
      color: #0e57a2;
    }
    li:hover .ruanjian {
      transform: scale(1.05);
    }
  }
}
</style>
