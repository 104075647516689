<template>
  <div class="while">
    <el-image
      :src="require('@/assets/imgAll/wisdom/Smartkitchen/backImg.jpg')"
      fit="cover"
      class="backImg"
    ></el-image>

    <!-- 内容区域 -->
    <div class="content">
      <p class="type animate__animated animate__backInLeft">
        <i class="el-icon-guide"></i> 名厨亮灶
      </p>
      <p class="title">行业概述</p>
      <el-card class="box-card">
        <div class="card">
          <div class="cardItem">
            <p class="cardItem_p">
             “智慧厨房”的创新理念，是通过互联网、物联网、大数据、视觉识别等前沿科技的融合创新，引领智慧食堂建设突破前厅售卖场景，延伸到团餐服务链上的每个环节，整合食堂供应端、营运端和消费端，实现食堂营运全场景的“数字化升级”，打造智慧食堂一站式整体解决方案。
            </p>
          </div>
          <div class="cardItem">
            <el-image
              :src="
                require('@/assets/imgAll/wisdom/Smartkitchen/hygsImg02.png')
              "
              fit="scale-down"
              class="backImg"
              style="height:320px"
            ></el-image>
          </div>
        </div>
      </el-card>
      <p class="title">需求分析</p>
      <el-card class="box-card ">
        <div class="card-grid">
          <el-image
              :src="require('@/assets/imgAll/wisdom/Smartkitchen/hygsImg02.jpg')"
              fit="scale-down"
              class="backImg"
              style="height:320px"
            ></el-image>
        <div style="margin:35px 0 0 25px">
          <p class="box-card-p">
            1.智慧厨房拼的不是产品是生态，真正的智慧厨房是不在厨房盯着就能完成美食烹饪。
          </p>
          <p class="box-card-p">
            2.智慧厨房没有生命周期，比拼的是软件迭代速度和人文关怀，同时也是为用户提供持续关爱的平台。
          </p>
          <p class="box-card-p">
            3.真正的智慧厨房是数字化的高级阶段，是具有烟火气息场景生态的创新，也是从让用户选择到为用户定制方案的全新平台。
          </p>
        </div>
        </div>
        
      </el-card>
      <p class="title">方案优势</p>
      <el-card class="box-card">
        <p class="box-card-title">1、人工成本</p>
        <p class="box-card-p">
          采用通岗培训，无需专业厨师，通过合理的任务规划，可实现人员与岗位配比的最优值。同时，广泛应用的智能设备，替代人工操作，显著降低用工成本。
        </p>
        <p class="box-card-title">2、食材成本</p>
        <p class="box-card-p">
          采用组件化的半成品净菜，无清洗、切配等加工环节，食材损耗少。智能系统采集每日菜品售卖数据，反向指导食材采购计划，采购精准，库存少，避免食材过期造成的浪费。
        </p>
        <p class="box-card-title">3、厨房面积</p>
        <p class="box-card-p">
          后厨采用专业的智能厨具，可替代90%的传统厨房设备。同时，食材为半成品净菜到库，可节省单独的切配区。多余的空间增设全自动洗消间，提升后厨空间的利用率。
        </p>
        <p class="box-card-title">4、客户满意度</p>
        <p class="box-card-p">
          多种智能自助设备，让就餐者享受便捷的服务，自助选餐结算，排队时间短，就餐更愉快。
        </p>
      </el-card>
      <p class="title">特色功能</p>
      <el-card class="box-card"> 
        <p class="box-card-title">标准化生产体系</p>
        <p class="box-card-p">
          全流程标准化品控，出品质量稳定，出餐速度快，安全可控，食堂运行效率高。优选绿色食材供应链，食材通过农业良好规范GAP标准认证；物流配送全程GPS跟踪，一物一码，出品严格，溯源安全有保障；标准化复合调料包，囊括鲜、香、麻、辣、咸、甜等风味，满足不同口味需求；通过智能烹饪设备，结合数据化标准配比与最佳烹饪时间，一键智能化操作，15分钟可以出品400人份，保留肉的多汁和色彩的鲜艳性，色泽诱人，出品一致。
        </p>
        <p class="box-card-title">数字化后勤系统</p>
        <p class="box-card-p">
         统计分析食堂日常营运数据，研发标准化菜谱、数字化排菜，实现美味不重样、利润节节高。结合“中国居民膳食宝塔”、“中国居民膳食能量需要量”等国民能量图谱，兼具时令和地域特色，通过系统的科学运算与分析，获取食客消费偏好；通过数字化排菜，自动分解BOM，汇总物料，生成采购需求单，精准采购，实现每日都有新菜单、有效减少损耗浪费。支持仓库管理的入库、出库及盘点业务，实现产销数字化，营运更便捷。
        </p>
        <p class="box-card-title">低人耗降本增效</p>
        <p class="box-card-p">
          使用半成品净菜、复合酱料供应链服务，无需切、配、洗和专业大调味厨，按照科学标准配比，均匀混合净菜包与酱料包，再通过智能蒸烤箱模拟中式传统烹饪工艺，满足煎、炒、烹、炸、焖、炖、蒸、煮等出品效果。智能蒸烤箱可自动识别食材的大小、数量，无需人工监管，一台可服务600人就餐，一人即可实现高产能与质量品控，在保证松脆的表皮、清爽的口感等美味基础之上，少油、少盐，健康饮食。有效减少40%人工成本、减少30%后厨面积、减少60%营运成本，降本增效。
        </p>
      </el-card>
    </div>
    <div class="black">
      <router-link to="/home/thesolution" class="blackLink">
        <i class="el-icon-d-arrow-left"></i> 返回解决方案
      </router-link>
    </div>
  </div>
</template>

<script>
import "animate.css";
export default {
  name: "SmartKitchen",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.while {
  width: 100%;
  height: auto;
  padding-top: 82px;
  .backImg{
    width: 100%;
    height: 370px;
  }
  .type {
    text-align: right;
    font-size: 21px;
    // font-weight: 500;
    font-family: STHupo;
  }
  .title {
    font-size: 18px;
    border-left: 2px solid #78bc27;
    padding-left: 10px;
    margin: 20px 0 30px 0;
  }
  .content {
    width: 100%;
    height: auto;
    padding: 20px 3%;
  }
  .card {
    display: grid;
    grid-template-columns: 60% 40%;
    .cardItem {
      .cardItem_p {
        font-size: 16px;
        color: #666;
        line-height: 32px;
        font-weight: 500;
      }
      .card-item-p {
        line-height: 2;
        font-size: 16px;
      }
    }
  }
  .box-card-title {
    font-size: 18px;
    line-height: 1.5;
    font-weight: bold;
    color: #666;
  }
  .box-card-p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #666;
  }

  .black {
    width: 100%;
    height: 120px;
    line-height: 120px;
    text-align: center;
    background-color: #f7f7f7;
    margin: 20px 0;
    .blackLink {
      color: #333;
      background-color: #e3e3e3;
      text-decoration: none;
      padding: 10px;
      border-radius: 5px;
    }
  }
  .card-grid{
    display: grid;
    grid-template-columns: 40% 60%;
  }
}
</style>