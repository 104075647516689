<template>
  <div class="navigation" ref="navigation">
    <el-header height="82px" class="dingbu">
      <img class="logo" src="../assets/logo.png" alt="logo" />
      <div class="wenzi">
        <ul>
          <!-- <li v-for="item in daohanglist" :key="item.id" @click="dianji(item.id)">
            <router-link :to="item.to" class="lianjie" v-if="item.isActive"> {{item.name}}</router-link>
            <router-link :to="item.to" class="lianjiehou" v-else> {{item.name}}</router-link>
          </li> -->
          <li @click="islianjie=0">
            <router-link to="/home/page" class="lianjie" :class="islianjie == 0 ? 'lianjieActive' : ''" > 首页</router-link>
          </li>
          <li @mouseenter="whichShow = 'fa'" @mouseleave="whichShow = ''"  @click="islianjie=1">
            <router-link to="/home/thesolution" class="lianjie" :class="islianjie == 1 ? 'lianjieActive' : ''">
              解决方案</router-link
            >
            <div
              class="select animate__animated animate__fadeIn"
              :class="[whichShow == 'fa' ? 'selectShow' : '']"
            >
              <p
                class="selectItem"
                v-for="(item, index) in selectArr.programme"
                :key="index"
                @click="toSmart(index)"
              >
                {{ item }}
              </p>
            </div>
          </li>

          <li @mouseenter="whichShow = 'cp'" @mouseleave="whichShow = ''" @click="islianjie=2">
            <router-link to="/home/productcenter" class="lianjie" :class="islianjie == 2 ? 'lianjieActive' : ''" >
              产品中心</router-link
            >
            <!-- 下拉内容 -->
            <div
              class="lianjieSelect animate__animated animate__fadeIn"
              :class="[whichShow == 'cp' ? 'lianjieSelectShow' : '']"
            >
              <div class="lianjieSelect_item">
                <p
                  class="item"
                  v-for="(item, index) in selectList"
                  :key="index"
                  @mouseenter="itemIndex = index"
                  :class="[itemIndex == index ? 'titleActive' : '']"
                  @click="toProductcenter(item.id)"
                >
                  {{ item.title }}
                </p>
              </div>
              <div
                class="lianjieSelect_item lianjieSelect_itemRight"
                @mouseleave="contentIndex = 0"
              >
                <p
                  class="item"
                  v-for="(item, index) in selectList[itemIndex].content"
                  :key="index"
                  @mouseenter="contentIndex = index"
                  :class="[contentIndex == index ? 'titleActive' : '']"
                  @click="toProductcenter(selectList[itemIndex].id)"
                >
                  {{ item }}
                </p>
              </div>
            </div>
          </li>
          <li @click="islianjie=3">
            <router-link to="/home/softwaretechnology" class="lianjie" :class="islianjie == 3 ? 'lianjieActive' : ''">
              软件技术</router-link
            >
          </li>
          <li @click="islianjie=4">
            <router-link to="/home/vlog" class="lianjie" :class="islianjie == 4 ? 'lianjieActive' : ''">
              景区Vlog</router-link
            >
          </li>         
          <li @click="islianjie=5">
            <router-link to="/home/CompanyProfile" class="lianjie" :class="islianjie == 5 ? 'lianjieActive' : ''">
              公司简介</router-link
            >
          </li>
        </ul>
      </div>
    </el-header>
  </div>
</template>

<script>
import "animate.css";
export default {
  name: "navigation",
  components: {},
  data() {
    return {
      daohanglist: [
        { id: 1, isActive: false, aa: false, name: "首页", to: "/home/page" },
        {
          id: 2,
          isActive: true,
          aa: false,
          name: "核心技术",
          to: "/home/softwaretechnology",
        },
        {
          id: 3,
          isActive: true,
          aa: false,
          name: "产品中心",
          to: "/home/productcenter",
        },
        {
          id: 4,
          isActive: true,
          aa: false,
          name: "解决方案",
          to: "/home/thesolution",
        },
      ],
      selectList: [
        //产品中心
        {
          id:1,
          title: "AI人脸门禁系列",
          content: [
            "4.3寸人脸识别门禁",
            "5寸人脸识别一体机",
            "8寸人脸识别一体机",
            "8寸测温人脸识别机",
            "10.1寸人脸识别一体机",
          ],
        },
        {
          id:2,
          title: "人证机系列",
          content: [
            "单屏桌面智能终端",
            "双屏桌面智能终端",
          ],
        },
        {
          id:3,
          title: "AI摄像头系列",
          content: [
            "智慧校园系列",
            "200万高清网络摄像机",
            "全景系列",
          ],
        },
        {
          id:4,
          title: "边缘计算盒",
          content: [
            // "人脸识别计算盒",
            "智能边缘分析盒",
            // "智能边缘分析服务器（1U）",
          ],
        },
      ],
      islianjie:0,
      itemIndex: 0, //悬浮所在下标
      contentIndex: 0, //内容所在下标
      whichShow: "", //控制展示哪个下拉
      selectArr: {
        programme: ["智慧工地", "智慧校园", "智慧社区", "名厨亮灶", "智慧园区"], //解决方案
      },
    };
  },
  watch:{
    '$route.path':{
      immediate:true,
      handler(to, from){
        if(to == '/home/page'){
          this.islianjie=0
        }
        if(to == '/home/thesolution'){
          this.islianjie=1
        }
        if(to == '/home/productcenter'){
          this.islianjie=2
        }
        if(to == '/home/softwaretechnology'){
          this.islianjie=3
        }
        if(to == '/home/vlog'){
          this.islianjie=4
        }        
        if(to == '/home/CompanyProfile'){
          this.islianjie=5
        }
        if(to == '/home/smartcampus' || to == '/home/SmartCommunity' || to == '/home/SmartConstructionSite' || to == '/home/SmartPark' || to == '/home/SmartKitchen'){
          this.islianjie=1
        }
    }
    }
  },
  methods: {
    dianji(id) {
      this.daohanglist.filter((item) => {
        if (item.id == id) {
          item.isActive = false;
        } else {
          item.isActive = true;
        }
      });
    },

    // 悬浮事件
    onEnterTd(index) {
      console.log(index);
    },

    toProductcenter(id) {
      console.log(id);
      this.$router.push({
        path: "productcenter",
        query: {
          id: id,
        },
      }).catch(err => err) 
    },

    toSmart(index) {
      // 根据下拉下标决定跳转页面
      switch (index) {
        case 0:
          this.$router.push({
            path: "SmartConstructionSite",
          });
          break;
        case 1:
          this.$router.push({
            path: "smartcampus",
          });
          break;
        case 2:
          this.$router.push({
            path: "SmartCommunity",
          });
         break;
        case 3:
          this.$router.push({
            path: "SmartKitchen",
          });
         break;
         case 4:
          this.$router.push({
            path: "SmartPark",
          });
         break;
        default:
          break;
      }
    },
  },
  beforeEnter: (to, from, next) => {
    // ...
    console.log(to, from, next);
  }
};
</script>
<style lang="less" scoped>
.lianjiehou {
  display: block;
  height: 82px;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  padding: 0 20px;
  color: #0e57a2;
  border-bottom: 3px solid #0e57a2;
}

.dingbu {
  line-height: 82px;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  // height: 82px;
  width: 100%;
  box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.35);
  line-height: 82px;
  .logo {
    width: 136px;
    height: 58px;
    vertical-align: middle;
    float: left;
    transform: translateY(25%);
  }
  .wenzi {
    float: right;

    ul {
      li {
        float: left;
        margin: 0 24px;
        height: 82px;
        .lianjie {
          display: block;
          height: 82px;
          text-decoration: none;
          color: black;
          font-size: 18px;
          font-weight: 500;
          padding: 0 20px;
        }
      }
      li:hover a {
        color: #0e57a2;
        border-bottom: 3px solid #0e57a2;
      }
      .lianjieActive{
        color: #0e57a2;
        border-bottom: 3px solid #0e57a2;
      }
    }
  }
}
.lianjieSelect {
  width: 540px;
  height: auto;
  background-color: #fff;
  position: absolute;
  top: 82px;
  right: 20px;
  border-top: 0.5px solid #e5e5e5;
  padding: 40px;
  display: grid;
  grid-template-columns: 30% 70%;
  display: none;
  .lianjieSelect_item {
    background-color: #fff;
    .item {
      margin-top: 10px;
      margin: 0;
      height: 50px;
      line-height: 50px;
      font-size: 14px;
      color: #666666;
    }
    .titleActive {
      color: #0e68c3;
      cursor: pointer;
    }
  }
  .lianjieSelect_itemRight {
    border-left: 0.5px solid #e5e5e5;
    padding-left: 40px;
  }
}
.lianjieSelectShow {
  display: grid;
}
.select {
  width: 110px;
  height: auto;
  background-color: #fff;
  position: absolute;
  top: 82px;
  display: none;
  .selectItem {
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    color: #666666;
    text-align: center;
  }
  .selectItem:hover {
    color: #0e68c3;
    cursor: pointer;
  }
}
.selectShow {
  display: block;
}
.animate__animated.animate__bounce {
  --animate-duration: 1s;
}
</style>
