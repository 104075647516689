<template>
  <div class="while">
    <el-image
      :src="require('@/assets/imgAll/wisdom/SmartConstructionSite/backImg.jpg')"
      fit="cover"
      class="backImg"
    ></el-image>

    <!-- 内容区域 -->
    <div class="content">
      <p class="type animate__animated animate__backInLeft">
        <i class="el-icon-guide"></i> 智慧工地
      </p>
      <p class="title">行业概述</p>
      <el-card class="box-card">
        <div class="card">
          <div class="cardItem">
            <p class="cardItem_p">
              伴随着现代网络科技的发展和建筑企业对项目管理要求的提升，越来越多的软件系统和智能设备被广泛应用于施工现场，碎片化的应用和孤立的数据已经不能满足建筑企业对项目的综合管理和目标监控。
            </p>
          </div>
          <div class="cardItem">
            <el-image
              :src="
                require('@/assets/imgAll/wisdom/SmartConstructionSite/gsImg01.png')
              "
              fit="cover"
              class="backImg"
            ></el-image>
          </div>
        </div>
      </el-card>
      <p class="title">需求分析</p>
      <el-card class="box-card">
        <p class="box-card-p">
          1.《劳务宝》智慧工地以物联网端设备数据采集为基础，平台采用“端+云+大数据”的方式将施工现场的应用和硬件设备进行集成，形成数据汇集，产生数据中心。
        </p>
        <p class="box-card-p">
          2.建筑工地管理以大数据为基础，以解决方案为核心，云计算及深度学习为手段，对设备及管理数据进行实时的监管，实现施工过程中智慧化管理、智慧化监控、智慧化服务。
        </p>
        <p class="box-card-p">
          3.平台上的应用和设备之间的数据互连互通形成联动，关键指标、数据以及分析结果以可视化的方式呈现给项目管理者，智能识别问题、智能预警，从而实现施工现场数字化、在线化、移动化、智能化的综合管理。
        </p>
      </el-card>
      <p class="title">方案优势</p>
      <el-card class="box-card">
        <p class="box-card-title">1、碎片化应用，一站式处理，统一监管。</p>
        <p class="box-card-p">
          项目应用整合在同一个平台上，相关应用数据和智能设备采集信息平台共享，数据集中展现、分析、预警，结果以可视化方式呈现，便于项目管理者对现场情况及时了解、有效监管。
        </p>
        <p class="box-card-title">2、通过手机APP,随时随地了解项目相关情况，提高管理效率。</p>
        <p class="box-card-p">
          系统采用SAAS应用方式，项目管理者通过下载手机APP，随时随地都可以了解项目相关情况，方便及时处理，提高管理效率。
        </p>
        <p class="box-card-title">3、指标数据，集中呈现，加强企业对项·目的管控</p>
        <p class="box-card-p">
          平台看板集中展现企业劳务用工、安全、物资机具、环境监测、用水用电等指标数据，加强企业对各项目的管控。
        </p>
      </el-card>
      <p class="title">特色功能</p>
      <el-card class="box-card"> 
        <p class="box-card-title">建筑劳动力管理系统</p>
        <p class="box-card-p">
          和政府建筑工地实名制对接，提供个人信息登记、合同管理、网上安全教育、工资发放、考勤记录、进退场管理、信用评价、施工日志上传、现场人员自动比对等一站式服务，平台数据云存储，核心设备专业研发，支持户外模式，扫码刷脸1秒过闸机，平台支持Saas应用模式。
        </p>
        <p class="box-card-title">工地物联网平台</p>
        <p class="box-card-p">
         通过接入二维码、射频识别(RFID)、红外感应器、GPS和激光扫描器、气体感应器等智能终端，基于云计算和大数据技术，实现平台化信息共享，智能识别、定位、跟踪、监控和管理，广泛应用于建筑工地人员实名制通道、视频监控、塔吊监控、升降机监控、用水用电、扬尘&智能喷淋、地磅、安全教育、工地食堂、超市等场合。
        </p>
        <p class="box-card-title">工地通讯</p>
        <p class="box-card-p">
          依托云计算及互联网技术，以“团队沟通”、“工地对讲”为核心，帮助工程项目团队实现成员管理和信息沟通、图片语音文档的集中存储和分发共享，以及各种工作任务实时交流。
        </p>
      </el-card>
    </div>
    <div class="black">
      <router-link to="/home/thesolution" class="blackLink">
        <i class="el-icon-d-arrow-left"></i> 返回解决方案
      </router-link>
    </div>
  </div>
</template>

<script>
import "animate.css";
export default {
  name: "SmartConstructionSite",
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="less" scoped>
.while {
  width: 100%;
  height: auto;
  padding-top: 82px;
  .backImg{
    width: 100%;
    height: 370px;
  }
  .type {
    text-align: right;
    font-size: 21px;
    // font-weight: 500;
    font-family: STHupo;
  }
  .title {
    font-size: 18px;
    border-left: 2px solid #78bc27;
    padding-left: 10px;
    margin: 20px 0 30px 0;
  }
  .content {
    width: 100%;
    height: auto;
    padding: 20px 3%;
  }
  .card {
    display: grid;
    grid-template-columns: 60% 40%;
    .cardItem {
      .cardItem_p {
        font-size: 16px;
        color: #666;
        line-height: 32px;
        font-weight: 500;
      }
      .card-item-p {
        line-height: 2;
        font-size: 16px;
      }
    }
  }
  .box-card-title {
    font-size: 18px;
    line-height: 1.5;
    font-weight: bold;
    color: #666;
  }
  .box-card-p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
    color: #666;
  }

  .black {
    width: 100%;
    height: 120px;
    line-height: 120px;
    text-align: center;
    background-color: #f7f7f7;
    margin: 20px 0;
    .blackLink {
      color: #333;
      background-color: #e3e3e3;
      text-decoration: none;
      padding: 10px;
      border-radius: 5px;
    }
  }
}
</style>