<template>
  <div class="dukaqi">
    <div class="title">
      <p
        @click="titleIndex = index"
        :class="[titleIndex == index ? 'titleActive' : '']"
        v-for="(item, index) in chanpinneironglist"
        :key="index"
      >
        {{ item.title }}
      </p>
    </div>

    <div class="jiejue">
      <ul>
        <li
          v-for="item in chanpinneironglist[titleIndex].contentList"
          :key="item.id"
          @click="toDetails(item)"
          class="mateDom"
        >
          <div class="jiejueimg">
            <el-image :src="item.src" alt="" lazy class="chanpintu" fit="contain"></el-image>
            <!-- <el-image :src="productObj.srcDetails" lazy class="tupian" ></el-image> -->
          </div>
          <div class="neirong">
            <div class="wenzi">{{ item.name }}</div>
            <div class="ttitle">{{ item.chakan }}</div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dukaqi",
  components: {},
  data() {
    return {
      chanpinneironglist: [
        {
          id: 0,
          title: "4.3寸人脸识别门禁",
          contentList: [
            {
              id: 1,
              src: require("../../assets/imgAll/AImengjin/TP4-FRT/ai1.jpg"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TP4-FRT/ai1.jpg"), //产品详情页图
              introduce: `4.3寸人脸识别门禁终端内置嵌入式Linux操作系统，MTBF＞50000H。搭载自研业界领先人脸识别算法与OTAE光学跟踪算法，采用RGB+IR双目镜头，支持活体检测和强逆光环境识别。识别准确率≥99.9%，识别速度＜0.2S，能够实现极速身份认证和通行，为用户打造安全、流畅的通行体验，可广泛应用于楼宇/园区/社区等场景。`, //介绍
              name: "4.3寸人脸识别门禁终端 TP4-FRT",
        
              effect: {
                title: "产品功能",
                content: [
                  "国产双核处理器",
                  "嵌入式Linux系统，MTBF＞50000H",
                  "人脸识别准确率≥99.9%，识别速度＜0.2S",
                  "2万张人脸库+6万条识别记录",
                  "双目活体防伪功能",
                  "适应复杂光环境精准识别（强光、逆光、阴阳光、逆光）",
                  "电容式多点触摸IPS屏，人员注册、设备配置、数据传输、数据查询均前端完成",
                  "标配WIFI、蓝牙功能，选配刷卡模块",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TP4-FRT/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TP4-FRT/xq1.1.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
          
          ],
        },
        {
          id: 1,
          title: "5寸人脸识别一体机",
          contentList: [
            {
              id: 1,
              src: require("../../assets/imgAll/AImengjin/5_0img/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/5_0img/ai1.1.jpg"), //产品详情页图
              introduce: `采用5寸IPS全视角LCD显示屏。支持在佩戴口罩的情况下完成人脸精准识别比对。支持2W人脸库。1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率,活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。`, //介绍
              name: "5寸人脸识别终端 TP5-FRT",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "门禁联动",
                    content: "人脸抓拍、比对、测温、口罩检测正常后自动打开道闸",
                  },
                  {
                    title: "人脸验证",
                    content: "双目活体防伪功能，防照片、视频等欺骗",
                  },
                  {
                    title: "考勤统计",
                    content: "人脸、温度、打卡即使可见，自动统计上下班考勤",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "接口对接",
                    content: "支持二次开发，OEM/ODM 定制开发",
                  },
                  {
                    title: "口罩识别",
                    content: "AI+红外热成像，戴口罩也能精准测温",
                  },
                  {
                    title: "异常警报",
                    content: "超出正常阈值，发出语音警报",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "采用5寸IPS全视角LCD显示屏。",
                  "工业级外观，防水、防尘设计，稳定可靠。",
                  "支持2W人脸库。",
                  " 1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率",
                  "活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。",
                  "支持在佩戴口罩的情况下完成人脸精准识别比对。",
                  "采用工业级双目宽动态摄像头，夜间红外、LED双补。",
                  "支持选配瑞芯微RK3288四核处理器，瑞芯微RK3399六核处理器，性能强劲。",
                  "支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。",
                  "支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/5_0img/xq1.png"),
                  require("../../assets/imgAll/AImengjin/5_0img/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/5_0img/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/5_0img/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/5_0img/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/5_0img/xq1.5.png"),
                  require("../../assets/imgAll/AImengjin/5_0img/xq1.6.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 2,
              src: require("../../assets/imgAll/AImengjin/TM51-FIG/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TM51-FIG/ai1.1.jpg"), //产品详情页图
              introduce: `采用5寸IPS全视角LCD显示屏。支持指纹识别。支持在佩戴口罩的情况下完成人脸精准识别比对。支持2W人脸库。1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率,活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。`, //介绍
              name: "5寸人脸识别终端 TP5-FFRT",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "指纹识别",
                    content: "内置指纹模块指纹容量1000枚,读取速度800毫秒",
                  },
                  {
                    title: "门禁联动",
                    content: "人脸抓拍、比对、测温、口罩检测正常后自动打开道闸",
                  },
                  {
                    title: "考勤统计",
                    content: "人脸、温度、打卡即使可见，自动统计上下班考勤",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "接口对接",
                    content: "支持二次开发，OEM/ODM 定制开发",
                  },
                  {
                    title: "口罩识别",
                    content: "AI+红外热成像，戴口罩也能精准测温",
                  },
                  {
                    title: "异常警报",
                    content: "超出正常阈值，发出语音警报",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "采用5寸IPS全视角LCD显示屏。",
                  "工业级外观，防水、防尘设计，稳定可靠。",
                  "支持指纹模块,指纹模块容量1000枚,类型Usb电容,读取速度800毫秒",
                  "支持2W人脸库。",
                  " 1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率",
                  "活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。",
                  "支持在佩戴口罩的情况下完成人脸精准识别比对。",
                  "采用工业级双目宽动态摄像头，夜间红外、LED双补。",
                  "支持选配瑞芯微RK3288四核处理器，瑞芯微RK3399六核处理器，性能强劲。",
                  "支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。",
                  "支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TM51-FIG/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TM51-FIG/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TM51-FIG/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TM51-FIG/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TM51-FIG/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TM51-FIG/xq1.5.png"),
                  require("../../assets/imgAll/AImengjin/TM51-FIG/xq1.6.png"),
                  require("../../assets/imgAll/AImengjin/TM51-FIG/xq1.7.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 3,
              src: require("../../assets/imgAll/AImengjin/TM50-QR/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TM50-QR/ai1.1.png"), //产品详情页图
              introduce: `适用于办公区域、酒店、人行通道、学校、商场、商店、社区、等公共服务及管理项目等需要用到扫描安心码的场所。选用高端航天铝型材外壳。支持香港疫苗码识别。支持屏幕显示内容配置`, //介绍
              name: "5寸扫码机 TP5-CSM",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "信息展示",
                    content: "健康码信息屏幕展示",
                  },
                  {
                    title: "性价比高",
                    content: "降低成本、性价比之王",
                  },
                  {
                    title: "便捷",
                    content: "适用于桌面式、落地式安装移动方便",
                  },
                  {
                    title: "防疫码扫描",
                    content: "支持香港健康码接口对接",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "选用高端航天铝型材外壳",
                  "支持HTTP方式的接口对接",
                  "支持公网、局域网使用部署方式",
                  "支持屏幕显示内容配置",
                  "支持香港疫苗码识别",
                  "采用RK3128性能芯片，安卓7.1系统",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TM50-QR/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TM50-QR/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TM50-QR/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TM50-QR/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TM50-QR/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TM50-QR/xq1.5.png"),
                  require("../../assets/imgAll/AImengjin/TM50-QR/xq1.6.png"),
                  require("../../assets/imgAll/AImengjin/TM50-QR/xq1.7.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 4,
              src: require("../../assets/imgAll/AImengjin/TSQ5-D/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TSQ5-D/ai1.1.png"), //产品详情页图
              introduce: `支持对接各地健康码平台,快速扫码,电池待机时间长,支持4G,WIFI防水`, //介绍
              name: "5寸智能扫码手持机 TP5-SCSH",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "身份证健康监测",
                    content: "内置身份证模块、支持刷身份证获取健康码信息",
                  },
                  {
                    title: "防疫码扫描",
                    content: "支持各地健康码接口对接同时实时联网同步云端",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "支持对接各地健康码平台",
                  "快速扫码",
                  "5000mAh大容量电池待机时间长",
                  "支持4G,WIFI防水",
                  "Android9.0,八核2.0主频",
                  "全网通4G,双卡双待,支持VOLTE",
                  "1D/2D/NFC/身份证",
                  "后置1300万相位对焦,前置500万",
                  "IP67,1.5m跌落",
                  "GPS,北斗,GLONADD",
                  "双频5模,信号增强",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TSQ5-D/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TSQ5-D/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TSQ5-D/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TSQ5-D/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TSQ5-D/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TSQ5-D/xq1.5.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 5,
              src: require("../../assets/imgAll/AImengjin/TSQ5-TB/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TSQ5-TB/ai1.1.png"), //产品详情页图
              introduce: `深圳市腾达讯科技推出全新自主研发产品二维码智能扫码盒TSQ5-TB，集成丰富的外部接口，自带电池续航，支持对接各地健康码平台。二维码智能扫码盒子TSQ5-TB配置高清二维码扫描大框，配备高精度扫描摄像头，扫描速度更快，识别扫码更灵敏。二维扫码器TSQ5-TB支持智能语音播报，扫码成功后可播报内容，让语音播报更智能，告别“哑巴式扫码”。`, //介绍
              name: "5寸智能扫码手持机 TP5-SCSB",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "身份证健康监测",
                    content: "内置身份证模块、支持刷身份证获取健康码信息",
                  },
                  {
                    title: "防疫码扫描",
                    content: "支持各地健康码接口对接同时实时联网同步云端",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "支持5.5英寸多点电容触摸屏",
                  "支持内置二代证模块",
                  "支持自带电池续航",
                  "支持主动扫码和被动扫码",
                  "支持智能语音播报",
                  "支持蓝牙",
                  "支持自感应模式，自动感应，快速扫码",
                  "支持内置4G模块",
                  "采用高通8909方案，安卓7.1",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TSQ5-TB/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TSQ5-TB/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TSQ5-TB/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TSQ5-TB/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TSQ5-TB/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TSQ5-TB/xq1.5.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
          ],
        },
        {
          id: 2,
          title: "8寸人脸识别一体机",
          contentList: [
            {
              id: 1,
              src: require("../../assets/imgAll/AImengjin/TM81-TMFH/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TM81-TMFH/ai1.1.jpg"), //产品详情页图
              introduce: `采用8寸IPS全视角LCD显示屏。采用Linux系统,系统稳定性更好。工业级外观，防水、防尘设计，稳定可靠。支持在佩戴口罩的情况下完成人脸精准识别比对。支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。`, //介绍
              name: "8寸海思人脸识别终端 TM81-TMFH",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "人脸验证",
                    content: "双目活体防伪功能，防照片、视频等欺骗",
                  },
                  {
                    title: "Linux系统",
                    content: "系统稳定性更好、内置后台管理系统，电脑无需安装",
                  },
                  {
                    title: "考勤统计",
                    content: "人脸、温度、打卡即使可见，自动统计上下班考勤",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "接口对接",
                    content: "支持二次开发，OEM/ODM 定制开发",
                  },
                  {
                    title: "口罩识别",
                    content: "AI+红外热成像，戴口罩也能精准测温",
                  },
                  {
                    title: "异常警报",
                    content: "超出正常阈值，发出语音警报",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "采用8寸IPS全视角LCD显示屏。",
                  "采用Linux系统,系统稳定性更好,内置后台管理系统,电脑无需安装。",
                  "工业级外观，防水、防尘设计，稳定可靠。",
                  "支持2W人脸库。",
                  "1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率",
                  "活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。",
                  "支持在佩戴口罩的情况下完成人脸精准识别比对。",
                  "采用工业级双目宽动态摄像头，夜间红外、LED双补。",
                  "支持选配瑞芯微RK3288四核处理器，瑞芯微RK3399六核处理器，性能强劲。",
                  "支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。",
                  "支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TM81-TMFH/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFH/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFH/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFH/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFH/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFH/xq1.5.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 2,
              src: require("../../assets/imgAll/AImengjin/TM81-TMFQR/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TM81-TMFQR/ai1.1.jpg"), //产品详情页图
              introduce: `采用8寸IPS全视角LCD显示屏。内置二维码读卡器。工业级外观，防水、防尘设计，稳定可靠。支持在佩戴口罩的情况下完成人脸精准识别比对。支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。`, //介绍
              name: "8寸人脸二维码识别终端 TM81-TMFQR",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "人脸验证",
                    content: "双目活体防伪功能，防照片、视频等欺骗",
                  },
                  {
                    title: "二维码识别",
                    content: "内置二维码模块，多种识别模式，进出方式更灵活",
                  },
                  {
                    title: "考勤统计",
                    content: "人脸、温度、打卡即使可见，自动统计上下班考勤",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "接口对接",
                    content: "支持二次开发，OEM/ODM 定制开发",
                  },
                  {
                    title: "口罩识别",
                    content: "AI+红外热成像，戴口罩也能精准测温",
                  },
                  {
                    title: "异常警报",
                    content: "超出正常阈值，发出语音警报",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "采用8寸IPS全视角LCD显示屏。",
                  "设备内置IC卡刷卡器，多种识别模式，进出方式更灵活",
                  "工业级外观，防水、防尘设计，稳定可靠。",
                  "支持2W人脸库。",
                  "1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率",
                  "活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。",
                  "支持在佩戴口罩的情况下完成人脸精准识别比对。",
                  "采用工业级双目宽动态摄像头，夜间红外、LED双补。",
                  "支持选配瑞芯微RK3288四核处理器，瑞芯微RK3399六核处理器，性能强劲。",
                  "支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。",
                  "支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TM81-TMFQR/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFQR/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFQR/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFQR/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFQR/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFQR/xq1.5.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFQR/xq1.6.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 3,
              src: require("../../assets/imgAll/AImengjin/8-TM81-TMFH/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/8-TM81-TMFH/ai1.1.jpg"), //产品详情页图
              introduce: `采用8寸IPS全视角LCD显示屏。工业级外观，防水、防尘设计，稳定可靠。支持在佩戴口罩的情况下完成人脸精准识别比对。支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。`, //介绍
              name: "8寸人脸识别终端 TM81-TMFH",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "人脸验证",
                    content: "双目活体防伪功能，防照片、视频等欺骗",
                  },
                  {
                    title: "门禁联动",
                    content: "人脸抓拍、对比、测温、口罩检测正常后自动打开匝道",
                  },
                  {
                    title: "考勤统计",
                    content: "人脸、温度、打卡即使可见，自动统计上下班考勤",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "接口对接",
                    content: "支持二次开发，OEM/ODM 定制开发",
                  },
                  {
                    title: "口罩识别",
                    content: "AI+红外热成像，戴口罩也能精准测温",
                  },
                  {
                    title: "异常警报",
                    content: "超出正常阈值，发出语音警报",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "采用8寸IPS全视角LCD显示屏。",
                  "工业级外观，防水、防尘设计，稳定可靠。",
                  "支持2W人脸库。",
                  "1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率",
                  "活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。",
                  "支持在佩戴口罩的情况下完成人脸精准识别比对。",
                  "采用工业级双目宽动态摄像头，夜间红外、LED双补。",
                  "支持选配瑞芯微RK3288四核处理器，瑞芯微RK3399六核处理器，性能强劲。",
                  "支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。",
                  "支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/8-TM81-TMFH/xq1.png"),
                  require("../../assets/imgAll/AImengjin/8-TM81-TMFH/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/8-TM81-TMFH/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/8-TM81-TMFH/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/8-TM81-TMFH/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/8-TM81-TMFH/xq1.5.png"),
                  require("../../assets/imgAll/AImengjin/8-TM81-TMFH/xq1.6.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
             {
              id: 4,
              src: require("../../assets/imgAll/AImengjin/TM81-TMFIC/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TM81-TMFIC/ai1.1.jpg"), //产品详情页图
              introduce: `采用8寸IPS全视角LCD显示屏。内置IC刷卡模块。工业级外观，防水、防尘设计，稳定可靠。支持在佩戴口罩的情况下完成人脸精准识别比对。支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。`, //介绍
              name: "8寸人脸刷卡识别终端 TM81-TMFIC",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "门禁联动",
                    content: "人脸抓拍、对比、测温、口罩检测正常后自动打开匝道",
                  },
                  {
                    title: "IC卡识别",
                    content: "内置IC刷卡器，多种识别模式，进出方式更灵活",
                  },
                  {
                    title: "考勤统计",
                    content: "人脸、温度、打卡即使可见，自动统计上下班考勤",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "接口对接",
                    content: "支持二次开发，OEM/ODM 定制开发",
                  },
                  {
                    title: "口罩识别",
                    content: "AI+红外热成像，戴口罩也能精准测温",
                  },
                  {
                    title: "异常警报",
                    content: "超出正常阈值，发出语音警报",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "采用8寸IPS全视角LCD显示屏。",
                  "设备内置IC卡刷卡器，多种识别模式，进出方式更灵活",
                  "工业级外观，防水、防尘设计，稳定可靠。",
                  "支持2W人脸库。",
                  "1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率",
                  "活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。",
                  "支持在佩戴口罩的情况下完成人脸精准识别比对。",
                  "采用工业级双目宽动态摄像头，夜间红外、LED双补。",
                  "支持选配瑞芯微RK3288四核处理器，瑞芯微RK3399六核处理器，性能强劲。",
                  "支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。",
                  "支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TM81-TMFIC/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFIC/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFIC/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFIC/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFIC/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFIC/xq1.5.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 5,
              src: require("../../assets/imgAll/AImengjin/TM81-TMFID/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TM81-TMFID/ai1.1.jpg"), //产品详情页图
              introduce: `采用8寸IPS全视角LCD显示屏。内置指纹识别模块。工业级外观，防水、防尘设计，稳定可靠。支持在佩戴口罩的情况下完成人脸精准识别比对。支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。`, //介绍
              name: "8寸人脸指纹识别终端 TM81-TMFID",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "指纹认证",
                    content: "内置指纹模块，指纹容量1000枚，读取速度800毫秒",
                  },
                  {
                    title: "门禁联动",
                    content: "人脸抓拍、比对、测温、口罩检测正常后自动打开道闸",
                  },
                  {
                    title: "考勤统计",
                    content: "人脸、温度、打卡即使可见，自动统计上下班考勤",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "接口对接",
                    content: "支持二次开发，OEM/ODM 定制开发",
                  },
                  {
                    title: "口罩识别",
                    content: "AI+红外热成像，戴口罩也能精准测温",
                  },
                  {
                    title: "异常警报",
                    content: "超出正常阈值，发出语音警报",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "采用8寸IPS全视角LCD显示屏。",
                  "支持指纹模块,指纹模块容量1000枚,类型Usb电容,读取速度800毫秒",
                  "工业级外观，防水、防尘设计，稳定可靠。",
                  "支持2W人脸库。",
                  "1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率",
                  "活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。",
                  "支持在佩戴口罩的情况下完成人脸精准识别比对。",
                  "采用工业级双目宽动态摄像头，夜间红外、LED双补。",
                  "支持选配瑞芯微RK3288四核处理器，瑞芯微RK3399六核处理器，性能强劲。",
                  "支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。",
                  "支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TM81-TMFID/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFID/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFID/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFID/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFID/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFID/xq1.5.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFID/xq1.6.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 6,
              src: require("../../assets/imgAll/AImengjin/TM91-PLA-IC/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TM91-PLA-IC/ai1.1.jpg"), //产品详情页图
              introduce: `采用8寸IPS全视角LCD显示屏。内置指纹识别模块和IC读卡器。工业级外观，防水、防尘设计，稳定可靠。支持在佩戴口罩的情况下完成人脸精准识别比对。支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。`, //介绍
              name: "8寸刷卡+掌纹识别终端 TM91-PLA-IC",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "掌纹认证",
                    content: "增加掌纹识别模块，防照片，视频等欺骗",
                  },
                  {
                    title: "IC卡识别",
                    content: "内置IC刷卡器，多种识别模式，进出方式更灵活",
                  },
                  {
                    title: "考勤统计",
                    content: "人脸、温度、打卡即使可见，自动统计上下班考勤",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "接口对接",
                    content: "支持二次开发，OEM/ODM 定制开发",
                  },
                  {
                    title: "口罩识别",
                    content: "AI+红外热成像，戴口罩也能精准测温",
                  },
                  {
                    title: "异常警报",
                    content: "超出正常阈值，发出语音警报",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "采用8寸IPS全视角LCD显示屏。",
                  "设备增加掌纹识别模块,并内置IC卡刷卡器,多种识别模式,进出方式更灵活",
                  "工业级外观，防水、防尘设计，稳定可靠。",
                  "支持2W人脸库。",
                  "1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率",
                  "活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。",
                  "支持在佩戴口罩的情况下完成人脸精准识别比对。",
                  "采用工业级双目宽动态摄像头，夜间红外、LED双补。",
                  "支持选配瑞芯微RK3288四核处理器，瑞芯微RK3399六核处理器，性能强劲。",
                  "支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。",
                  "支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TM91-PLA-IC/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TM91-PLA-IC/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TM91-PLA-IC/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TM91-PLA-IC/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TM91-PLA-IC/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TM91-PLA-IC/xq1.5.png"),
                  require("../../assets/imgAll/AImengjin/TM91-PLA-IC/xq1.6.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 7,
              src: require("../../assets/imgAll/AImengjin/TM81-TMFIC+/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TM81-TMFIC+/ai1.1.jpg"), //产品详情页图
              introduce: `采用8寸IPS全视角LCD显示屏。内置指纹识别模块和IC读卡器。工业级外观，防水、防尘设计，稳定可靠。支持在佩戴口罩的情况下完成人脸精准识别比对。支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。`, //介绍
              name: "8寸刷卡+指纹识别终端 TM81-TMFIC",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "指纹认证",
                    content: "内置指纹模块，指纹容量1000枚，读取速度800毫秒",
                  },
                  {
                    title: "IC卡识别",
                    content: "内置IC刷卡器，多种识别模式，进出方式更灵活",
                  },
                  {
                    title: "考勤统计",
                    content: "人脸、温度、打卡即使可见，自动统计上下班考勤",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "接口对接",
                    content: "支持二次开发，OEM/ODM 定制开发",
                  },
                  {
                    title: "口罩识别",
                    content: "AI+红外热成像，戴口罩也能精准测温",
                  },
                  {
                    title: "异常警报",
                    content: "超出正常阈值，发出语音警报",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "采用8寸IPS全视角LCD显示屏。",
                  "支持指纹模块,指纹模块容量1000枚,类型Usb电容,读取速度800毫秒",
                  "设备内置IC卡刷卡器,多种识别模式,进出方式更灵活",
                  "工业级外观，防水、防尘设计，稳定可靠。",
                  "支持2W人脸库。",
                  "1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率",
                  "活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。",
                  "支持在佩戴口罩的情况下完成人脸精准识别比对。",
                  "采用工业级双目宽动态摄像头，夜间红外、LED双补。",
                  "支持选配瑞芯微RK3288四核处理器，瑞芯微RK3399六核处理器，性能强劲。",
                  "支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。",
                  "支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TM81-TMFIC+/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFIC+/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFIC+/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFIC+/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFIC+/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFIC+/xq1.5.png"),
                  require("../../assets/imgAll/AImengjin/TM81-TMFIC+/xq1.6.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
          ],
        },
        {
          id: 3,
          title: "8寸测温人脸识别机",
          contentList: [
            {
              id: 1,
              src: require("../../assets/imgAll/AImengjin/TM82-TMFQR/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TM82-TMFQR/ai1.1.jpg"), //产品详情页图
              introduce: `采用8寸IPS全视角LCD显示屏。内置二维码读卡器。支持人体温度检测、温度显示支持以及体温异常自动报警，秒级检测速度。工业级外观，防水、防尘设计，稳定可靠。支持在佩戴口罩的情况下完成人脸精准识别比对。支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。`, //介绍
              name: "8寸测温人脸二维码识别终端 TM82-TMFQR",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "体温检测",
                    content: "支持人体温度检测、温度显示支持以及体温异常自动报警，秒级检测速度",
                  },
                  {
                    title: "二维码识别",
                    content: "内置二维码识别模块，多种识别模块，进出方式更灵活",
                  },
                  {
                    title: "考勤统计",
                    content: "人脸、温度、打卡即使可见，自动统计上下班考勤",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "接口对接",
                    content: "支持二次开发，OEM/ODM 定制开发",
                  },
                  {
                    title: "口罩识别",
                    content: "AI+红外热成像，戴口罩也能精准测温",
                  },
                  {
                    title: "异常警报",
                    content: "超出正常阈值，发出语音警报",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "采用8寸IPS全视角LCD显示屏。",
                  "设备内置二维码识别模块,多种识别模式,进出方式更灵活。",
                  "支持人体温度检测、温度显示，最佳体温检测距离0.5米，最远1米，0.5℃误差，支持以及体温异常自动报警，秒级     检测速度，考勤测温数据实时导出。",
                  "支持2W人脸库。",
                  " 1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率",
                  "活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。",
                  "支持在佩戴口罩的情况下完成人脸精准识别比对。",
                  "采用工业级双目宽动态摄像头，夜间红外、LED双补。",
                  "支持选配瑞芯微RK3288四核处理器，瑞芯微RK3399六核处理器，性能强劲。",
                  "支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。",
                  "支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TM82-TMFQR/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFQR/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFQR/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFQR/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFQR/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFQR/xq1.5.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFQR/xq1.6.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFQR/xq1.7.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 2,
              src: require("../../assets/imgAll/AImengjin/TM82-TMF/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TM82-TMF/ai1.1.jpg"), //产品详情页图
              introduce: `采用8寸IPS全视角LCD显示屏。工业级外观，防水、防尘设计，稳定可靠。支持人体温度检测、温度显示支持以及体温异常自动报警，秒级检测速度。支持在佩戴口罩的情况下完成人脸精准识别比对。支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。`, //介绍
              name: "8寸测温人脸识别终端 TM82-TMF",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "体温检测",
                    content: "支持人体温度检测、温度显示支持以及体温异常自动报警，秒级检测速度",
                  },
                  {
                    title: "人脸验证",
                    content: "双目活体防伪功能，防照片、视频等欺骗",
                  },
                  {
                    title: "考勤统计",
                    content: "人脸、温度、打卡即使可见，自动统计上下班考勤",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "接口对接",
                    content: "支持二次开发，OEM/ODM 定制开发",
                  },
                  {
                    title: "口罩识别",
                    content: "AI+红外热成像，戴口罩也能精准测温",
                  },
                  {
                    title: "异常警报",
                    content: "超出正常阈值，发出语音警报",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "采用8寸IPS全视角LCD显示屏。",
                  "工业级外观，防水、防尘设计，稳定可靠。",
                  "设备内置二维码识别模块,多种识别模式,进出方式更灵活。",
                  "支持人体温度检测、温度显示，最佳体温检测距离0.5米，最远1米，0.5℃误差，支持以及体温异常自动报警，秒级检测速度，考勤测温数据实时导出。",
                  "支持2W人脸库。",
                  " 1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率",
                  "活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。",
                  "支持在佩戴口罩的情况下完成人脸精准识别比对。",
                  "采用工业级双目宽动态摄像头，夜间红外、LED双补。",
                  "支持选配瑞芯微RK3288四核处理器，瑞芯微RK3399六核处理器，性能强劲。",
                  "支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。",
                  "支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TM82-TMF/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMF/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMF/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMF/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMF/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMF/xq1.5.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMF/xq1.6.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMF/xq1.7.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 3,
              src: require("../../assets/imgAll/AImengjin/TM82-TMFIC/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TM82-TMFIC/ai1.1.jpg"), //产品详情页图
              introduce: `采用8寸IPS全视角LCD显示屏。内置IC刷卡模块。支持人体温度检测、温度显示支持以及体温异常自动报警，秒级检测速度。工业级外观，防水、防尘设计，稳定可靠。支持在佩戴口罩的情况下完成人脸精准识别比对。支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。`, //介绍
              name: "8寸测温人脸刷卡识别终端 TM82-TMFIC",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "体温检测",
                    content: "支持人体温度检测、温度显示支持以及体温异常自动报警，秒级检测速度",
                  },
                  {
                    title: "IC卡识别",
                    content: "内置IC刷卡器，多种识别模式，进出方式更灵活",
                  },
                  {
                    title: "考勤统计",
                    content: "人脸、温度、打卡即使可见，自动统计上下班考勤",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "接口对接",
                    content: "支持二次开发，OEM/ODM 定制开发",
                  },
                  {
                    title: "口罩识别",
                    content: "AI+红外热成像，戴口罩也能精准测温",
                  },
                  {
                    title: "异常警报",
                    content: "超出正常阈值，发出语音警报",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "采用8寸IPS全视角LCD显示屏。",
                  "设备内置IC卡刷卡器,多种识别模式,进出方式更灵活。",
                  "支持人体温度检测、温度显示，最佳体温检测距离0.5米，最远1米，0.5℃误差，支持以及体温异常自动报警，秒级检测速度，考勤测温数据实时导出。",
                  "工业级外观，防水、防尘设计，稳定可靠。",
                  "支持2W人脸库。",
                  " 1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率",
                  "活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。",
                  "支持在佩戴口罩的情况下完成人脸精准识别比对。",
                  "采用工业级双目宽动态摄像头，夜间红外、LED双补。",
                  "支持选配瑞芯微RK3288四核处理器，瑞芯微RK3399六核处理器，性能强劲。",
                  "支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。",
                  "支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TM82-TMFIC/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFIC/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFIC/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFIC/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFIC/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFIC/xq1.5.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFIC/xq1.6.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 4,
              src: require("../../assets/imgAll/AImengjin/TM82-TMFPIC/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TM82-TMFPIC/ai1.1.jpg"), //产品详情页图
              introduce: `采用8寸IPS全视角LCD显示屏。内置掌纹识别模块。内置IC刷卡模块。支持人体温度检测、温度显示支持以及体温异常自动报警，秒级检测速度。内置IC刷卡器。工业级外观，防水、防尘设计，稳定可靠。支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。`, //介绍
              name: "8寸测温人脸掌纹刷卡识别终端 TM82-TMFPIC",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "掌纹验证",
                    content: "增加掌纹识别模块，防照片、视频等欺骗",
                  },
                  {
                    title: "IC卡识别",
                    content: "内置IC刷卡器，多种识别模式，进出方式更灵活",
                  },
                  {
                    title: "考勤统计",
                    content: "人脸、温度、打卡即使可见，自动统计上下班考勤",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "接口对接",
                    content: "支持二次开发，OEM/ODM 定制开发",
                  },
                  {
                    title: "口罩识别",
                    content: "AI+红外热成像，戴口罩也能精准测温",
                  },
                  {
                    title: "异常警报",
                    content: "超出正常阈值，发出语音警报",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "采用8寸IPS全视角LCD显示屏。",
                  "设备增加掌纹识别模块,并内置IC卡刷卡器,多种识别模式,进出方式更灵活",
                  "支持人体温度检测、温度显示，最佳体温检测距离0.5米，最远1米，0.5℃误差，支持以及体温异常自动报警，秒级检测速度，考勤测温数据实时导出。",
                  "工业级外观，防水、防尘设计，稳定可靠。",
                  "支持2W人脸库。",
                  " 1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率",
                  "活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。",
                  "支持在佩戴口罩的情况下完成人脸精准识别比对。",
                  "采用工业级双目宽动态摄像头，夜间红外、LED双补。",
                  "支持选配瑞芯微RK3288四核处理器，瑞芯微RK3399六核处理器，性能强劲。",
                  "支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。",
                  "支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TM82-TMFPIC/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFPIC/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFPIC/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFPIC/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFPIC/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFPIC/xq1.5.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFPIC/xq1.6.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFPIC/xq1.7.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 5,
              src: require("../../assets/imgAll/AImengjin/TM82-TMFID/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TM82-TMFID/ai1.1.png"), //产品详情页图
              introduce: `支持健康码识别。支持人证1:1模式。支持人体温度检测、温度显示支持以及体温异常自动报警，秒级检测速度。工业级外观，防水、防尘设计，稳定可靠。支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。`, //介绍
              name: "8寸测温人脸证码一体机 TM82-TMFID",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "身份证健康检测",
                    content: "内置身份证模块，支持刷身份证获取健康码信息",
                  },
                  {
                    title: "防疫码扫描",
                    content: "支持各地健康码接口对接，同时实时联网同步云端",
                  },
                  {
                    title: "考勤统计",
                    content: "人脸、温度、打卡即使可见，自动统计上下班考勤",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "接口对接",
                    content: "支持二次开发，OEM/ODM 定制开发",
                  },
                  {
                    title: "口罩识别",
                    content: "AI+红外热成像，戴口罩也能精准测温",
                  },
                  {
                    title: "异常警报",
                    content: "超出正常阈值，发出语音警报",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "宽动态单目活体防伪，彻底解决各类照片在各种载上的欺骗",
                  "支持LED智能补光",
                  "支持串口、韦根26、34输出，输出内容支持配置",
                  "实时体温监控，体温公差范围精准到0.3°,体温检测距离0.5-1.5米",
                  "支持设备本地存储万人库",
                  "云平台设备支持同时储存5万张人脸照片（小于400KB）、100万条识别记录(0.45KB），2万现场抓拍照片",
                  "局域网设备支持同时储存2万张人脸照片（照片按100KB计算）、100万条识别记录（含最近1万张现场抓拍照片）",
                  " 1:N人脸识别，人脸库支持20000张人脸，top1命中率99.99%",
                  " 1：1人证比对准确率99%以上",
                  "识别速度快",
                  "支持陌生人检测，陌生人等级可配置",
                  "支持人脸识别或陌生人检测时的现场照片保存",
                  "支持HTTP方式的接口对接",
                  "支持公网、局域网使用部署方式",
                  "支持屏幕显示内容配置",
                  "支持二维码识别,支持一维码,支持条形码识别,支持健康码识别",
                  "支持人证模式（二代身份证阅读器）,支持人证1:1模式,支持白名单",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TM82-TMFID/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFID/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFID/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFID/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFID/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFID/xq1.5.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFID/xq1.6.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFID/xq1.7.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 6,
              src: require("../../assets/imgAll/AImengjin/TM82-TMFPII/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TM82-TMFPII/ai1.1.jpg"), //产品详情页图
              introduce: `采用8寸IPS全视角LCD显示屏。内置指纹识别模块和IC读卡器。支持人体温度检测、温度显示支持以及体温异常自动报警，秒级检测速度。工业级外观，防水、防尘设计，稳定可靠。支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。`, //介绍
              name: "8寸测温人脸指纹刷卡识别终端 TM82-TMFPII",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "指纹识别",
                    content: "内置指纹模块，指纹容量1000枚，读取速度800毫秒",
                  },
                  {
                    title: "IC卡识别",
                    content: "内置IC刷卡器，多种识别模式，进出方式更灵活",
                  },
                  {
                    title: "考勤统计",
                    content: "人脸、温度、打卡即使可见，自动统计上下班考勤",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "接口对接",
                    content: "支持二次开发，OEM/ODM 定制开发",
                  },
                  {
                    title: "口罩识别",
                    content: "AI+红外热成像，戴口罩也能精准测温",
                  },
                  {
                    title: "异常警报",
                    content: "超出正常阈值，发出语音警报",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "采用8寸IPS全视角LCD显示屏。",
                  "设备指纹模块，指纹模块容量1000枚，容量Usb电容，读取速度800毫秒",
                  "设备内置IC卡刷卡器，多种识别模式，进出方式更方便",
                  "支持人体温度检测、温度显示，最佳体温检测距离0.5米，最远1米，0.5℃误差，支持以及体温异常自动报警，秒级检测速度，考勤测温数据实时导出。",
                  "工业级外观，防水、防尘设计，稳定可靠。",
                  "支持2W人脸库。",
                  " 1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率",
                  "活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。",
                  "支持在佩戴口罩的情况下完成人脸精准识别比对。",
                  "采用工业级双目宽动态摄像头，夜间红外、LED双补。",
                  "支持选配瑞芯微RK3288四核处理器，瑞芯微RK3399六核处理器，性能强劲。",
                  "支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。",
                  "支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TM82-TMFPII/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFPII/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFPII/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFPII/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFPII/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFPII/xq1.5.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFPII/xq1.6.png"),
                  require("../../assets/imgAll/AImengjin/TM82-TMFPII/xq1.7.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
          ],
        },
        {
          id: 4,
          title: "10.1寸人脸识别一体机",
          contentList: [
            {
              id: 1,
              src: require("../../assets/imgAll/AImengjin/TM101/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TM101/ai1.1.jpg"), //产品详情页图
              introduce: `采用10.1寸IPS全视角LCD显示屏。工业级外观，防水、防尘设计，稳定可靠。支持在佩戴口罩的情况下完成人脸精准识别比对。支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。`, //介绍
              name: "10.1寸人脸识别终端 TM101",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "门禁联动",
                    content: "人脸抓拍、比对、测温、口罩检测正常后自动打开道闸",
                  },
                  {
                    title: "人脸验证",
                    content: "双目活体防伪功能，防照片、视频等欺骗",
                  },
                  {
                    title: "考勤统计",
                    content: "人脸、温度、打卡即使可见，自动统计上下班考勤",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "接口对接",
                    content: "支持二次开发，OEM/ODM 定制开发",
                  },
                  {
                    title: "口罩识别",
                    content: "AI+红外热成像，戴口罩也能精准测温",
                  },
                  {
                    title: "异常警报",
                    content: "超出正常阈值，发出语音警报",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "采用10.1寸IPS全视角LCD显示屏。",
                  "工业级外观，防水、防尘设计，稳定可靠。",
                  "支持2W人脸库。",
                  " 1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率",
                  "活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。",
                  "支持在佩戴口罩的情况下完成人脸精准识别比对。",
                  "采用工业级双目宽动态摄像头，夜间红外、LED双补。",
                  "支持选配瑞芯微RK3288四核处理器，瑞芯微RK3399六核处理器，性能强劲。",
                  "支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。",
                  "支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TM101/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TM101/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TM101/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TM101/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TM101/xq1.5.png"),
                  require("../../assets/imgAll/AImengjin/TM101/xq1.6.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
            {
              id: 2,
              src: require("../../assets/imgAll/AImengjin/TM102/ai1.png"), //产品图
              srcDetails: require("../../assets/imgAll/AImengjin/TM102/ai1.1.jpg"), //产品详情页图
              introduce: `采用10.1寸IPS全视角LCD显示屏。支持人体温度检测、温度显示，最佳体温检测距离0.5米，最远1米，0.5℃误差，支持以及体温异常自动报警，秒级检测速度，考勤测温数据实时导出。工业级外观，防水、防尘设计，稳定可靠。支持在佩戴口罩的情况下完成人脸精准识别比对。支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。`, //介绍
              name: "10.1寸人脸识别终端-测温款 TM102",
              characteristic: {
                title: "产品特色",
                content: [
                  {
                    title: "测温检测",
                    content: "支持人体温度检测、温度显示、支持以及体温异常自动报警，秒级检测速度",
                  },
                  {
                    title: "人脸验证",
                    content: "双目活体防伪功能，防照片、视频等欺骗",
                  },
                  {
                    title: "考勤统计",
                    content: "人脸、温度、打卡即使可见，自动统计上下班考勤",
                  },
                  {
                    title: "记录追溯",
                    content: "人脸记录自动保存，可以进行人员的二次筛查和检查",
                  },
                  {
                    title: "数据分析",
                    content: "自动生成报表，也可第三方平台接入，完成数据汇总",
                  },
                  {
                    title: "接口对接",
                    content: "支持二次开发，OEM/ODM 定制开发",
                  },
                  {
                    title: "口罩识别",
                    content: "AI+红外热成像，戴口罩也能精准测温",
                  },
                  {
                    title: "异常警报",
                    content: "超出正常阈值，发出语音警报",
                  },
                ],
              }, //特色
              effect: {
                title: "产品功能",
                content: [
                  "采用10.1寸IPS全视角LCD显示屏。",
                  "工业级外观，防水、防尘设计，稳定可靠。",
                  "支持人体温度检测、温度显示，最佳体温检测距离0.5米，最远1米，0.5℃误差，支持以及体温异常自动报警，秒级检测速度，考勤测温数据实时导出。",
                  "支持2W人脸库。",
                  " 1：1比对识别率99.7%以上，1：N比对识别率96.7%以上@0.1%误识率",
                  "活体检测准确率98.3%@1%误拒率。人脸识别通过速度小于1秒。",
                  "支持在佩戴口罩的情况下完成人脸精准识别比对。",
                  "采用工业级双目宽动态摄像头，夜间红外、LED双补。",
                  "支持选配瑞芯微RK3288四核处理器，瑞芯微RK3399六核处理器，性能强劲。",
                  "支持身份证读卡器、IC刷卡器、二维码读卡器等多种外设扩展。",
                  "支持系统级、APP离线级、APP+后台网络级多种API对接，文档完善，支持二次开发。",
                ], //功能
              },
              parameter: {
                title: "设备参数",
                content: [
                  require("../../assets/imgAll/AImengjin/TM102/xq1.png"),
                  require("../../assets/imgAll/AImengjin/TM102/xq1.1.png"),
                  require("../../assets/imgAll/AImengjin/TM102/xq1.2.png"),
                  require("../../assets/imgAll/AImengjin/TM102/xq1.3.png"),
                  require("../../assets/imgAll/AImengjin/TM102/xq1.4.png"),
                  require("../../assets/imgAll/AImengjin/TM102/xq1.5.png"),
                  require("../../assets/imgAll/AImengjin/TM102/xq1.6.png"),
                ],
              }, //参数
              chakan:
                "适用功能和场景:民政、银行、宾馆、邮局、证券等场所进行身份核验。",
            },
          ],
        },
      ],

      titleIndex: 0, //选择系列得下标;同时作为显示内容的下标
    };
  },
  methods: {
    toDetails(obj) {
      this.$router.push({
        path: "productdetailsppage",
        query: {
          list: JSON.stringify(obj),
        },
      });
    },
    getDivAddr(){
      // 获取要添加动画的dom元素（也可用ref）
      let dom = document.getElementsByClassName('mateDom')
      // 获取页面高度；后期与bottom做对比
      let height = document.documentElement.clientHeight
      // 给每一个添加动画的dom元素添加事件
      for (const iterator of dom) {
        // 获取dom距离可视区域的高度
        let list = iterator.getBoundingClientRect()
        // 利用top bottom距离可视区域顶部的距离判断元素是否在可视区域中
        if(list.bottom>0 && list.top<height-100){
          // 将预先隐藏的元素显示
          // 注意：用visibility而不是用disablay
          // 添加动画类名
        iterator.style.visibility='visible'
        iterator.classList.add('animate__animated', 'animate__fadeInUp');
      }
      }
    },
    // 监听滚动条事件
    windowScroll() {
      this.getDivAddr()
    },
  },
  mounted(){
    this.getDivAddr()
    // 监听滚动条
    window.addEventListener('scroll', this.windowScroll)
  }
};
</script>
<style lang="less" scoped>
.jiejue {
  width: 100%;
  ul {
    li {
      width: 33.333%;
      padding: 1%;
      height: 100%;
      float: left;
      .jiejueimg {
        width: 100%;
        height: 290px;
        line-height: 290px;
        background-color: rgb(255, 255, 255);
        text-align: center;
        vertical-align: middle;
        overflow: hidden;
        .chanpintu {
          width: 180px;
          height: 200px;
          // overflow: hidden;
          // transition: ease 0.5s;
          margin-top: 40px;
        }
      }
      .neirong {
        background-color: rgb(255, 255, 255);
        border-top: 1px solid #e5e5e5;
        height: 120px;
        text-align: center;
        padding: 28px 20px;
        transition: ease 0.5s;
        .wenzi {
          color: rgb(14, 14, 14);
          font-size: 22px;
          font-weight: 400;
          margin-bottom: 12px;
          transition: ease 0.5s;
        }
        .ttitle {
          font-size: 16px;
          line-height: 30px;
          color: #666;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    li:hover .wenzi {
      color: #0e57a2;
    }
    li:hover .neirong {
      border-top: 1px solid #0e57a2;
      transform: scale(x, y);
    }
    li:hover .chanpintu {
      transform: scale(1.2);
    }
  }
}
.title {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
  p {
    margin: 0 20px;
  }
  .titleActive {
    color: #2468c3;
    font-weight: bold;
  }
  .mateDom{
    visibility: hidden;
  }
}
</style>
